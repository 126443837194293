import React, { useEffect, useMemo, useRef, useState } from "react";
import "./mspDonutChart.scss";
import { Doughnut } from "react-chartjs-2";
import { TrimString } from "../../../Utils/StringTrimmer";
import { Chart } from "chart.js";

/**
 *
 * @param  graphData [{
 * label:""
 * value:"",
 * color:""
 * }]
 *
 *
 * @param number height
 *
 * @param thickness (0,100)
 * @returns
 */
const MspDonutChart = ({ height = 150, graphData = [], thickness,forwardedRef }) => {
  const [labelName, setLabelName] = useState({ name: "", percent: "" });
  const chartRef = useRef(null);
  const options = {
    rotation: 70 * Math.PI,
    hover: {
      animationDuration: 50,
    },
    legend: {
      display: false,
    },
    responsive: false,
    maintainAspectRatio: true,
    onHover: handleHover,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    layout: {
      padding: {
        top: 8,
        left: 8,
        right: 8,
        bottom: 8
      }
    },
    cutout: 100 - (thickness ?? 31),
    tooltips: false,
  };

  const preDataOptions = {
    rotation: 70 * Math.PI,
    hover: {
      animationDuration: 50,
    },
    legend: {
      display: false,
    },
    responsive: false,
    maintainAspectRatio: true,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    layout: {
      padding: {
        top: 8,
        left: 8,
        right: 8,
        bottom: 8
      }
    },
    cutout: 100 - (thickness ?? 31),
    tooltips: false,
  };
console.log(graphData,"graphData")
  const data = useMemo(() => {
    let graph_data = [...graphData].filter((item) => item?.value >= 0);
    let labels = [...graph_data].map((item) => item.label);
    let data_list = [...graph_data].map((item) => item.value);
    let colors = [...graph_data].map((item) => item.color);
    return {
      labels: labels,
      datasets: [
        {
          label: "My First Dataset",
          data: data_list,
          backgroundColor: colors,
          //  hoverRadius: [10,10,10],
          borderColor: colors,
          hoverBorderWidth: 10,
          borderCapStyle: "butt",
          hoverBorderJoinStyle: 'miter',
          borderJoinStyle: 'miter',
          borderWidth: 1,
        },
      ],
    };
  }, [graphData]);

 

  const _data = {
    labels: ["Spend 1"],
    datasets: [
      {
        label: "My First Dataset",
        data: [30],
        backgroundColor: [
          "rgb(235, 237, 241)",
         
        ],
        //  hoverRadius: [10,10,10],
        borderColor: [
          "rgb(235, 237, 241)",
         
        ],
        // hoverBorderWidth: 10,
        borderCapStyle: "butt",
      },
    ],
  };

   
  const Total = data.datasets[0].data.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  function handleHover(event, array) {
    if (array && array.length > 0) {
      const index = array[0].index;
      if (labelName.index === index) return;

      setLabelName({
        name: TrimString(data.labels[index],15) ?? "",
        percent: `${((data.datasets[0].data[index] / Total) * 100).toFixed(
          2
        )}%`,
        index
      });
    } else {
      if (!labelName.index) return;
      setLabelName({ name: "", percent: "" });
    }
  }
  function expandSpecificSegement(_colorId) {
    const chartInstance = chartRef.current;
    if (!chartInstance) return;
    if (_colorId === null) {
      chartInstance.setActiveElements([]);
      setLabelName({ name: "", percent: "" });
      return;
    }
    chartInstance.setActiveElements([{
      datasetIndex: 0,
      index: _colorId,
    }]);
    setLabelName({
      name: TrimString(data.labels[_colorId],15) ?? "",
      percent: `${((data.datasets[0].data[_colorId] / Total) * 100).toFixed(
        2
      )}%`,
    });
  }

  // Using forwardedRef to pass the ref to the parent
  React.useImperativeHandle(forwardedRef, () => ({
    expandSpecificSegement,
  }));

  return (
    <div className="zoom-independent py-3 position-relative">
      {Total === 0 ? (
        <div className="w-100 d-flex align-items-center justify-content-center position-relative">
          {/* <svg width={height+20 + 17} height={height+20 + 17}>
            <circle
              fill="none"
              class="progress-x"
              pathLength="100"
              stroke-dasharray="100"
              strokeDashoffset={0}
              className=""
              r={(height+20) / 2}
              cx="calc(50% - 0px)"
              cy="calc(50% - 0px)"
              // cy="125"
              // cx="125"
              strokeWidth="17"
              stroke="#EBEDF1"
            ></circle>
          </svg> */}
          <Doughnut options={preDataOptions} data={_data} height={height}  />
          <div className="position-absolute f-500 f-18 f-grey d-flex align-items-center justify-content-center">
            0%
          </div>
        </div>
      ) : (
        <div className="w-100 d-flex align-items-center justify-content-center position-relative">
          <Doughnut 
            options={options} 
            data={data} 
            height={height}
            width={height}  
            ref={chartRef}
            onMouseLeave={() => setLabelName({ name: "", percent: "" })}
          />
          <div
            className="position-absolute"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <div className="f-18 f-500 mb-2 d-flex align-items-center justify-content-center">
              {labelName.percent}
            </div>
            <div className="f-12 f-500 f-grey  d-flex align-items-center justify-content-center"
            title={labelName.name}
          
            >
              {TrimString(labelName.name,10)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MspDonutChart;

/**
 *
 * @param  graphData [{
 * label:""
 * value:"",
 * color:""
 * }]
 *
 *
 * @param number height
 *
 * @param thickness (0,100)
 * @returns
 */
export const MspDonutChartForDashboard = ({
  height = 150,
  graphData = [],
  thickness,
  forwardedRef,
}) => {
  const [labelName, setLabelName] = useState({ name: "", percent: "" });
  const chartRef = useRef(null);
  const options = {
    rotation: 70 * Math.PI,
    hover: {
      animationDuration: 50,
    },
    legend: {
      display: false,
    },
    responsive: false,
    maintainAspectRatio: true,
    onHover: handleHover,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    layout: {
      padding: {
        top: 8,
        left: 8,
        right: 8,
        bottom: 8
      }
    },
    cutout: 100 - (thickness ?? 31),
    tooltips: false,
  };
  
  const preDataOptions = {
    rotation: 70 * Math.PI,
    hover: {
      animationDuration: 50,
    },
    legend: {
      display: false,
    },
    responsive: false,
    maintainAspectRatio: true,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    layout: {
      padding: {
        top: 8,
        left: 8,
        right: 8,
        bottom: 8
      }
    },
    cutout: 100 - (thickness ?? 31),
    tooltips: false,
  };

  const data = useMemo(() => {
    let graph_data = [...graphData].filter((item) => item?.value >= 0);
    let labels = [...graph_data].map((item) => item.label);
    let data_list = [...graph_data].map((item) => item.value);
    let colors = [...graph_data].map((item) => item.color);
    return {
      labels: labels,
      datasets: [
        {
          label: "My First Dataset",
          data: data_list,
          backgroundColor: colors,
          borderColor: colors,
          hoverBorderWidth: 10,
          borderCapStyle: "butt",
          hoverBorderJoinStyle: 'miter',
          borderJoinStyle: 'miter',
          borderWidth: 1,
        },
      ],
    };
  }, [graphData]);


  const _data = {
    labels: ["Spend 1"],
    datasets: [
      {
        label: "My First Dataset",
        data: [30],
        backgroundColor: [
          "rgb(235, 237, 241)",
         
        ],
        //  hoverRadius: [10,10,10],
        borderColor: [
          "rgb(235, 237, 241)",
         
        ],
        // hoverBorderWidth: 10,
        borderCapStyle: "butt",
      },
    ],
  };
  const Total = data.datasets[0].data.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  function handleHover(event, array) {
    if (array && array.length > 0) {
      const index = array[0].index;
      if (labelName.index === index) return;

      setLabelName({
        name: TrimString(data.labels[index],15) ?? "",
        percent: `${((data.datasets[0].data[index] / Total) * 100).toFixed(
          2
        )}%`,
        index
      });
    } else {
      if (!labelName.index) return;
      setLabelName({ name: "", percent: "" });
    }
  }

  function expandSpecificSegement(_colorId) {
    const chartInstance = chartRef.current;
    if (!chartInstance) return;
    if (_colorId === null) {
      chartInstance.setActiveElements([]);
      setLabelName({ name: "", percent: "" });
      return;
    }
    chartInstance.setActiveElements([{
      datasetIndex: 0,
      index: _colorId,
    }]);
    setLabelName({
      name: TrimString(data.labels[_colorId],15) ?? "",
      percent: `${((data.datasets[0].data[_colorId] / Total) * 100).toFixed(
        2
      )}%`,
    });
  }

  // Using forwardedRef to pass the ref to the parent
  React.useImperativeHandle(forwardedRef, () => ({
    expandSpecificSegement,
  }));

  return (
    <div className="zoom-independent py-3 position-relative">
    

      {Total === 0 ? (
        <div className="w-100 d-flex align-items-center justify-content-center position-relative">
          {/* <svg width={height + 17} height={height + 17}>
            <circle
              fill="none"
              class="progress-x"
              pathLength="100"
              stroke-dasharray="100"
              strokeDashoffset={0}
              className=""
              r={height / 2}
              cx="calc(50% - 0px)"
              cy="calc(50% - 0px)"
              // cy="125"
              // cx="125"
              strokeWidth="17"
              stroke="#EBEDF1"
            ></circle>
          </svg> */}
           <Doughnut
            options={preDataOptions}
            data={_data}
            height={height}
            // ref={chartRef}
          />
          <div className="position-absolute f-500 f-18 f-grey d-flex align-items-center justify-content-center">
            0%
          </div>
        </div>
      ) : (
        <>
          <Doughnut
            options={options}
            data={data}
            height={height}
            ref={chartRef}
            onMouseLeave={() => setLabelName({ name: "", percent: "" })}
          />
          <div
            className="position-absolute"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <div className="f-18 f-500 mb-2 d-flex align-items-center justify-content-center">
              {labelName.percent}
            </div>
            <div className="f-12 f-500 f-grey  d-flex align-items-center justify-content-center">
              {labelName.name}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
