import { useEffect, useMemo, useState } from "react";
import { CustomHGDropdown } from "../../../clients/createClientScreens/CyberComplianceForGroupModal";
import { isString } from "lodash";

const CustomDateSelection = [
  { Id: 1, OptionText: "Days", value: 1 / 30 },
  { Id: 2, OptionText: "Months", value: 1 },
  { Id: 3, OptionText: "Years", value: 12 },
];

const DatePeriod = [
  { Id: 1, OptionText: "30 days", value: 1 },
  { Id: 2, OptionText: "90 days", value: 3 },
  { Id: 3, OptionText: "180 days", value: 6 },
  { Id: 4, OptionText: "Custom" },
];

const getPeriodTitle = (months) => {
  const years = Math.floor(months / 12);
  const restMonths = Math.floor(months % 12);
  const days = months >= 1 ? Math.floor((months % 1) * 30) : Math.floor(months * 30);
  const periodTextYears = `${years} year${years !== 1 ? 's' : ''}`;
  const periodTextMonths = `${restMonths} month${restMonths !== 1 ? 's' : ''}`;
  const periodTextDays = `${days} day${days !== 1 ? 's' : ''}`;
  return `${years > 0 ? periodTextYears : ''} ${restMonths > 0 ? periodTextMonths : ''} ${days > 0 ? periodTextDays : ''}`;
};

const SubscriptionTermsPeriod = ({
  selected,
  errors,
  onChange = () => {}, 
  custom = false,
  maxMonths,
  placeholder = "Select",
}) => {
  const [isCustom, setIsCustom] = useState(custom);
  const [count, setCount] = useState("");
  const [datePeriod, setDatePeriod] = useState(null);
  const [customDateSelection, setCustomDateSelection] = useState(CustomDateSelection[0]);

  const months = count * customDateSelection.value;
  const periodTitle = useMemo(() => getPeriodTitle(months), [months]);

  const isSelected = useMemo(() => !!selected, []);

  useEffect(() => {
    if (!isSelected) return;
    setIsCustom(true);
    setCustomDateSelection(CustomDateSelection[1]);
    setCount(selected);
  }, [isSelected]);

  useEffect(() => {
    if (!count) return;
    const months = count * customDateSelection.value;
    if (maxMonths && months > maxMonths) {
      setCount(maxMonths / customDateSelection.value);
      return;
    }
    onChange(months);
  }, [count]);

  if (!isCustom) {
    return (
      <CustomHGDropdown
        redBorder={errors}
        fieldId={"Id"}
        contentwidth
        fieldValue={"OptionText"}
        selected={datePeriod}
        placeholder={placeholder}
        onClick={(id, val, item) => {
          if (!item.value) {
            setIsCustom(true);
            setCustomDateSelection(CustomDateSelection[1]);
            return;
          }
          setDatePeriod(item);
          setCount(item.value);
        }}
        data={DatePeriod}
      />
    );
  }

  return (
    <CustomInput
      count={count}
      setCount={setCount}
      datePeriod={customDateSelection}
      setDatePeriod={setCustomDateSelection}
      periodTitle={periodTitle}
      errors={errors}
      maxMonths={maxMonths}
    />
  );
}

const CustomInput = ({
  count,
  setCount,
  datePeriod,
  setDatePeriod,
  periodTitle,
  errors,
  maxMonths
}) => (
  <div className="d-flex align-items-center" style={{ gap: '16px' }}>
    <div
      className={`${
        false
          ? "input-error"
          : ""
      } d-flex align-items-center radius-4 highlight-input border-none  p-1 `}
    >
      <input
        type="number"
        className="bg-transparent  border-none"
        placeholder="Type"
        value={count}
        onChange={(e) => {
          let value = e.target.value;
          if (e.target.value && +e.target.value && +e.target.value < 0) {
            value = Math.abs(+e.target.value);
          }
          if (isString(value)) {
            value = value.replace(/^0+/, "");
          }
          setCount(value);
        }}
        style={{
          width: "100px",
        }}
      />
    </div>
    <CustomHGDropdown
      redBorder={errors}
      fieldId={"Id"}
      contentwidth
      fieldValue={"OptionText"}
      selected={datePeriod}
      placeholder="Select"
      onClick={(id, val, item) => {
        setDatePeriod(item);
        const months = count * item.value;
        if (maxMonths && months > maxMonths) {
          setCount(maxMonths / item.value);
          return;
        }
      }}
      data={CustomDateSelection}
    />
    {count && (
      <span className="f-grey f-12 f-400">
        {periodTitle}
      </span>
    )}
  </div>
)

export default SubscriptionTermsPeriod;