import React, { useMemo, useState, useEffect, useRef } from "react";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import "../../integrations/mspIntegrations.scss";
import "./mspPriceCalculator.scss";
import moment from "moment";
import MspRadar from "./MspRadar";
import CyberComplianceGauge from "./mspPriceCalculatorComponents/CyberComplianceGauge";
import CyberScoreGauge from "./mspPriceCalculatorComponents/CyberScoreGauge";
import MspAddEditServiceModal, { IncreaseDecreaseNumbersComponentAlwaysHighlight } from "./MspAddEditServiceModal";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import ContentLoader from "react-content-loader";
import { TrimString } from "../../../../Utils/StringTrimmer";
import CompanySettingsDropdown from "../../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import MspServiceModalToolTip from "./mspCalculatorDropdownAndModals/MspServiceModalToolTip";
import AttackSurfaceTooltip from "../../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import {
  numberWithCommas,
  renderPriceText,
} from "../../mspClientPriceCalculator/MspClientPriceCalculator";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { removeWhiteSpace } from "../../../../Utils/CommonFunctions";
import {
  AddEditTaxRateModal,
  CurrencyDropdown,
  TaxListDropdown,
  TaxRateDropdown,
  deleteTaxRate,
} from "../../MspAttackSurface.js/addItemInSpendModal/AddItemInSpendModal";
import MspAddEditProductModal from "./MspAddEditProductModal";
import MspAddEditSeriesModal from "./MspAddEditSeriesModal";
import { CustomDeleteModal } from "../../../DeleteModal/DeleteModal";
import MspScoreModal, { PackageToolTip, QuestionChip } from "./mspCalculatorDropdownAndModals/MspScoreModal";
import { isEqual } from "lodash";
import ExploreMappingModal from "../../../SecurityAssessments/ExploreMappingModal";
import ImageWithInitials from "../../../../Utils/ImageWithInitials";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MspFeaturesModal from "./mspCalculatorDropdownAndModals/MspFeaturesModal";
import MspFeatures from "./mspCalculatorDropdownAndModals/MspFeatures";
import MspComponents from "./mspCalculatorDropdownAndModals/MspComponents";
import MapCurrentSpendModal from "../../mspClientPriceCalculator/MapCurrentSpendModal";

const MspPriceCalcultor = ({ CustomRadar }) => {
  const location = useLocation();
  const state = location?.state;
  const allPackagesList = state?.allPackagesList;
  const isFirst = state?.isFirst;
  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [packageName, setPackageName] = useState("");
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [dataset, setDataset] = useState([0, 0, 0, 0, 0]); //[12, 24, 26, 14, 6]
  const [changed, setChanged] = useState(false);
  const [pageData, setPageData] = useState({
    response: {},
    service_list_data: [],
    service_dropdown_list: [],
  });
  const [serviceList, setServiceList] = useState([]);
  const [packagesList, setPackagesList] = useState([]);
  const [saving, setSaving] = useState(false);
  const [showDropdownVisibilityArray, setShowDropdownVisibilityArray] = useState(false);
  //3938209 for services
  //3540633 for services
  //3955192 for table
  const { packageId } = useParams();
  const [scenarioId, setScenarioId] = useState(packageId);
  const [selectedServiceId, setSelectedServiceId] = useState(0);
  const [nistPillarType, setnistPillarType] = useState();

  const [billedByStatus, setBilledByStatus] = useState();
  const [packageBillingPeriod, setPackageBillingPeriod] = useState();
  const [errors, setErrors] = useState({
    packageNameError: false,
    packageNameExistError: false,
    packageNameLengthError: false,
    packageDescriptionError: false,
  });
  const [seriesModalData, setSeriesModalData] = useState({
    show: false,
  });
  const [userSelectedCurrency, setUserSelectedCurrency] = useState({});
  const [defaultCurrency, setDefaultCurrency] = useState({});
  const [currencyList, setCurrencyList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [selectedTax, setSelectedTax] = useState();
  const [exchangeRateData, setExchangeRateData] = useState({});
  const [updatedExchangeRateData, setUpdateExchangeRateData] = useState({});
  const [packageData, setPackageData] = useState({});
  const [packageDataChanged, setPackageDataChanged] = useState({});
  const [isPackageDataChanged, setIsPackageDataChanged] = useState(false);
  const [clientId, setClientId] = useState(0);
  const [editState, setEditState] = useState(false);
  const [nestedProductModalState, setNestedProductModalState] = useState({
    show: false,
  });
  const [warningModalConfig, setWarningModalConfig] = useState({
    showWarningModal: false,
  });
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const [newPackageName, setNewPackageName] = useState("");
  const [isNameUnique, setIsNameUnique] = useState(true);
  const [isAddonService, setIsAddOnService] = useState(false);

  const [selectedServices, setSelectedServices] = useState([]);
  const [servicesLoader, setServicesLoader] = useState(false);
  const [oldServices, setOldServices] = useState([]);
  const [nistIndex, setNistIndex] = useState(0);
  const [isCustom, setIsCustom] = useState(null);
  const [tableLoader, setTableLoader] = useState(false);
  const [isHovered, setIsHovered] = useState(0);
  const [serviceSearchString, setServiceSearchString] = useState('');
  const [componentsData, setComponentsData] = useState([]);

  console.log('loading ---> ', loading)

  function addEditServiceByModal(_serviceId) {
    setSelectedServiceId(_serviceId);
    setShowServiceModal(true);
    if (_serviceId === 0) {
      setOldServices(pageData?.service_dropdown_list);
    }
  }
  console.log('oldServices --- ', oldServices)
  useEffect(() => {
    setOldServices(pageData?.service_dropdown_list[nistIndex]);
  }, [nistIndex]);

  useEffect(() => {
    setClientId(state?.clientId);
    setIsCustom(state?.isCustom);
  }, [state]);

  useEffect(() => {
    setPackageData(pageData?.response);
  }, [pageData]);

  useEffect(() => {
    if (oldServices?.length) {
      const addedService = findAddedObject(
        oldServices,
        pageData?.service_dropdown_list
      );
      updateSelectedServices(addedService, nistIndex);
      setTableLoader(true);
      if (addedService) {
        setChanged(true);
        add_or_delete_service_addOn(addedService?.objId_SecurityPack, 0, true)
          .then(() => {
            getPackageDetails(
              Number(scenarioId),
              clientId,
              userAndDeviceCountChanged
            );
            getFeaturesData(Number(scenarioId));
            // getDropdownDataForService(nistIndex);
          })
          .catch(() => {
            // getDropdownDataForService(nistIndex);
          });
        setOldServices([]);
        setNistIndex(0);
      }
    }
    // setChanged(false);
  }, [pageData]);

  function findAddedObject(arr1, arr2) {
    // Filter out objects from arr2 that are not present in arr1
    let addedObjects = arr2.filter(
      (obj2) => !arr1.find((obj1) => isEqual(obj1, obj2))
    );

    // If there is at least one added object, return the first one
    return addedObjects.length > 0 ? addedObjects[0] : null;
  }

  // useEffect(() => {
  //   console.log('*******************************')
  //   const deletedId = window.localStorage.getItem("packageId");
  //   if (deletedId) {
  //     deleteData(deletedId);
  //     window.localStorage.removeItem("packageId");
  //   }
  //   console.log('Number(packageId) === 0 && packagesList.length', Number(packageId) === 0 && packagesList.length)
  //   console.log('Number(packageId)', Number(packageId))
  //   console.log('packagesList.length', packagesList.length)
  //   if (Number(packageId) === 0 && packagesList.length) {
  //     console.log('here - ')
  //     const defaultServiceName = "Security Package 1";
  //     let incrementedName = defaultServiceName;
  //     let counter = 1;

  //     while (packagesList?.some((service) => service.Name == incrementedName)) {
  //       counter++;
  //       incrementedName = `Security Package ${counter}`;
  //     }

  //     setNewPackageName(incrementedName);
  //     createPackage(state?.clientId ?? 0, incrementedName);
  //   }
  //   if (Number(packageId) > 0) {
  //     setScenarioId(packageId);
  //     // getPackageDetails(Number(packageId));
  //     // fillDataInDropdown();
  //   }
  // }, [packagesList]);


  useEffect(() => {
    console.log('*******************************');
    const deletedId = window.localStorage.getItem("packageId");
    if (deletedId) {
      deleteData(deletedId);
      window.localStorage.removeItem("packageId");
    }

    if (Number(packageId) === 0 /*&& packagesList.length*/) {
      console.log('here - ');
      const defaultServiceName = "Security Package 1";
      let incrementedName = defaultServiceName;
      let counter = 1;

      const checkUniqueName = async (nameToCheck) => {
        try {
          const response = await getAPIData(989, authTokens, {
            optionStrEnum1: nameToCheck
          });

          if (response?.Success) {
            counter++;
            incrementedName = `Security Package ${counter}`;
            return checkUniqueName(incrementedName);
          } else {
            setNewPackageName(nameToCheck);
            createPackage(state?.clientId ?? 0, nameToCheck);
          }
        } catch (err) {
          console.log(err);
        }
      };

      checkUniqueName(incrementedName);
    }

    if (Number(packageId) > 0) {
      setScenarioId(packageId);
    }
  }, [packagesList]);


  console.log('packagesList --- ', packagesList)

  useEffect(() => {
    if (Number(packageId) === 0 && isFirst) {
      const defaultServiceName = "Security Package 1";
      let incrementedName = defaultServiceName;
      let counter = 1;

      // while (packagesList?.some((service) => service.Name == incrementedName)) {
      // counter++;
      incrementedName = `Security Package ${counter}`;
      // }



      setNewPackageName(incrementedName);
      createPackage(state?.clientId ?? 0, incrementedName);
    }
    if (Number(packageId) > 0) {
      setScenarioId(packageId);
      // getPackageDetails(Number(packageId));
      // fillDataInDropdown();
    }
  }, [isFirst]);

  useEffect(() => {
    //   // setChanged(false);
    setLoading(true);
    // getServiceList(0);
    // setIsCustom(false);
    // setClientId(0);
  }, [packageId]);

  console.log('packageId', packageId)

  const [isChangeUserAbdDeviceCount, setIsChangeUserAbdDeviceCount] =
    useState(true);
  const [defaultUserAndDeviceCount, setDefaultUserAndDeviceCount] = useState();
  useEffect(() => {
    if (Number(packageId) === 0 && isChangeUserAbdDeviceCount) {
      let updated_data = { ...pageData };
      if (
        pageData.response?.ScenarioHeader?.UserAndDeviceCount &&
        Object.keys(pageData.response.ScenarioHeader).length !== 0
      ) {
        updated_data.response.ScenarioHeader.UserAndDeviceCount.NoOfUsers = "0";
        updated_data.response.ScenarioHeader.UserAndDeviceCount.NoOfWorkstation =
          "0";
        updated_data.response.ScenarioHeader.UserAndDeviceCount.NoOfMobileDevices =
          "0";
        updated_data.response.ScenarioHeader.UserAndDeviceCount.NoOfServices =
          "0";
        updated_data.response.ScenarioHeader.UserAndDeviceCount.NoOfRouters =
          "0";
        updated_data.response.ScenarioHeader.UserAndDeviceCount.NoOfNetworkDevices =
          "0";
        updated_data.response.ScenarioHeader.UserAndDeviceCount.NoOfDomains =
          "0";
        setPageData(updated_data);
        setIsChangeUserAbdDeviceCount(false);
      }
    }
  }, [pageData?.response?.ScenarioHeader]);
  const [isFirstOpen, setIsFirstOpen] = useState(true); // Set initial state to true to open accordion by default
  const [isPreDataState, setIsPreDataState] = useState(true);
  const [userAndDeviceCountChanged, setUserAndDeviceCountChanged] =
    useState(false);

  function ToggleAccordion(e, work = true) {
    if (!work) return;
    var parent = e.target.closest(".point-row");
    var panel = parent.querySelector(".panel-opened");
    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
    setIsFirstOpen(false);
  }



  const [isFirstOpenBlocks, setIsFirstOpenBlocks] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  function ToggleAccordionBlocks(e, blockId, work = true) {
    if (!work) return;
    var parent = e.target.closest(`.point-row-${blockId}`);
    console.log("e.targeta", e.targeta);
    var panel = parent.querySelector(`.panel-opened`);
    var chevron = parent.querySelector("#chevron");
    if (panel?.style?.maxHeight) {
      chevron.classList.toggle("rotate180");
      panel.style.maxHeight = null;
    } else {
      chevron.classList.toggle("rotate180");
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
    let updData = isFirstOpenBlocks;
    updData[blockId] = !updData[blockId];
    setIsFirstOpenBlocks(updData);
  }
  // Function to Create Package
  async function createPackage(objId_Customer, packageName) {
    await postData(authTokens, "CreatePackage", {
      ObjectId: objId_Customer ?? 0,
      PackageName: packageName,
    })
      .then((response) => {
        if (response?.Success) {
          setScenarioId(response?.EntityId);
          setClientId(objId_Customer);
          getPackageDetails(
            Number(response?.EntityId),
            clientId,
            userAndDeviceCountChanged
          );
          getFeaturesData(Number(response?.EntityId));
          getCustomerDevicesCount();
          // fillDataInDropdown();
          setIsCustom(objId_Customer !== 0 ? true : false);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          if (state?.backUrl) {
            history.push(state.backUrl, {
              packageFilterSave: state?.packageFilter,
              serviceFilterSave: state?.serviceFilter,
              productFilterSave: state?.pproductFilter,
            });
          } else {
            history.push("/mspv2/sell/packages", {
              packageFilterSave: state?.packageFilter,
              serviceFilterSave: state?.serviceFilter,
              productFilterSave: state?.pproductFilter,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  // Function to Update scenario Name
  async function updatePackageName(_packageName) {
    await postData(authTokens, "UpdateScenarioName", {
      objectId: scenarioId,
      scenarioName: newPackageName !== "" ? newPackageName : _packageName,
    })
      .then((response) => {
        if (response?.Success) {
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  const data = useMemo(() => {
    console.log("data rendeers");

    return {
      labels: ["Identify", "Protect", "Detect", "Respond", "Recover"],

      datasets: [
        {
          data: dataset, //[25, 20, 17, 30, 31],
          datalabels: {
            display: false,
          },
          backgroundColor: `${"#5244B3"}00`,
          borderColor: `${"#5244B3"}`, // "rgba(0, 143, 212, 1)",
          pointBackgroundColor: "rgba(0, 143, 212, 1)",
          borderWidth: 2,
          pointLabelFontSize: 0,
          scaleShowLabels: false,
          label: "Current Posture",
        },
      ],
    };
  }, [dataset]);
  const _options = useMemo(() => {
    console.log("option function executes");
    const dataValuePlugin = {
      afterDraw: function (chart) {
        const ctx = chart.ctx;
        const xAxis = chart.scales["r"];

        // Set blue color for data values
        ctx.fillStyle = "blue";
        ctx.font = "bold 12px Arial";

        // Loop through each dataset
        chart.data.datasets.forEach(function (dataset, datasetIndex) {
          const meta = chart.getDatasetMeta(datasetIndex);
          meta.data.forEach(function (element, index) {
            const value = dataset.data[index];

            // Calculate position for data value
            const xPos = element.x;
            const yPos = element.y + 20;

            // Draw data value below the label
            ctx.fillText(value, xPos, yPos);
          });
        });
      },
    };
    return {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 1, // 4000, // Set the duration of the transition in milliseconds
      },
      legend: {
        display: false,
      },

      tooltips: {
        enabled: true,
      },
      scales: {
        r: {
          beginAtZero: false,
          max: 40,
          min: 0,
          ticks: {
            display: false,
            stepSize: 10,
            backdropColor: "rgba(0, 0, 0, 0)",
          },
          pointLabels: {
            display: false,
            fontSize: 16,
            fontFamily: "Archivo, sans-serif",
            fontColor: "#101010",
          },
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
      plugins: {
        afterDraw: [dataValuePlugin], // Add the plugin to the chart
      },
    };
    // return opts;
  }, []);
  const __options = (activeTab) => {
    // let dataLabelsValue = [];

    // if (activeTab != "allscenario") {
    //   if (typeof datasets[activeTab] != "undefined") {
    //     dataLabelsValue = datasets[activeTab]["data"];
    //   } else {
    //     dataLabelsValue = datasets[0]["data"];
    //   }
    // } else {
    //   dataLabelsValue = datasets[0]["data"];
    // }
    // if (
    //   dataLabelsValue.length > 0

    // ) {
    //   dataLabelsValue.forEach((val, i) => {
    //     data.labels[i] = data.labels[i] + ` - ${val}`;
    //   });
    // }

    let opts = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: true,
      },
      scales: {
        r: {
          beginAtZero: false,
          max: 35,
          min: 0,
          ticks: {
            display: false,
            stepSize: 5,
            backdropColor: "rgba(0, 0, 0, 0)",
          },
          pointLabels: {
            display: false,
            fontSize: 16,
            fontFamily: "Archivo, sans-serif",
            fontColor: "#101010",
          },
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
    return opts;
  };

  useEffect(() => {
    if (Number(packageId) !== 0) {
      if (isCustom !== null) {
        if (isCustom === true) {
          if (clientId !== undefined && clientId !== 0) {
            getPackageDetails(
              Number(packageId),
              clientId,
              userAndDeviceCountChanged
            );
            getFeaturesData(Number(packageId));
            getCustomerDevicesCount();
          }
        } else {
          getPackageDetails(Number(packageId), 0, userAndDeviceCountChanged);
          getFeaturesData(Number(packageId));
        }
      }
    } else {
      // if (currencyList?.length < 0) {
      //   updateExchangeRateData();
      // }
    }
  }, [clientId, isCustom]);

  window.addEventListener('beforeunload', function (event) {
    if (Number(packageId) === 0 && !isSaveClicked) {
      window.localStorage.setItem("packageId", pageData?.response?.ScenarioHeader?.objId_Scenario);
    }
  });

  const [firstLoad, setfirstLoad] = useState(true);
  async function getPackageDetails(objId, clientId, userAndDeviceCountChanged) {
    updateSeatsIncludes(userAndDeviceCountChanged);
    return new Promise((resolve, reject) => {
      getAPIData(619, authTokens, {
        optionEnum1: objId,
        optionEnum2: state?.clientId ?? clientId ?? 0,
      })
        .then((response) => {
          resolve(response);
          if (response?.mr?.Success) {
            if (firstLoad) {
              setPackageName(response?.ScenarioHeader?.Name);
              if (!isAddedFromResetContribution) {
                setContribPrevData(response?.MSPPackagePrice?.PackageMapCurrentSpend_List);
              }
              setNewPackageName(response?.ScenarioHeader?.Name);
              setPackageBillingPeriod(
                response?.ScenarioHeader?.BillingPeriod_Selected ?? null
              );
              setAssetSeatValues(response);
              setPackageData(response);
              // Set Currency and Tax Part
              setCurrencyList(response?.CurrencyCode_List);

              setDefaultCurrency(
                response?.CurrencyCode_List?.find((f) => f.IsDefault)
              );
              console.log(
                "check curremcy---",
                defaultCurrency,
                response?.CurrencyCode_List?.find((f) => f.IsDefault)
              );
              setUserSelectedCurrency(
                response?.CurrencyCode_Selected ??
                (response?.CurrencyCode_List &&
                  response?.CurrencyCode_List?.find((f) => f.IsDefault))
              );
              setTaxList(response?.TaxRates_List);
              setSelectedTax(
                response?.TaxRates_Selected?.TaxName !== ""
                  ? response?.TaxRates_Selected
                  : {
                    ObjectId: 0,
                    TaxCode: "Not Taxable",
                    TaxName: "Not Taxable",
                    TaxRate: 0,
                    IsDefault: true,
                    IntegrationId: 0,
                  }
              );
            }
            setfirstLoad(false);
            setComponentsData(response?.Nist_All?.flatMap(obj =>
              Array.isArray(obj?.ScoreContributionSelected_List) && obj.ScoreContributionSelected_List?.length > 0
                ? obj.ScoreContributionSelected_List
                : []
            ));
            setExchangeRateData({
              Date: response?.CreatedDate,
              FromCurrency: response?.CurrencyCode_Selected?.CurrencyName,
              Rate: response?.ForeignCurrencyRate,
              ToCurrency: response?.CurrencyCode_List?.find((f) => f.IsDefault),
            });
            updateExchangeRateData(
              response?.CurrencyCode_Selected?.CurrencyName ??
              response?.CurrencyCode_List?.find((f) => f.IsDefault)
                ?.CurrencyName ??
              "GBP"
            );

            let updated_data = response;
            updated_data.MSPPackagePrice.SellOverridden = addZerows((updated_data?.MSPPackagePrice?.SellOverridden).toString())
            setPageData((prevData) => ({
              ...prevData,
              response: updated_data,
              service_list_data: /*[
                response?.Nist_Identify?.length > 0
                  ? [...response?.Nist_Identify]
                  : [],
                response?.Nist_Protect?.length > 0
                  ? [...response?.Nist_Protect]
                  : [],
                response?.Nist_Detect?.length > 0
                  ? [...response?.Nist_Detect]
                  : [],
                response?.Nist_Respond?.length > 0
                  ? [...response?.Nist_Respond]
                  : [],
                response?.Nist_Recover?.length > 0
                  ? [...response?.Nist_Recover]*/
                response?.Nist_All?.length > 0 ? response?.Nist_All : [],
              /*],*/
            }));
            setDataset([
              response?.ScenarioHeader?.InspectScore,
              response?.ScenarioHeader?.ProtectScore,
              response?.ScenarioHeader?.DetectScore,
              response?.ScenarioHeader?.RespondScore,
              response?.ScenarioHeader?.RecoverScore,
            ]);
            setPackageData(response);
            setTableLoader(false);

            // Set Billing Period

            setLoading(false);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    });
  }

  const [featuresData, setFeaturesData] = useState([]);
  async function getFeaturesData(packageId) {
    await getAPIData(679,
      authTokens,
      {
        optionEnum1: 0,
        optionEnum2: 0,
        optionEnum3: 0,
        optionEnum4: 0,
        optionEnum5: packageId,
      }
    )
      .then((response) => {
        if (response?.mr?.Success) {
          setFeaturesData(response?.QuestionnaireSection_List)
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function updateSnapToUnitAssetCounts(_userDeviceDetails) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdateSnapToUnitAssetCounts", {
        AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        UpdateTarget: 3,
        objId_Customer: clientId ?? 0,
        DefaultPackageAsset_List: JSON.stringify(_userDeviceDetails),
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // function UpdateSnapToUnitAssetCounts() {
  //   postData(authTokens, "UpdateSnapToUnitAssetCounts", {
  //     AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
  //     UpdateTarget: 3,
  //     objId_Customer: clientId ?? 0,
  //     DefaultPackageAsset_List: [], //! Change
  //   })
  //     .then((response) => {
  //       // setProcessing(false);
  //       if (response?.Success) {
  //         // hideModal();
  //         // if (refreshData) refreshData();
  //       } else {
  //         CallToast("Something went wrong", false, false, "v2style");
  //       }
  //     })
  //     .catch((err) => {
  //       CallToast("Something went wrong", false, false, "v2style");
  //       // setProcessing(false);
  //       console.log(err);
  //     });
  // }

  async function fillDataInDropdown() {
    // let updated_data_loader = [true, true, true, true, true];
    setServicesLoader(true);//(updated_data_loader);
    return Promise.all([
      getDropdownDataForService(),
      // getDropdownDataForService(1),
      // getDropdownDataForService(2),
      // getDropdownDataForService(3),
      // getDropdownDataForService(4),
    ]);
  }
  async function getServiceList(passedOptionEnum1) {
    // setDataLoading(true);
    await getAPIData(615, authTokens, {
      optionEnum1: passedOptionEnum1,
      optionEnum2: 0,
      optionEnum3: 0,
      optionEnum4: 0,
    }).then((response) => {
      // setDataLoading(false);
      if (response?.mr?.Success) {
        if (passedOptionEnum1 === 1) {
          setServiceList(response?.PackageServiceProduct_List);
        } else {
          setPackagesList(
            response?.PackageServiceProduct_List?.filter(
              (obj) => obj?.ObjectId !== Number(packageId)
            )
          );
          setClientId(
            response?.PackageServiceProduct_List?.find(
              (obj) => obj?.ObjectId === Number(packageId)
            )?.ObjId_Customer ?? 0
          );
          setIsCustom(
            response?.PackageServiceProduct_List?.find(
              (obj) => obj?.ObjectId === Number(packageId)
            )?.IsCustom
          );
        }
        // setLoading(false);
      } else {
      }
    });
  }
  async function getDropdownDataForService(/*_serviceEnum = 0*/) {
    await getAPIData(675, authTokens, {
      optionEnum1: /*_serviceEnum*/-1,
      optionEnum2: 14464,
      optionEnum3: scenarioId,
    })
      .then((response) => {
        let updated_page_data = { ...pageData };
        updated_page_data.service_dropdown_list/*[_serviceEnum]*/ = response;
        setPageData((prevData) => ({
          ...prevData,
          service_dropdown_list: updated_page_data.service_dropdown_list,
        }));
        // let updated_data = servicesLoader;
        // updated_data/*[_serviceEnum]*/ = false;
        // console.log('pageData -----------> ', pageData)
        setServicesLoader(false);//(updated_data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getCustomerDevicesCount() {
    await getAPIData(692, authTokens, {
      optionEnum1: clientId,
    })
      .then((response) => {
        setDefaultUserDeviceDetails(response);
        setTempUserDetailsData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function add_or_delete_service_addOn(
    addID = 0,
    deleteID = 0,
    stopLoadingNewPackage = false
  ) {
    console.log('addID = ', addID)
    return new Promise((resolve, reject) => {
      setTableLoader(true);
      getAPIData(618, authTokens, {
        optionEnum1: scenarioId,
        optionEnum2: addID,
        optionEnum3: deleteID,
        optionEnum5: clientId ?? 0,
      })
        .then((response) => {
          resolve(response);
          if (stopLoadingNewPackage === false) {
            getPackageDetails(
              Number(scenarioId),
              clientId,
              userAndDeviceCountChanged
            );
            getFeaturesData(Number(scenarioId));
          }
          if (response?.mr?.Success) {
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  function setAssetSeatValues(response) {
    // for Seats Setup
    if (response?.ScenarioHeader?.PackageType_Enum === 0) {
      setBilledByStatus({
        DDLId: 0,
        DDLText: "Seats",
      });
    }
    if (response?.ScenarioHeader?.PackageType_Enum === 1) {
      setBilledByStatus({
        DDLId: 1,
        DDLText: "Assets",
      });
    }
  }

  function updatePackage(
    billingPeriod,
    billedStatus,
    currencySelected,
    ForeignCurrencyRate
  ) {
    setUserAndDeviceCountChanged(false);
    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdateMSPPackage", {
        ObjId_Scenario: scenarioId,
        scenarioName: newPackageName !== "" ? newPackageName : packageName,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        BilledBy: billedStatus ? billedStatus : billedByStatus?.DDLId,
        BillingPeriod: billingPeriod
          ? billingPeriod
          : packageBillingPeriod?.DDLId,
        // NoOfUsers:
        //   pageData?.response?.MSPPackagePrice?.PackagePerSeat_List[0]?.PerSeat,
        // NoOfDevices:
        //   pageData?.response?.MSPPackagePrice?.PackagePerSeat_List[3]?.PerSeat,
        // NoOfServers:
        //   pageData?.response?.MSPPackagePrice?.PackagePerSeat_List[6]?.PerSeat,
        // NoOfMobileDevices:
        //   pageData?.response?.MSPPackagePrice?.PackagePerSeat_List[2]?.PerSeat,
        // NoOfDomains:
        //   pageData?.response?.MSPPackagePrice?.PackagePerSeat_List[1]?.PerSeat,
        // NoOfRouters:
        //   pageData?.response?.MSPPackagePrice?.PackagePerSeat_List[5]?.PerSeat,
        // NoOfNetworkDevices:
        //   pageData?.response?.MSPPackagePrice?.PackagePerSeat_List[4]?.PerSeat,
        DefaultPackagePerSeat_List: JSON.stringify(
          pageData?.response?.DefaultPackagePerSeat_List
        ),
        TaxRates_Selected: JSON.stringify(selectedTax),
        CurrencyCode_Selected: currencySelected
          ? JSON.stringify(currencySelected)
          : JSON.stringify(userSelectedCurrency),
        ForeignCurrencyAmount: 0,
        ForeignCurrencyRate: ForeignCurrencyRate
          ? ForeignCurrencyRate
          : updatedExchangeRateData?.Rate ?? 1,
        Description: pageData?.response?.ScenarioHeader?.Description,
        SellPriceOverridden:
          pageData?.response?.MSPPackagePrice?.SellOverridden,
        ObjId_Customer: clientId ?? 0,
      })
        .then((response) => {
          setSaving(false);
          setUserAndDeviceCountChanged(false);
          if (!billingPeriod) {
            if (state?.backUrl) {
              history.push(state.backUrl, {
                packageFilterSave: state?.packageFilter,
                serviceFilterSave: state?.serviceFilter,
                productFilterSave: state?.productFilter,
              });
            } else {
              history.push("/mspv2/sell/packages", {
                packageFilterSave: state?.packageFilter,
                serviceFilterSave: state?.serviceFilter,
                productFilterSave: state?.productFilter,
              });
            }
          }
          resolve(response);
        })
        .catch((err) => {
          setSaving(false);

          reject(err);
        });
    });
  }

  async function updateExchangeRateData(_currency = "GBP") {
    let tempdata = {};
    await postData(authTokens, "GetCurrencyConverionRate", {
      // FromCurrency: _currency,
      // ToCurrency: defaultCurrency?.CurrencyName ?? "GBP",
      FromCurrency: defaultCurrency?.CurrencyName ?? "GBP",
      ToCurrency: _currency,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        setUpdateExchangeRateData(response);
        tempdata = response;
      })
      .catch((err) => {
        console.log(err);
      });
    return tempdata;
  }

  function updatePackageExchangeRateData(_currency = 0, packageId) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdatePackageForeignCurrencyRate", {
        PackageId: packageId,
        ForeignCurrencyRate: _currency,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
        .then((response) => {
          resolve(response);
          getPackageDetails(
            Number(scenarioId),
            clientId,
            userAndDeviceCountChanged
          );
          getFeaturesData(Number(scenarioId));
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
  useEffect(() => {
    if (pageData?.response?.CurrencyCode_Selected?.CurrencyName != userSelectedCurrency?.CurrencyName) {
      if (userSelectedCurrency?.CurrencyName || defaultCurrency?.CurrencyName) {
        updateExchangeRateData(
          userSelectedCurrency?.CurrencyName ??
          defaultCurrency?.CurrencyName ??
          "GBP"
        );
      }
    }
  }, [userSelectedCurrency]);

  useEffect(() => {
    if (currencyList?.length <= 0) {
      updateExchangeRateData();
    }
  }, [])

  let totalTax =
    packageData?.MSPPackagePrice?.Subtotal *
    packageData?.MSPPackagePrice?.Tax *
    0.01;
  let totalCost = (
    packageData?.MSPPackagePrice?.Subtotal -
    packageData?.MSPPackagePrice?.Discount +
    totalTax
  ).toFixed(2);

  useEffect(() => {
    // Check if the default service name already exists
    if (Number(packageId) === 0) {
      const defaultServiceName = "Security Package 1";
      let incrementedName = defaultServiceName;
      let counter = 1;

      // while (packagesList?.some((service) => service.Name == incrementedName)) {
      //   counter++;
      //   incrementedName = `Security Package ${counter}`;
      // }

      // // Set the default service name (incremented if necessary)
      // setNewPackageName(incrementedName);


      if (Number(packageId) === 0 /*&& packagesList.length*/) {
        console.log('here - ');
        const defaultServiceName = "Security Package 1";
        let incrementedName = defaultServiceName;
        let counter = 1;

        const checkUniqueName = async (nameToCheck) => {
          try {
            const response = await getAPIData(989, authTokens, {
              optionStrEnum1: nameToCheck
            });

            if (response?.Success) {
              counter++;
              incrementedName = `Security Package ${counter}`;
              return checkUniqueName(incrementedName);
            } else {
              setNewPackageName(nameToCheck);
              // createPackage(state?.clientId ?? 0, nameToCheck);
            }
          } catch (err) {
            console.log(err);
          }
        };

        checkUniqueName(incrementedName);
      }
    } else {
    }
  }, [packagesList, packageId]);

  const handleInputChange = (e) => {
    setErrors((prev) => ({
      ...prev,
      packageNameError: false,
      packageNameExistError: false,
    }));
    const newName = e?.target?.value;
    setNewPackageName(newName);

    // Check if the entered name is unique
    // setIsNameUnique(
    //   !packagesList?.some((service) => service.Name === newName?.trim())
    // );
  };

  // const handleInputBlur = (name) => {
  //   // Check uniqueness and set the service name in state
  //   if ((name ? name?.trim() : newPackageName?.trim()) === "") {
  //     // Optionally handle the case when the name is empty
  //     setErrors((prev) => ({
  //       ...prev,
  //       packageNameError: false,
  //       packageNameExistError: true,
  //     }));
  //     // setNewPackageName(packageName)
  //   } else if (isNameUnique === true) {
  //     setChanged(name !== packageName ? true : false);
  //     // Set the service name in state only if it's unique
  //     // updatePackageName(packageName);
  //     setErrors((prev) => ({
  //       ...prev,
  //       packageNameError: false,
  //       packageNameExistError: false,
  //     }));
  //   } else {
  //     // Optionally handle the case when the name is not unique
  //     setErrors((prev) => ({
  //       ...prev,
  //       packageNameError: true,
  //       packageNameExistError: false,
  //     }));
  //   }
  // };


  async function handleInputBlur(name) {
    console.log('packageName --- ', packageName)
    if (packageName != name) {

      if (name !== "") {
        setErrors((prev) => ({
          ...prev,
          packageNameError: false,
          packageNameExistError: false,
        }));
        await getAPIData(989, authTokens, {
          optionStrEnum1: name
        })
          .then((response) => {
            // setIsNameUnique(response?.Success);
            if (response?.Success) {
              setErrors((prev) => ({
                ...prev,
                packageNameError: true,
                packageNameExistError: false,
              }));
            } else {
              setChanged(name !== packageName ? true : false);
              setErrors((prev) => ({
                ...prev,
                packageNameError: false,
                packageNameExistError: false,
              }));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setErrors((prev) => ({
          ...prev,
          packageNameError: true,
          packageNameExistError: false,
        }));
      }
    }
  }

  const [inputSize, setInputSize] = useState(6.8);

  useEffect(() => {
    if (
      pageData?.response?.MSPPackagePrice?.SellOverridden &&
      pageData?.response?.MSPPackagePrice?.SellOverridden !== 0
    ) {
      let size =
        formatNumberWithTwoDecimalPlaces(
          pageData?.response?.MSPPackagePrice?.SellOverridden
        )?.toString()?.length +
          2.4 >
          6.4
          ? formatNumberWithTwoDecimalPlaces(
            pageData?.response?.MSPPackagePrice?.SellOverridden
          )?.toString()?.length + 2.4
          : 6.4;
      setInputSize(size);
    }
  }, [pageData?.response?.MSPPackagePrice?.SellOverridden]);

  function formatNumberWithTwoDecimalPlaces(number) {
    // Convert the number to a string with two decimal places
    let formattedNumber = Number(number)?.toFixed(2);

    // If the number has only one decimal place, add a trailing zero
    if (formattedNumber.indexOf(".") === formattedNumber.length - 2) {
      formattedNumber += "0";
    }

    return formattedNumber;
  }

  useEffect(() => {
    if (changed) {
      updatePackage(packageBillingPeriod?.DDLId).then(() => {
        getPackageDetails(Number(scenarioId), clientId, false);
        getFeaturesData(Number(scenarioId));
      });
    }
  }, [selectedTax]);

  const [showMspScoreModal, setShowMspScoreModal] = useState(false);
  const [showMspFeaturesModal, setShowMspFeaturesModal] = useState(false);
  const [showExploreMappingModal, setShowExploreMappingModal] = useState(false);
  const [showRadar, setShowRadar] = useState(false);
  const [serviceModalData, setServiceModalData] = useState({});

  async function updateRadarChart() {
    setLoading(true);
    await getAPIData(611, authTokens, {}).then((response) => {
      setShowRadar(response?.ShowNistGraph);
      setServiceModalData(response);
      setLoading(false);
    });
  }

  const [showAddEditTaxRateModal, setShowAddEditTaxRateModal] = useState(false);
  const [taxRateData, setTaxRateData] = useState({});

  function getTaxRateList() {
    return new Promise((resolve, reject) => {
      getAPIData("774", authTokens, {
        // ObjId_Customer: clientId,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
        .then((response) => {
          resolve(response);
          setTaxList(response?.TaxRates_List ?? []);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  function updateTaxList(stoploading = false) {
    if (!stoploading) {
      // setLoading(true);
    }
    getTaxRateList()
      .then((response) => {
        setTaxList(response?.TaxRates_List);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  }

  useEffect(() => {
    setSelectedServices(pageData?.service_list_data);
  }, [pageData]);
  function updateSelectedServices(service) {
    let found = false;
    // Check if any object in the selectedServices array matches passedId
    const foundIndex = selectedServices.findIndex(
      (obj) => obj?.objId_SecurityPack === service?.objId_SecurityPack
    );
    if (foundIndex !== -1) {
      // Remove the object if found
      selectedServices.splice(foundIndex, 1);
      found = true;
    }

    // If not found, add it to the array at index NistPillar
    if (!found) {
      selectedServices.push(service);
    }
    setSelectedServices(selectedServices);
    return selectedServices;
  }

  const [margin, setMargin] = useState(0);

  useEffect(() => {
    setMargin(
      (
        pageData?.response?.MSPPackagePrice?.SellOverridden -
        pageData?.response?.MSPPackagePrice?.Cost
      ).toFixed(2)
    );
  }, [pageData]);

  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowDropdownVisibilityArray(false);//([false, false, false, false, false]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [seatsIncludesError, setSeatsIncludesError] = useState(false);

  function updateSeatsIncludes(userAndDeviceCountChanged) {
    if (userAndDeviceCountChanged) {
      setChanged(true);
      if (
        pageData?.response?.DefaultPackagePerSeat_List?.some(
          (obj) => obj?.PerSeat != 0
        )
      ) {
        setTableLoader(true);
        setSeatsIncludesError(false);
        updatePackage(packageBillingPeriod?.DDLId).then(() => { });
      } else {
        setSeatsIncludesError(true);
      }
      setUserAndDeviceCountChanged(false);
    }
  }
  //---------------------------------------------------------------------------------------

  const [draggingId, setDraggingId] = useState(null);

  // const reorder = (list, startIndex, endIndex) => {
  //     const result = Array.from(list);
  //     const [removed] = result.splice(startIndex, 1);
  //     result.splice(endIndex, 0, removed);
  //     return result;
  // };

  // const onDragEndShown = (result) => {
  //     if (!result.destination) return;

  //     const items = reorder(
  //         selectedServices,
  //         result.source.index,
  //         result.destination.index
  //     );

  //     setSelectedServices(
  //         items.map((item, index) => ({
  //             ...item,
  //             SortOrder: index + 1,
  //         }))
  //     );
  // };

  const onDragEnd = (result) => {
    const { destination, source } = result;

    // Dropped outside the list
    if (!destination) return;

    // Reorder the array
    const updatedServices = Array.from(selectedServices);
    const [removed] = updatedServices.splice(source.index, 1);
    updatedServices.splice(destination.index, 0, removed);

    // Update state with reordered items
    setSelectedServices(updatedServices);
  };

  const [defaultUserDeviceDetails, setDefaultUserDeviceDetails] = useState([]);
  const [tempUserDetailsData, setTempUserDetailsData] = useState([]);

  // const [defaultUserDeviceDetails, setDefaultUserDeviceDetails] = useState({
  //   NoOfUsers: 1,
  //   NoOfWorkstation: 1,
  //   NoOfServices: 1,
  //   NoOfMobileDevices: 1,
  //   NoOfDomains: 1,
  //   NoOfRouters: 1,
  //   NoOfNetworkDevices: 1,
  // });
  // const [tempUserDetailsData, setTempUserDetailsData] = useState({
  //   NoOfUsers: 1,
  //   NoOfWorkstation: 1,
  //   NoOfServices: 1,
  //   NoOfMobileDevices: 1,
  //   NoOfDomains: 1,
  //   NoOfRouters: 1,
  //   NoOfNetworkDevices: 1,
  // });

  // const preDataState = Object.values(defaultUserDeviceDetails).every(
  //   (value) => value === 0
  // );
  const preDataState =
    defaultUserDeviceDetails.length !== 0 &&
    defaultUserDeviceDetails?.every((item) => item?.Qty === 0);

  const [savingAssets, setSavingAssets] = useState(false);

  function saveAssets() {
    setSavingAssets(true);
    setTableLoader(true);
    // let userDetails = { ...tempUserDetailsData };

    let userDetails = [...tempUserDetailsData];

    userDetails.forEach((item) => {
      if (item.Qty === "") {
        item.Qty = 0;
      }
    });

    updateSnapToUnitAssetCounts(userDetails)
      .then((res) => {
        document.getElementById("quantyAssetOnDashboard").click();
        setSavingAssets(false);
        getCustomerDevicesCount();
        getPackageDetails(
          Number(scenarioId),
          clientId,
          userAndDeviceCountChanged
        );
        getFeaturesData(Number(scenarioId));
        // CallToast("Updated successfully", false, false, "v2style");
      })
      .catch((err) => {
        setSavingAssets(false);
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // function updateQuantityOfAssets(_userDeviceDetails) {
  //   return new Promise((resolve, reject) => {
  //     postData(authTokens, "UpdateUserSettingsAssetCounts", {
  //       NoOfDomains: _userDeviceDetails.NoOfDomains,
  //       NoOfWorkstation: _userDeviceDetails.NoOfWorkstation,
  //       NoOfUsers: _userDeviceDetails.NoOfUsers,
  //       NoOfServers: _userDeviceDetails.NoOfServices,
  //       NoOfMobileDevices: _userDeviceDetails.NoOfMobileDevices,
  //       accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
  //       UpdateTarget: 3,
  //       NoOfRouters: _userDeviceDetails.NoOfRouters,
  //       NoOfNetworkDevices: _userDeviceDetails.NoOfNetworkDevices,
  //       objId_Customer: clientId,
  //     })
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // }

  // console.log('DefaultUserDeviceDetails ---------------------------- ', defaultUserDeviceDetails)

  function replaceEmptyWithZero(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === "") {
          obj[key] = 0;
        }
      }
    }
    return obj;
  }

  useEffect(() => {
    setTempUserDetailsData(defaultUserDeviceDetails);
  }, [defaultUserDeviceDetails]);

  function deleteData(objId) {
    return new Promise((resolve, reject) => {
      getAPIData(621, authTokens, {
        optionEnum1: objId,
      })
        .then((response) => {
          if (response?.Success) {
            // CallToast("Deleted successfully", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    });
  }
  async function sendReoderServices(packJson, nist) {
    await postData(authTokens, "SecurityPackSortOrder", {
      ObjId_Secnario: scenarioId,
      SecurityPack_Json: JSON.stringify(packJson),
      NistPillar: nist,
    })
      .then((response) => { })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  const [selectedTab, setSelectedTab] = useState(0);

  const billingModelData = [
    {
      DDLId: 1,
      DDLText: "Assets",
    },
    {
      DDLId: 0,
      DDLText: "Seats",
    },
  ];


  const showBy = [pageData?.SecurityCapabiltiesByNist_List?.length > 0 && {
    id: 0,
    label: 'Show by NIST CSF Function'
  },
  pageData?.SecurityCapabiltiesBySecurityPillar_List?.length > 0 && {
    id: 1,
    label: 'Show by Security Pillar'
  }]?.filter(Boolean)

  const [selectedShowBy, setSelectedShowBy] = useState(pageData?.SecurityCapabiltiesByNist_List?.length > 0 ? {
    id: 0,
    label: 'Show by NIST CSF Function'
  } : pageData?.SecurityCapabiltiesBySecurityPillar_List?.length > 0 && {
    id: 1,
    label: 'Show by Security Pillar'
  })
  const [selectedShowByData, setSelectedShowByData] = useState(pageData?.SecurityCapabiltiesByNist_List?.length > 0 ?
    pageData?.SecurityCapabiltiesByNist_List :
    pageData?.SecurityCapabiltiesBySecurityPillar_List?.length > 0 &&
    pageData?.SecurityCapabiltiesBySecurityPillar_List
  );

  console.log('pageData ---> ', pageData)


  const [mapCurrentSpendPackageId, setMapCurrentSpendPackageId] = useState(0);
  const [showMapCurrentSpendModal, setShowMapCurrentSpendModal] = useState(false);
  const [updateAfterClose, setUpdateAfterClose] = useState(false);
  const [mapCurrentSpendPackagePrice, setMapCurrentSpendPackagePrice] = useState(0);
  const [mapCurrentSpendPackagePriceText, setMapCurrentSpendPackagePriceText] = useState("");
  const [discount, setDiscount] = useState(0.00);

  console.log('packageData ---> ', packageData)

  const [resetting, setResetting] = useState(false);
  const [contribPrevData, setContribPrevData] = useState([]);
  const [isAddedFromResetContribution, setIsAddedFromResetContribution] = useState(false);

  return (
    <div className="integration-header">
      <div
        className="w-100 bg-black d-flex align-items-center justify-content-between"
        style={{
          position: "sticky",
          top: "56px",
          height: "51px",
          borderTop: "1px solid rgba(255, 255, 255, 0.08)",
          zIndex: 1,
        }}
      >
        <div
          className="d-flex align-items-center f-white f-500 ml-3 pointer"
          onClick={() => {
            if (changed) {
              setWarningModalConfig((prev) => ({
                ...prev,
                showWarningModal: true,
              }));
            } else {
              console.log(
                "test teste tstetet====",
                state?.packageFilter,
                state
              );
              if (state?.backUrl) {
                history.push(state.backUrl, {
                  packageFilterSave: state?.packageFilter,
                  serviceFilterSave: state?.serviceFilter,
                  productFilterSave: state?.productFilter,
                });
              } else {
                history.push("/mspv2/sell/packages", {
                  packageFilterSave: state?.packageFilter,
                  serviceFilterSave: state?.serviceFilter,
                  productFilterSave: state?.productFilter,
                });
              }
              if (Number(packageId) === 0) {
                deleteData(pageData?.response?.ScenarioHeader?.objId_Scenario);
              }
            }
          }}
        >
          <img
            alt=""
            src="/images/msp/msp-white-cross.svg"
            className="mr-3 pointer"
          />
          {Number(packageId) === 0 ? "Add" : "Edit"} Security Package
        </div>
        {/* Mapping Tabs List */}
        <div className="d-flex align-items-center"></div>

        <div className="mr-3">
          <Button
            className="bg-transparent border-none mr-2"
            onClick={() => {
              if (changed) {
                setWarningModalConfig((prev) => ({
                  ...prev,
                  showWarningModal: true,
                }));
              } else {
                if (state?.backUrl) {
                  history.push(state.backUrl, {
                    packageFilterSave: state?.packageFilter,
                    serviceFilterSave: state?.serviceFilter,
                    productFilterSave: state?.productFilter,
                  });
                } else {
                  history.push("/mspv2/sell/packages", {
                    packageFilterSave: state?.packageFilter,
                    serviceFilterSave: state?.serviceFilter,
                    productFilterSave: state?.productFilter,
                  });
                }
                if (Number(packageId) === 0) {
                  deleteData(
                    pageData?.response?.ScenarioHeader?.objId_Scenario
                  );
                }
              }
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              saving ||
              errors.packageNameError ||
              errors.packageNameExistError ||
              errors.packageDescriptionError ||
              errors.packageNameLengthError ||
              seatsIncludesError
            }
            className="hg-blue-btn"
            onClick={() => {
              if (billedByStatus?.DDLText === "Seats") {
                if (
                  pageData?.response?.DefaultPackagePerSeat_List?.some(
                    (obj) => obj?.PerSeat != 0
                  )
                ) {
                  setSeatsIncludesError(false);
                  if (selectedServices/*.some((arr) => arr*/.length !== 0/*)*/) {
                    setSaving(true);
                    updatePackage();
                  } else {
                    setIsSaveClicked(true);
                    setWarningModalConfig((prev) => ({
                      ...prev,
                      showWarningModal: true,
                    }));
                  }
                } else {
                  setSeatsIncludesError(true);
                }
              } else {
                if (selectedServices/*.some((arr) => arr*/.length !== 0/*)*/) {
                  setSaving(true);
                  updatePackage();
                } else {
                  setIsSaveClicked(true);
                  setWarningModalConfig((prev) => ({
                    ...prev,
                    showWarningModal: true,
                  }));
                }
              }
            }}
          >
            {saving ? "Saving.." : "Save"}
          </Button>
        </div>
      </div>

      {/* Whole UI fro the MSP Pricing Calculator */}
      <div className="msp-price-calculator justify-content-around">
        {loading ? (
          <MspPriceCalcultorLoader />
        ) : (
          <>
            {/* Left SIDE */}
            <div
              className="px-4"
              style={{
                minWidth: "650px",
                maxWidth: "650px",
                marginBottom: "50px",
              }}
            >
              <div className="f-14 f-600 f-black py-3">
                Security Package Details
              </div>

              <div className="f-400 f-14 f-black">Name</div>
              <div
                className={`highlight-input-with-grey-border   w-100  d-flex align-items-center p-2 radius-4 my-1   ${errors?.packageNameError || errors?.packageNameLengthError ? "input-error" : ""
                  } `}
              >
                {/* <div className="f-darkgrey">Name:</div> */}
                <input
                  tabIndex={0}
                  type="text"
                  placeholder="Package name"
                  className="flex-grow-1 pl-1 pr-2"
                  value={
                    Number(packageId) !== 0 ? newPackageName : newPackageName
                  }
                  onChange={(e) => {
                    if (e.target.value?.length > 35) {
                      setErrors((prev) => ({
                        ...prev,
                        packageNameLengthError: true,
                      }));
                      return;
                    };
                    setChanged(true);
                    setErrors((prev) => ({
                      ...prev,
                      packageNameLengthError: false,
                    }));
                    handleInputChange(e);
                  }}
                  onBlur={(e) => {
                    handleInputChange(e);
                    handleInputBlur(e.target.value);
                  }}
                />
                <div className="f-darkgrey">{newPackageName?.length}/35</div>
              </div>
              {errors?.packageNameExistError && (
                <div className="f-danger f-12 p-1">
                  Service name cannot be empty
                </div>
              )}
              {errors?.packageNameError && (
                <div className="f-danger f-12 p-1">
                  Service name already exists
                </div>
              )}
              {errors?.packageNameLengthError && (
                <div className="f-danger f-12 p-1">
                  Maximum description length of 35 characters has been
                  reached
                </div>
              )}

              <div className="f-400 f-14 f-black">Short Description</div>
              <div
                className={`highlight-input-with-grey-border w-100 d-flex align-items-center p-2 radius-4 my-1 ${errors?.packageDescriptionError && 'input-error'}`}
              >
                {/* <div className="f-darkgrey">Description:</div> */}
                <input
                  tabIndex={0}
                  type="text"
                  placeholder="Description"
                  className="flex-grow-1 pl-1 pr-2"
                  // value={
                  //   pageData?.response?.ScenarioHeader?.IsCustomPackage ?
                  //     `Improved Detection Package for ${pageData?.response?.ScenarioHeader?.ClientName}`
                  //   :
                  //     'Improved Detection Package for all'
                  // }
                  value={pageData?.response?.ScenarioHeader?.Description}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value?.length > 100) {
                      setErrors((prev) => ({
                        ...prev,
                        packageDescriptionError: true,
                      }));
                      return;
                    };
                    setChanged(true);
                    setErrors((prev) => ({
                      ...prev,
                      packageDescriptionError: false,
                    }));
                    setPageData((prevPageData) => ({
                      ...prevPageData,
                      response: {
                        ...prevPageData.response,
                        ScenarioHeader: {
                          ...prevPageData.response.ScenarioHeader,
                          Description: value,
                        },
                      },
                    }));
                  }}
                  onBlur={() => { }}
                />
                <div className="f-darkgrey">{pageData?.response?.ScenarioHeader?.Description?.length}/100</div>
              </div>
              {errors?.packageDescriptionError && (
                <div className="f-danger f-12 p-1">
                  Maximum description length of 100 characters has been
                  reached
                </div>
              )}

              <div className="mt-3">
                <div className="f-400 f-14 f-black mr-3 mb-1">Type</div>
                {isCustom ? (
                  <div className="d-flex align-content-center align-items-center">
                    <div className="f-500 f-14 f-black mr-2">Custom Package for</div>
                    {pageData?.response?.ScenarioHeader?.ClientLogo ? (
                      <img
                        alt=""
                        className="rounded-sm"
                        width={24}
                        height={24}
                        src={pageData?.response?.ScenarioHeader?.ClientLogo}
                      />
                    ) : (
                      <ImageWithInitials
                        initials={pageData?.response?.ScenarioHeader?.ClientName?.charAt(
                          0
                        )}
                        width={24}
                        background={"purple"}
                        borderRadius={"15"}
                      />
                    )}
                    <div className="f-500 аf14 ml-2 f-black">
                      {pageData?.response?.ScenarioHeader?.ClientName}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-content-center align-items-center">
                    <div
                      className="d-flex align-items-center justify-content-center radius-4 bg-greyish mr-2"
                      style={{
                        width: '24px',
                        height: '24px'
                      }}
                    >
                      <img
                        alt=""
                        className=""
                        width={12}
                        height={14}
                        src='/images/msp/package-icon3.svg'
                      />
                    </div>
                    <div className="f-500 а-14 f-black">MSP Standard Package</div>
                  </div>
                )}
              </div>


              <div className="my-4">
                <div className="f-black f-15 mt-1 mb-2 f-500">
                  Services
                </div>


                <>
                  <div className="w-100 bg-grey border-parent radius-8">
                    {
                      selectedServices?.length > 0 ?
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="services-list">
                            {(provided) => (
                              <ul {...provided.droppableProps} ref={provided.innerRef}>
                                {selectedServices.map((serviceItem, serviceItemIndex) => {
                                  const draggableId = `draggable-${serviceItem.objId_SecurityPack}`;

                                  return (
                                    <Draggable
                                      id={`draggable-${serviceItem.objId_SecurityPack}`}
                                      key={serviceItem.objId_SecurityPack}
                                      draggableId={draggableId}
                                      index={serviceItemIndex}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className="border-item d-flex align-items-center justify-content-between p-3 pointer bg-hover-dark-grey hover-on-show-parent"
                                        >
                                          <div
                                            className="d-flex align-items-center w-100"
                                            onClick={() =>
                                              addEditServiceByModal(serviceItem.objId_SecurityPack)
                                            }
                                          >
                                            <img
                                              alt=""
                                              className="mr-2"
                                              width={16}
                                              src={
                                                isHovered ==
                                                  serviceItem?.objId_SecurityPack ||
                                                  snapshot.isDragging
                                                  ? "/images/supply-chain/drag-n-drop.svg" :
                                                  '/images/other-apps-icon.svg'
                                              }
                                            />
                                            <div className="d-flex flex-column">
                                              <div className="f-14 f-500 f-black">
                                                {serviceItem.Headline}
                                              </div>
                                              <div className="f-12 f-darkgrey">
                                                {serviceItem.IsAddOn ? "Add-on" : "Service"} • Cost •{" "}
                                                {serviceItem.AnnualPrice_Text} • Sell •{" "}
                                                {serviceItem.MonthlyPrice_Text}
                                                <span className="f-12 txt-blue">
                                                  {serviceItem.IsOnDemand ? " • POD" : ""}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="d-flex align-items-center justify-content-center hover-on-show-child pointer"
                                            style={{ width: "26px", height: "26px" }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setChanged(true);
                                              updateSelectedServices(serviceItem);
                                              let updated_page_data = { ...pageData };
                                              setPageData(updated_page_data);
                                              add_or_delete_service_addOn(
                                                0,
                                                serviceItem.objId_SecurityPack
                                              )
                                                .then(() => {
                                                  setServicesLoader(true);
                                                  getDropdownDataForService();
                                                })
                                                .catch(() => { });
                                            }}
                                          >
                                            <img
                                              alt=""
                                              className=""
                                              width={12}
                                              height={12}
                                              src="/images/attack-surface/cross-icon.svg"
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </ul>
                            )}
                          </Droppable>
                        </DragDropContext> : <div
                          className="f-400 f-12 f-darkgrey px-2 py-1"
                        >
                          Add services to package
                        </div>
                    }

                    {showDropdownVisibilityArray ===
                      true && (
                        <ServiceDropdown
                          serviceSearchString={serviceSearchString}
                          setServiceSearchString={setServiceSearchString}
                          data={pageData?.service_dropdown_list}
                          _updateSelectedServices={
                            updateSelectedServices
                          }
                          selectedData={selectedServices}
                          _setSelectedServices={setSelectedServices}
                          _setIsAddonService={setIsAddOnService}
                          _setnistPillarType={setnistPillarType}
                          _isToggleShows={showDropdownVisibilityArray}
                          isLoading={servicesLoader}
                          _inputRef={inputRef}
                          onToggleItem={(childIndex) => {
                            setChanged(true);
                            setShowDropdownVisibilityArray(false);

                            let updated_page_data = { ...pageData };
                            let filtered_service_dropdown_list = updated_page_data?.service_dropdown_list?.filter(item =>
                              item?.Headline?.toLowerCase()?.includes(serviceSearchString?.toLowerCase())
                            );

                            let clicked_item = filtered_service_dropdown_list[childIndex];
                            if (clicked_item) {

                              let originalIndex = updated_page_data?.service_dropdown_list?.findIndex(item =>
                                item?.objId_SecurityPack === clicked_item?.objId_SecurityPack
                              );

                              if (originalIndex !== -1) {
                                updated_page_data.service_dropdown_list[originalIndex].loading = true;
                              }
                            }
                            if (clicked_item.Selected) {
                              add_or_delete_service_addOn(0, clicked_item.objId_SecurityPack, true)
                                .then(() => {
                                  setTableLoader(true);
                                  getPackageDetails(
                                    Number(scenarioId),
                                    clientId,
                                    userAndDeviceCountChanged
                                  );
                                  getFeaturesData(Number(scenarioId));
                                })
                                .catch(() => {
                                });
                            } else {
                              add_or_delete_service_addOn(clicked_item.objId_SecurityPack, 0, true)
                                .then(() => {
                                  setTableLoader(true);
                                  getPackageDetails(
                                    Number(scenarioId),
                                    clientId,
                                    userAndDeviceCountChanged
                                  );
                                  getFeaturesData(Number(scenarioId));
                                })
                                .catch(() => {
                                });
                            }
                            setPageData(updated_page_data);
                          }}
                          onClickAdd={() => {
                            setShowDropdownVisibilityArray(false);
                          }}
                          onClose={() => {
                            setShowDropdownVisibilityArray(false)
                          }}
                        />
                      )}
                    {showDropdownVisibilityArray !== true && (
                      <AddButtonForPackage
                        className={'hg-border-top w-100 bg-hover-dark-grey'}
                        onClick={() => {
                          setServicesLoader(true);
                          getDropdownDataForService();

                          setShowDropdownVisibilityArray(true);
                          getServiceList(1);
                        }}
                      />
                    )}
                  </div>
                </>
              </div>

              <hr />
              <div className="mt-4">
                <div className="f-600 f-14 f-black my-3">Financial Breakdown</div>
                <div>
                  <div className="f-400 f-14 f-black my-3">Billing model</div>
                  <div className="d-flex align-items-center">
                    {
                      billingModelData?.map((billingModelItem) => {
                        return (
                          <div
                            className={`px-2 py-1 mr-2 radius-4 ${billedByStatus?.DDLId == billingModelItem?.DDLId ? 'border-blue' : 'hg-border pointer'}`}
                            onClick={() => {
                              if (billedByStatus?.DDLId != billingModelItem?.DDLId) {
                                setSeatsIncludesError(false);
                                setBilledByStatus({
                                  DDLId: billingModelItem?.DDLId,
                                  DDLText: billingModelItem?.DDLText,
                                });
                                updatePackage(packageBillingPeriod?.DDLId, billingModelItem?.DDLId).then(
                                  () => {
                                    setChanged(true);
                                    getPackageDetails(
                                      Number(scenarioId),
                                      clientId,
                                      false
                                    );
                                    getFeaturesData(Number(scenarioId));
                                  }
                                );
                              }
                            }}
                          >
                            <div className={`f-500 f-14 ${billedByStatus?.DDLId == billingModelItem?.DDLId ? 'txt-blue' : 'f-grey'}`}>
                              {billingModelItem?.DDLText}
                            </div>
                            <div className="f-400 f-12 f-darkgrey">
                              {billingModelItem?.DDLId == 1 ? 'Billing based on clients assets quantities' : 'Billed based on the number of included assets'}
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>

                <div>

                </div>
              </div>

              {/* Seats and Assets Setup */}
              <div className="mt-4">
                {/* Pricing block */}

                <div>
                  {billedByStatus && (
                    <>
                      <div className="f-12 f-500 f-darkgrey mb-2 mt-3 d-flex align-items-center">
                        Pricing
                        {billedByStatus?.DDLText === "Seats" && tableLoader ? (
                          <div className="msp-service-update-loader ml-2 mr-1"></div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="d-flex align-items-center w-100 mb-3">
                        <div className="w-50">
                          <div className="d-flex f-500">
                            Billing period{" "}
                            <div className="msp-service-tool-tip-parent w-fit-content">
                              <img
                                className="ml-2 pointer"
                                src="/images/msp/ques-info-icon.svg"
                                alt=""
                              />
                              <MspServiceModalToolTip
                                data={{
                                  PackageHelpToolTip_Enum: 4,
                                  Name: "Billing Period",
                                  Description:
                                    "This is the billing period that this package is billed to your client. Options include monthly, quarterly and annually.",
                                  Image: "Billing_Period",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="w-50 ">
                          <div className="w-fit-content dropdown-grey-theme">
                            <CompanySettingsDropdown
                              fieldId={"DDLSort"}
                              fieldValue={"DDLText"}
                              selected={packageBillingPeriod}
                              rightAlign={true}
                              placeholder="Choose..."
                              onClick={(id, val, e, obj) => {
                                if (
                                  pageData?.response?.ScenarioHeader
                                    ?.BillingPeriod_Selected?.DDLSort !=
                                  obj.DDLSort
                                ) {
                                  setTableLoader(true);
                                  setPackageBillingPeriod({
                                    ObjId: id,
                                    DDLText: val,
                                    DDLId: obj.DDLId,
                                    DDLSort: obj.DDLSort,
                                  });
                                  updatePackage(obj.DDLId).then(() => {
                                    setChanged(true);
                                    getPackageDetails(
                                      Number(scenarioId),
                                      clientId,
                                      false
                                    );
                                    getFeaturesData(Number(scenarioId));
                                  });
                                }
                              }}
                              data={
                                pageData?.response?.BillingPeriod_List ?? []
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Currency and Tax UI for Packages */}
                  <div className="w-100 py-2 d-flex align-items-center f-black">
                    <div className="w-50">Currency</div>
                    <div className="w-50 d-flex align-items-center currency-dropdown-container">
                      <div className="">
                        <CurrencyDropdown
                          currencyList={currencyList}
                          menuClassName={
                            "right-align upload-only-template-drop"
                          }
                          onChange={(data) => {
                            setUserSelectedCurrency(data);
                            updateExchangeRateData(
                              data?.CurrencyName ??
                              defaultCurrency?.CurrencyName
                            ).then((response) => {
                              updatePackage(
                                packageBillingPeriod?.DDLId,
                                billedByStatus?.DDLId,
                                data,
                                response?.Rate
                              ).then(() => {
                                getPackageDetails(
                                  Number(scenarioId),
                                  clientId,
                                  false
                                );
                                getFeaturesData(Number(scenarioId));
                              });
                            });

                            setChanged(true);
                          }}
                          selected={userSelectedCurrency}
                        />
                      </div>
                    </div>
                  </div>

                  {billedByStatus?.DDLText === "Seats" && (
                    <div className="w-100">
                      <div className="w-100 d-flex align-items-center my-2 mb-3">
                        <div className="f-500 w-50">Seat Cost</div>
                        <div className="f-500 w-50">
                          <div>
                            {" "}
                            {pageData?.response?.MSPPackagePrice?.Cost_Text && (
                              <>{userSelectedCurrency?.CurrencySymbol}</>
                            )}
                            {numberWithCommas(
                              (
                                pageData?.response?.MSPPackagePrice?.Cost *
                                (exchangeRateData?.Rate ?? 1)
                              )?.toFixed(2)
                            )}
                            {" "}/
                            {
                              pageData?.response?.ScenarioHeader
                                ?.BillingPeriod_Selected?.DDLColour
                            }
                          </div>
                          {userSelectedCurrency?.CurrencySymbol !==
                            defaultCurrency?.CurrencySymbol && (
                              <div className="f-darkgrey">
                                <img
                                  alt=""
                                  width={13}
                                  height={13}
                                  src="/images/msp/exchange-rate-icon.svg"
                                  className="mr-2"
                                />
                                {pageData?.response?.MSPPackagePrice
                                  ?.Cost_Text && (
                                    <>
                                      {defaultCurrency?.CurrencySymbol ?? "£"}
                                    </>
                                  )}
                                {numberWithCommas(
                                  pageData?.response?.MSPPackagePrice?.Cost_Text
                                ) ??
                                  renderPriceText(
                                    pageData?.response?.ScenarioHeader
                                      ?.BillingPeriod_Selected?.DDLSort,
                                    0,
                                    userSelectedCurrency?.CurrencySymbol
                                  )}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="w-100 d-flex align-items-center my-2 mb-3">
                        <div className="d-flex align-items-center w-50">
                          <div className="f-500">Seat RRP</div>
                          <AttackSurfaceTooltip
                            content={
                              "Best practice is to achieve minimum 70% net margin on managed cyber security services"
                            }
                          >
                            <img
                              className="ml-2 pointer"
                              src="/images/msp/ques-info-icon.svg"
                              alt=""
                            />
                          </AttackSurfaceTooltip>
                        </div>
                        <div className="f-500 w-50">
                          {pageData?.response?.MSPPackagePrice?.RRP_Text && (
                            <>{userSelectedCurrency?.CurrencySymbol}</>
                          )}
                          {numberWithCommas(
                            (
                              pageData?.response?.MSPPackagePrice?.RRP *
                              (exchangeRateData?.Rate ?? 1)
                            )?.toFixed(2)
                          )}
                          {" "}/
                          {
                            pageData?.response?.ScenarioHeader
                              ?.BillingPeriod_Selected?.DDLColour
                          }
                        </div>
                      </div>

                      <div className="w-100 d-flex align-items-center my-2 mb-3">
                        <div className="f-500 w-50">Seat Price</div>
                        <div className="w-50 ">
                          <div className="w-100 d-flex align-items-center justify-content-between">
                            <div className="f-500 d-flex align-items-center">
                              <div
                                className="d-flex align-items-center radius-4 highlight-input-with-darker-grey-border"
                                style={{
                                  width: `${inputSize + 1}ch`,
                                  height: "34px",
                                }}
                              >
                                <div className="ml-1 mr-2">
                                  {userSelectedCurrency?.CurrencySymbol}
                                </div>
                                <input
                                  type="number"
                                  style={{
                                    width: `${inputSize + 1}ch`,
                                  }}
                                  className="bg-transparent  border-none w-100"
                                  placeholder="0.00"
                                  value={
                                    pageData?.response?.MSPPackagePrice
                                      ?.SellOverridden
                                  }
                                  onChange={(e) => {
                                    setChanged(true);
                                    let value = e.target.value;
                                    const regex = /^-?\d*\.?\d{0,2}$/;

                                    if (regex.test(value)) {
                                      let updated_data = { ...pageData };
                                      updated_data.response.MSPPackagePrice.SellOverridden =
                                        e.target.value;
                                      setPageData(updated_data);
                                    } else {
                                      value = value.slice(0, -1);
                                      let updated_data = { ...pageData };
                                      updated_data.response.MSPPackagePrice.SellOverridden =
                                        e.target.value;
                                      setPageData(updated_data);
                                    }
                                  }}
                                  onBlur={(e) => {
                                    setTableLoader(true);
                                    const value = e.target.value;
                                    if (!isNaN(value)) {
                                      if (value.includes(".")) {
                                        const decimalPartLength =
                                          value.split(".")[1].length;
                                        if (decimalPartLength === 1) {
                                          let updated_data = { ...pageData };
                                          updated_data.response.MSPPackagePrice.SellOverridden =
                                            value + "0";
                                          setPageData(updated_data);
                                        } else if (decimalPartLength > 2) {
                                          let updated_data = { ...pageData };
                                          updated_data.response.MSPPackagePrice.SellOverridden =
                                            Number(value).toFixed(2).toString();
                                          setPageData(updated_data);
                                        }
                                      } else {
                                        let updated_data = { ...pageData };
                                        updated_data.response.MSPPackagePrice.SellOverridden =
                                          value + ".00";
                                        setPageData(updated_data);
                                      }
                                    }
                                    updatePackage(
                                      packageBillingPeriod?.DDLId
                                    ).then(() => {
                                      getPackageDetails(
                                        Number(scenarioId),
                                        clientId,
                                        false
                                      );
                                      getFeaturesData(Number(scenarioId));
                                    });
                                  }}
                                />
                              </div>
                              <div className="ml-1">
                                /
                                {pageData?.response?.ScenarioHeader
                                  ?.BillingPeriod_Selected?.DDLSort === 2
                                  ? "year"
                                  : pageData?.response?.ScenarioHeader
                                    ?.BillingPeriod_Selected?.DDLColour}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-100 py-2 d-flex align-items-center f-black">
                        <div className="w-50">Seat Margin</div>
                        <div
                          className={`w-50 
                            ${margin < 0 && " redText "}
                      `}
                        >
                          {userSelectedCurrency?.CurrencySymbol}
                          {numberWithCommas(
                            (margin * (exchangeRateData?.Rate ?? 1))?.toFixed(2)
                          )}
                          {" "}/
                          {
                            pageData?.response?.ScenarioHeader
                              ?.BillingPeriod_Selected?.DDLColour
                          }
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="w-100 py-2 d-flex align-items-center f-black">
                    <div className="w-50">Tax Rate</div>
                    <div className="w-50 d-flex align-items-center justify-content-between">
                      <div className="tax-rate-dropdown-container">
                        <TaxRateDropdown
                          deleteTaxRate={deleteTaxRate}
                          updateTaxList={updateTaxList}
                          onChange={(data) => {
                            setChanged(true);
                            setTableLoader(true);
                            setSelectedTax(data);
                          }}
                          taxList={taxList}
                          selected={selectedTax}
                          onClickEditTaxRate={(data) => {
                            setTaxRateData(data);
                            setShowAddEditTaxRateModal(true);
                          }}
                          onClickAddTaxRate={() => {
                            setTaxRateData({
                              ObjectId: 0,
                              TaxCode: "",
                              TaxName: "",
                              TaxRate: "",
                            });
                            setShowAddEditTaxRateModal(true);
                          }}
                        />
                      </div>
                      <div
                        className={`add-edit-tax-rate-modal-wrapper ${showAddEditTaxRateModal ? "show mt-187px" : ""
                          }`}
                      >
                        <AddEditTaxRateModal
                          show={showAddEditTaxRateModal}
                          hideModal={() => {
                            setShowAddEditTaxRateModal(false);
                          }}
                          taxRateData={taxRateData}
                          updateTaxList={updateTaxList}
                          deleteTaxRate={deleteTaxRate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-4">
                {billedByStatus?.DDLText === "Assets" && (
                  <div>
                    {/* Price breakdown table */}
                    <div className="">
                      <div
                        className="w-100 d-flex align-items-center justify-content-between p-2 bg-hov er-grey radius-4 mt-2"
                      >
                        <div className="d-flex align-items-center justify-content-between w-100 f-500 f-12 f-darkgrey">
                          <div>
                            Package Pricing
                          </div>
                          {tableLoader ? (
                            <div className="msp-service-update-loader ml-2 mr-1"></div>
                          ) : (
                            <></>
                          )}
                          {/* {
                            isPackageDataChanged ? <div
                              className="txt-blue pointer"
                              onClick={() => {
                                setResetting(true);
                                setIsPackageDataChanged(false);
                                setPackageDataChanged({});
                                setResetting(false);
                              }}
                            >
                              <img
                                alt=""
                                className={`mr-1 ${resetting && "rotate-full"}`}
                                src="/images/msp/blue-load-icon.svg"
                              />
                              {resetting ? "Resetting.." : "Reset"}
                            </div> : <></>
                          } */}
                        </div>
                      </div>
                      <div
                        className="border-parent  radius-8 "
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div
                          className={`border-item f-12 f-500 f-darkgrey p-2 per_asset_period-breakdown_table`}
                        >
                          <div className="f-500 f-12 f-darkgrey">Asset Type</div>
                          <div className="f-500 f-12 f-darkgrey">Cost </div>
                          <div className="f-500 f-12 f-darkgrey">Sell</div>
                          <div className="f-500 f-12 f-darkgrey">Margin</div>
                        </div>
                        {
                          (isPackageDataChanged ? packageDataChanged?.MSPPackagePrice?.PackagePerAsset_List?.filter(
                            (item) => item.UnitCost !== 0
                          )?.length > 0 : packageData?.MSPPackagePrice?.PackagePerAsset_List?.filter(
                            (item) => item.UnitCost !== 0
                          )?.length > 0) ? <div>

                            {(isPackageDataChanged ? packageDataChanged?.MSPPackagePrice?.PackagePerAsset_List?.filter(
                              (item) => item.UnitCost !== 0
                            ) : packageData?.MSPPackagePrice?.PackagePerAsset_List?.filter(
                              (item) => item.UnitCost !== 0
                            ))?.map((data, dataIndex) => {
                              return (
                                <div
                                  className={`border-item f-grey p-2 per_asset_period-breakdown_table`}
                                >
                                  <div className="f-black f-500">
                                    {data.Name?.replace("Per ", "")}
                                  </div>
                                  <div>
                                    <OverlayTrigger
                                      placement="right"
                                      trigger={
                                        exchangeRateData?.FromCurrency &&
                                          exchangeRateData?.FromCurrency !==
                                          defaultCurrency?.CurrencyName
                                          ? ["hover", "focus"]
                                          : []
                                      }
                                      delay={{ show: 200, hide: 250 }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-1365`}
                                          className="custom_tooltip_access"
                                        >
                                          <div>
                                            {defaultCurrency?.CurrencySymbol ??
                                              "£"}{" "}
                                            {data.UnitCost?.toFixed(2)}
                                          </div>
                                          <div className="f-darkgrey">
                                            <img
                                              alt=""
                                              width={13}
                                              height={13}
                                              src="/images/msp/exchange-rate-icon.svg"
                                              className="mr-2"
                                            />
                                            {
                                              userSelectedCurrency?.CurrencySymbol
                                            }{" "}
                                            {(
                                              data.UnitCost *
                                              exchangeRateData?.Rate
                                            )?.toFixed(2)}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        {userSelectedCurrency?.CurrencySymbol}
                                        {(
                                          data?.UnitCost *
                                          (exchangeRateData?.Rate ?? 1)
                                        )?.toFixed(2)}
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                  {/* <IncreaseDecreaseNumbersComponentAlwaysHighlight
                                    type={"number"}
                                    value={(
                                      data?.UnitCost *
                                      (exchangeRateData?.Rate ?? 1)
                                    )?.toFixed(2)}
                                    isBlue={false}
                                    onChange={(value) => {
                                      let updatedPackageData = { 
                                        ...packageData, 
                                        MSPPackagePrice: {
                                          ...packageData.MSPPackagePrice, 
                                          PackagePerAsset_List: packageData.MSPPackagePrice.PackagePerAsset_List.map((item, index) =>
                                            index === dataIndex ? { ...item, UnitCost: value } : item
                                          )
                                        }
                                      };
                                      
                                      setIsPackageDataChanged(true);
                                      setPackageDataChanged(updatedPackageData);
                                      return true;
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  /> */}
                                  <div>
                                    {editState ? (
                                      <>
                                        <div className="highlight-input-with-grey-border p-2 mr-3 overflow-hidden">
                                          <input
                                            type="number"
                                            className="border-none bg-transparent overflow-hidden"
                                            value={data.UnitSell}
                                            onChange={(e) => {
                                              let updated_data = {
                                                ...packageData,
                                              };
                                              updated_data.MSPPackagePrice.PackagePerAsset_List[
                                                dataIndex
                                              ].IsEditedUnitSell = true;
                                              updated_data.MSPPackagePrice.PackagePerAsset_List[
                                                dataIndex
                                              ].UnitSell = e.target.value;
                                              setPackageData(updated_data);
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {data.IsEditedUnitSell ? (
                                          <OverlayTrigger
                                            placement="right"
                                            trigger={
                                              exchangeRateData?.FromCurrency &&
                                                exchangeRateData?.FromCurrency !==
                                                defaultCurrency?.CurrencyName
                                                ? ["hover", "focus"]
                                                : []
                                            }
                                            delay={{ show: 200, hide: 250 }}
                                            overlay={
                                              <Tooltip
                                                id={`tooltip-1365`}
                                                className="custom_tooltip_access"
                                              >
                                                <div>
                                                  {defaultCurrency?.CurrencySymbol ??
                                                    "£"}{" "}
                                                  {data.UnitSell?.toFixed(2)}
                                                </div>
                                                <div className="f-darkgrey">
                                                  <img
                                                    alt=""
                                                    width={13}
                                                    height={13}
                                                    src="/images/msp/exchange-rate-icon.svg"
                                                    className="mr-2"
                                                  />
                                                  {
                                                    userSelectedCurrency?.CurrencySymbol
                                                  }{" "}
                                                  {(
                                                    data.UnitSell *
                                                    exchangeRateData?.Rate
                                                  )?.toFixed(2)}
                                                </div>
                                              </Tooltip>
                                            }
                                          >
                                            <div className="f-blue position-relative">
                                              <span className="blue-dot" />
                                              {
                                                userSelectedCurrency?.CurrencySymbol
                                              }
                                              {(
                                                data.UnitSell *
                                                exchangeRateData?.Rate
                                              )?.toFixed(2)}{" "}
                                            </div>
                                          </OverlayTrigger>
                                        ) : (
                                          <OverlayTrigger
                                            placement="right"
                                            trigger={
                                              exchangeRateData?.FromCurrency &&
                                                exchangeRateData?.FromCurrency !==
                                                defaultCurrency?.CurrencyName
                                                ? ["hover", "focus"]
                                                : []
                                            }
                                            delay={{ show: 200, hide: 250 }}
                                            overlay={
                                              <Tooltip
                                                id={`tooltip-1365`}
                                                className="custom_tooltip_access"
                                              >
                                                <div>
                                                  {defaultCurrency?.CurrencySymbol ??
                                                    "£"}{" "}
                                                  {data.UnitSell?.toFixed(2)}
                                                </div>
                                                <div className="f-darkgrey">
                                                  <img
                                                    alt=""
                                                    width={13}
                                                    height={13}
                                                    src="/images/msp/exchange-rate-icon.svg"
                                                    className="mr-2"
                                                  />
                                                  {
                                                    userSelectedCurrency?.CurrencySymbol
                                                  }{" "}
                                                  {(
                                                    data.UnitSell *
                                                    exchangeRateData?.Rate
                                                  )?.toFixed(2)}
                                                </div>
                                              </Tooltip>
                                            }
                                          >
                                            <div>
                                              {
                                                userSelectedCurrency?.CurrencySymbol
                                              }
                                              {(
                                                data.UnitSell *
                                                exchangeRateData?.Rate
                                              )?.toFixed(2)}{" "}
                                            </div>
                                          </OverlayTrigger>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div>
                                    <OverlayTrigger
                                      placement="right"
                                      trigger={
                                        exchangeRateData?.FromCurrency &&
                                          exchangeRateData?.FromCurrency !==
                                          defaultCurrency?.CurrencyName
                                          ? ["hover", "focus"]
                                          : []
                                      }
                                      delay={{ show: 200, hide: 250 }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-1365`}
                                          className="custom_tooltip_access"
                                        >
                                          <div>
                                            {defaultCurrency?.CurrencySymbol ??
                                              "£"}{" "}
                                            {(data.UnitSell - data.UnitCost)?.toFixed(2)}
                                          </div>
                                          <div className="f-darkgrey">
                                            <img
                                              alt=""
                                              width={13}
                                              height={13}
                                              src="/images/msp/exchange-rate-icon.svg"
                                              className="mr-2"
                                            />
                                            {
                                              userSelectedCurrency?.CurrencySymbol
                                            }{" "}
                                            {(
                                              (data.UnitSell - data.UnitCost) *
                                              exchangeRateData?.Rate
                                            )?.toFixed(2)}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="f-500 f-grey">
                                        {renderPriceText(
                                          99,
                                          (
                                            (data.UnitSell - data.UnitCost) *
                                            (exchangeRateData?.Rate ?? 1)
                                          )?.toFixed(2),
                                          userSelectedCurrency?.CurrencySymbol
                                        )}
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              );
                            })}
                          </div> : <div
                            className="f-400 f-12 f-darkgrey p-2"
                          >
                            Here will be displayed assets from services
                          </div>
                        }
                      </div>
                    </div>

                    {/* Contribution Table */}

                    {
                      isCustom &&
                      <div className="mt-2">
                        <div
                          className="d-flex align-items-center justify-content-between- w-100"
                        >
                          <div
                            className="f-500 f-12 f-darkgrey my-1"
                          >
                            Contribution
                          </div>
                          {tableLoader ? (
                            <div className="msp-service-update-loader ml-2 mr-1"></div>
                          ) : (
                            <></>
                          )}
                          {
                            !tableLoader && isAddedFromResetContribution ? <div
                              className="txt-blue pointer"
                              onClick={() => {
                                setResetting(true);
                                setIsAddedFromResetContribution(false);
                                let updatedPackageData = {
                                  ...packageData,
                                  MSPPackagePrice: {
                                    ...packageData.MSPPackagePrice,
                                    PackageMapCurrentSpend_List: [...contribPrevData]
                                  }
                                };
                                setPackageData(updatedPackageData);
                                setResetting(false);
                              }}
                            >
                              <img
                                alt=""
                                className={`mr-1 ${resetting && "rotate-full"}`}
                                src="/images/msp/blue-load-icon.svg"
                              />
                              {resetting ? "Resetting.." : "Reset"}
                            </div> : <></>
                          }
                        </div>

                        <div
                          className="border-parent  radius-8"
                        >
                          <div
                            className="border-item f-12 f-500 f-darkgrey p-2 contribution_table"
                          >
                            <div>Recurring Item</div>
                            <div>Qty.</div>
                            <div>Price</div>
                            <div>Total</div>
                            <div>Period</div>
                          </div>
                          {packageData?.MSPPackagePrice?.PackageMapCurrentSpend_List
                            ?.map((data, dataIndex) => {
                              return (
                                <>
                                  {data.UnitCost !== 0 && (
                                    <div
                                      className="border-item p-2 contribution_table"
                                    >
                                      <div className="f-400 f-14 f-black">{data.ItemName}</div>
                                      <div className="f-400 f-14 greyText2">{data.Quantity}</div>
                                      <div className="f-400 f-14 greyText2">{data.Price}</div>
                                      <div className="f-400 f-14 greyText2">{data.Total}</div>
                                      <div className="f-400 f-14 greyText2">{data.Period_Text}</div>
                                    </div>
                                  )}
                                </>
                              );
                            })}

                          <div className="f-500 f-12 greyText2  p-2 border-item pointer"
                            onClick={() => {
                              setMapCurrentSpendPackageId(packageData?.ScenarioHeader?.objId_Scenario);
                              setMapCurrentSpendPackagePriceText(`${packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${Number(packageData?.MSPPackagePrice?.Subtotal + packageData?.MSPPackagePrice?.Tax).toFixed(2)} /${packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}`);
                              setMapCurrentSpendPackagePrice(Number(packageData?.MSPPackagePrice?.Subtotal).toFixed(2));
                              setShowMapCurrentSpendModal(true);
                              setIsAddedFromResetContribution(true);
                              setUpdateAfterClose(true);
                              const drag = document?.getElementById("map-current-spend-modal");
                              drag.style.display = "block";
                            }}
                          >
                            + Add
                          </div>

                          <div className="contribution_table p-2">
                            <div className="f-400 f-14 greyText2">
                              Total
                            </div>
                            <div></div>
                            <div></div>
                            <div>
                              {numberWithCommas(Number(packageData?.MSPPackagePrice?.Contribution)?.toFixed(2))}
                            </div>
                            <div className="f-400 f-14 greyText2">
                              /{packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {/* Summary Table */}

                    {
                      !isCustom &&
                      <div className="">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div
                            className="my-2 f-500 f-12 f-darkgrey"
                            onClick={(e) => {
                            }}
                          >
                            Summary
                          </div>
                          <div>
                            {tableLoader ? (
                              <div className="msp-service-update-loader ml-2 mr-1"></div>
                            ) : (
                              <></>
                            )}
                            {
                              !tableLoader && isAddedFromResetContribution ? <div
                                className="txt-blue pointer"
                                onClick={() => {
                                  setResetting(true);
                                  setIsAddedFromResetContribution(false);
                                  let updatedPackageData = {
                                    ...packageData,
                                    MSPPackagePrice: {
                                      ...packageData.MSPPackagePrice,
                                      PackageMapCurrentSpend_List: [...contribPrevData]
                                    }
                                  };
                                  setPackageData(updatedPackageData);
                                  setResetting(false);
                                }}
                              >
                                <img
                                  alt=""
                                  className={`mr-1 ${resetting && "rotate-full"}`}
                                  src="/images/msp/blue-load-icon.svg"
                                />
                                {resetting ? "Resetting.." : "Reset"}
                              </div> : <></>
                            }
                          </div>
                        </div>
                        <div
                          className="border-parent radius-8"
                        >
                          <div className="per_asset_total_pricing_table-updated border-item">
                            <div className="f-400 f-14 f-black pl-2">Subtotal</div>
                            <div></div>
                            <div>
                              {packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                              {Number(packageData?.MSPPackagePrice?.Subtotal)?.toFixed(2)}
                            </div>
                          </div>

                          <div className="per_asset_total_pricing_table-updated  border-item">
                            <div className="f-400 f-14 f-black pl-2">
                              Contribution
                            </div>
                            <div className="f-400 f-14 txt-blue underline pointer"
                              onClick={() => {
                                setMapCurrentSpendPackageId(packageData?.ScenarioHeader?.objId_Scenario);
                                setMapCurrentSpendPackagePriceText(`${packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${Number(packageData?.MSPPackagePrice?.Subtotal + packageData?.MSPPackagePrice?.Tax).toFixed(2)} /${packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}`);
                                setMapCurrentSpendPackagePrice(Number(packageData?.MSPPackagePrice?.Subtotal).toFixed(2));
                                setShowMapCurrentSpendModal(true);
                                setIsAddedFromResetContribution(true);
                                setUpdateAfterClose(true);
                                const drag = document?.getElementById("map-current-spend-modal");
                                drag.style.display = "block";
                              }}
                            >
                              -{packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                              {numberWithCommas(Number(packageData?.MSPPackagePrice?.Contribution)?.toFixed(2))}
                            </div>

                            <div className="f-400 f-14 greyText2">
                              {packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                              {numberWithCommas(
                                Number(packageData?.MSPPackagePrice?.Subtotal - packageData?.MSPPackagePrice?.Contribution).toFixed(2)
                              )}
                            </div>
                          </div>

                          <div className="per_asset_total_pricing_table-updated  border-item">
                            <div className="f-400 f-14 f-black pl-2">
                              Discount
                            </div>
                            <div className="f-400 f-14 " >
                              <IncreaseDecreaseNumbersComponentAlwaysHighlight
                                type={"number"}
                                floatValue={true}
                                children={<div
                                  className="f-400 f-14 f-black"
                                  style={{
                                    width: ' 25px'
                                  }}
                                >
                                  -{packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                                </div>}
                                value={discount ?? 0.00}
                                onChange={(value) => {
                                  setDiscount(value);
                                  return true;
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                            </div>

                            <div className="f-400 f-14 greyText2">
                              {packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                              {numberWithCommas(
                                Number(packageData?.MSPPackagePrice?.Subtotal - packageData?.MSPPackagePrice?.Contribution - discount).toFixed(2)
                              )}
                            </div>
                          </div>

                          <div className="per_asset_total_pricing_table-updated  border-item">
                            <div className="f-400 f-14 f-black pl-2">
                              {`
                            Tax @ ${packageData?.TaxRates_Selected?.TaxRate}% (${packageData?.TaxName})
                          ` ?? "Tax @ 0% (No Taxable)"}
                            </div>
                            <div className="f-400 f-14 greyText2">
                              {packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                              {Number(packageData?.MSPPackagePrice?.Tax)?.toFixed(2)}
                            </div>
                            <div className="f-400 f-14 greyText2">
                              {packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                              {Number(
                                packageData?.MSPPackagePrice?.Subtotal - packageData?.MSPPackagePrice?.Contribution - discount +
                                packageData?.MSPPackagePrice?.Tax
                              ).toFixed(2)}{" "}
                            </div>
                          </div>



                          <div className="per_asset_total_pricing_table-updated  border-item">
                            <div className="f-600 f-14 f-black pl-2">Total</div>
                            <div></div>
                            <div className="f-600 f-14 greyText2">
                              {renderPriceText(
                                packageData?.ScenarioHeader?.BillingPeriod_Selected
                                  ?.DDLSort,
                                (totalCost - packageData?.MSPPackagePrice?.Contribution - discount)?.toFixed(2),
                                packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {/* Margin Table */}
                    <div className="">
                      <div
                        className="d-flex align-items-center p-2 justify-content-between"
                      >
                        <div className=" f-12 f-darkgrey d-flex align-items-center">
                          Margin
                          {tableLoader ? (
                            <div className="msp-service-update-loader ml-2 mr-1"></div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div
                        className="border-block  radius-8"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="per_asset_period-breakdown_table border-item f-grey">
                          <div className="  pl-2">Period</div>
                          <div>Cost</div>
                          <div>Sell</div>
                          <div>Margin</div>
                        </div>

                        {packageData?.MSPPackagePrice?.PackagePeriodBreakdown_List?.map(
                          (data, index) => {
                            return (
                              <div className="per_asset_period-breakdown_table  border-item">
                                <div className="f-500  pl-2">
                                  {data?.Name}{" "}
                                </div>
                                <OverlayTrigger
                                  placement="right"
                                  trigger={
                                    exchangeRateData?.FromCurrency &&
                                      exchangeRateData?.FromCurrency !==
                                      defaultCurrency?.CurrencyName
                                      ? ["hover", "focus"]
                                      : []
                                  }
                                  delay={{ show: 200, hide: 250 }}
                                  overlay={
                                    <Tooltip
                                      id={`tooltip-1365`}
                                      className="custom_tooltip_access"
                                    >
                                      <div>
                                        {defaultCurrency?.CurrencySymbol ??
                                          "£"}{" "}
                                        {data.Cost?.toFixed(2)}
                                      </div>
                                      <div className="f-darkgrey">
                                        <img
                                          alt=""
                                          width={13}
                                          height={13}
                                          src="/images/msp/exchange-rate-icon.svg"
                                          className="mr-2"
                                        />
                                        {
                                          userSelectedCurrency?.CurrencySymbol
                                        }{" "}
                                        {(
                                          data.Cost * exchangeRateData?.Rate
                                        )?.toFixed(2)}
                                      </div>
                                    </Tooltip>
                                  }
                                >
                                  <div className="f-500 f-grey">
                                    {renderPriceText(
                                      99,
                                      (
                                        data?.Cost *
                                        (exchangeRateData?.Rate ?? 1)
                                      )?.toFixed(2),
                                      userSelectedCurrency?.CurrencySymbol
                                    )}
                                  </div>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="right"
                                  trigger={
                                    exchangeRateData?.FromCurrency &&
                                      exchangeRateData?.FromCurrency !==
                                      defaultCurrency?.CurrencyName
                                      ? ["hover", "focus"]
                                      : []
                                  }
                                  delay={{ show: 200, hide: 250 }}
                                  overlay={
                                    <Tooltip
                                      id={`tooltip-1365`}
                                      className="custom_tooltip_access"
                                    >
                                      <div>
                                        {defaultCurrency?.CurrencySymbol ??
                                          "£"}{" "}
                                        {data.Sell?.toFixed(2)}
                                      </div>
                                      <div className="f-darkgrey">
                                        <img
                                          alt=""
                                          width={13}
                                          height={13}
                                          src="/images/msp/exchange-rate-icon.svg"
                                          className="mr-2"
                                        />
                                        {
                                          userSelectedCurrency?.CurrencySymbol
                                        }{" "}
                                        {(
                                          data.Sell * exchangeRateData?.Rate
                                        )?.toFixed(2)}
                                      </div>
                                    </Tooltip>
                                  }
                                >
                                  <div className="f-500 f-grey">
                                    {renderPriceText(
                                      99,
                                      (
                                        data?.Sell *
                                        (exchangeRateData?.Rate ?? 1)
                                      )?.toFixed(2),
                                      userSelectedCurrency?.CurrencySymbol
                                    )}
                                  </div>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="right"
                                  trigger={
                                    exchangeRateData?.FromCurrency &&
                                      exchangeRateData?.FromCurrency !==
                                      defaultCurrency?.CurrencyName
                                      ? ["hover", "focus"]
                                      : []
                                  }
                                  delay={{ show: 200, hide: 250 }}
                                  overlay={
                                    <Tooltip
                                      id={`tooltip-1365`}
                                      className="custom_tooltip_access"
                                    >
                                      <div>
                                        {defaultCurrency?.CurrencySymbol ??
                                          "£"}{" "}
                                        {data.Margin?.toFixed(2)}
                                      </div>
                                      <div className="f-darkgrey">
                                        <img
                                          alt=""
                                          width={13}
                                          height={13}
                                          src="/images/msp/exchange-rate-icon.svg"
                                          className="mr-2"
                                        />
                                        {
                                          userSelectedCurrency?.CurrencySymbol
                                        }{" "}
                                        {(
                                          data.Margin *
                                          exchangeRateData?.Rate
                                        )?.toFixed(2)}
                                      </div>
                                    </Tooltip>
                                  }
                                >
                                  <div className="f-500 f-grey">
                                    {renderPriceText(
                                      99,
                                      (
                                        data?.Margin *
                                        (exchangeRateData?.Rate ?? 1)
                                      )?.toFixed(2),
                                      userSelectedCurrency?.CurrencySymbol
                                    )}
                                  </div>
                                </OverlayTrigger>
                              </div>
                            );
                          }
                        )}
                        {
                          selectedServices?.some(item => item?.IsOnDemand === true) ?
                            <div className="px-2 py-3">
                              <div className="d-flex align-items-center">
                                <img
                                  alt=""
                                  width={13}
                                  height={13}
                                  src="/images/info-blue.svg"
                                  className="mr-2"
                                />
                                <div className="f-500 f-14 txt-blue">Price On-Demand</div>
                              </div>
                              <div className="f-400 f-12 f-darkgrey">This package includes services which are priced on demand</div>
                            </div> : <></>
                        }
                      </div>
                    </div>
                    <div
                      className="mt-2"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "83% 10%",
                      }}
                    >
                      <div>
                        {exchangeRateData?.FromCurrency &&
                          exchangeRateData?.FromCurrency !==
                          defaultCurrency?.CurrencyName && (
                            <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
                              <img
                                alt=""
                                width={13}
                                height={13}
                                src="/images/msp/exchange-rate-icon.svg"
                                className="mr-2"
                              />{" "}
                              The exchange rate on{" "}
                              {moment(
                                updatedExchangeRateData?.Date ?? ""
                              )?.format("DD MMM YYYY")}{" "}
                              is 1 {updatedExchangeRateData?.FromCurrency} ={" "}
                              {updatedExchangeRateData?.Rate}{" "}
                              {defaultCurrency?.CurrencyName}
                            </div>
                          )}
                        {exchangeRateData?.FromCurrency &&
                          exchangeRateData?.FromCurrency !==
                          defaultCurrency?.CurrencyName && (
                            <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
                              <img
                                alt=""
                                width={13}
                                height={13}
                                src="/images/msp/exchange-rate-icon.svg"
                                className="mr-2"
                              />{" "}
                              The exchange rate when this package was
                              created (
                              {moment(exchangeRateData?.Date ?? "")?.format(
                                "DD MMM YYYY"
                              )}
                              ) was 1 {exchangeRateData?.FromCurrency} ={" "}
                              {exchangeRateData?.Rate}{" "}
                              {defaultCurrency?.CurrencyName}
                            </div>
                          )}
                      </div>

                      {exchangeRateData?.FromCurrency &&
                        exchangeRateData?.FromCurrency !==
                        defaultCurrency?.CurrencyName && (
                          <div
                            className="f-blue pointer"
                            onClick={() => {
                              updatePackageExchangeRateData(
                                exchangeRateData?.Rate,
                                scenarioId
                              );
                            }}
                          >
                            Update
                          </div>
                        )}
                    </div>
                  </div>
                )}
                {billedByStatus?.DDLText === "Seats" && (
                  <div>

                    <div>
                      <div
                        className="d-flex align-items-center justify-content-between"
                      >
                        <div
                          className={`my-2 f-12 f-darkgrey d-flex align-items-center ${!isCustom && "mt-4"
                            }`}
                        >
                          Seat includes
                          {tableLoader ? (
                            <div className="msp-service-update-loader ml-2 mr-1"></div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {seatsIncludesError && (
                        <div className="f-danger f-12 pb-1 px-1">
                          At least one value should not be 0
                        </div>
                      )}

                      <div
                        className="border-parent  radius-8"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="per_seat_includes_table border-item f-grey">
                          <div className="f-500 f-12 f-darkgrey pl-2">Asset Type</div>
                          <div className="f-500 f-12 f-darkgrey">Qnt.</div>
                        </div>
                        {/* <div className="d-flex flex-wrap   msp_assets_inputs_wrapper"> */}
                        {pageData?.response?.DefaultPackagePerSeat_List?.map(
                          (item, index) => {
                            return (
                              <div className="per_seat_includes_table border-item f-grey px-2">
                                <div className="f-400 f-14 f-black flex-shrink-0">
                                  {item?.Name?.replace("Per ", "")}
                                </div>
                                <div
                                  className={`highlight-input-with-grey-border d-flex align-items-center py-1 px-2 radius-4 mt-2   ${seatsIncludesError ? "input-error" : ""
                                    } `}
                                >
                                  {/* <IncreaseDecreaseNumbersComponentAlwaysHighlight
                                    type={"number"}
                                    // floatValue={false}
                                    value={item?.PerSeat ?? ""}
                                    isBlue={false}
                                    onChange={(value) => {
                                      setChanged(true);
                                      setUserAndDeviceCountChanged(true);
                                      let newData = value;
                                      let updated_data = { ...pageData };
                                      updated_data.response.DefaultPackagePerSeat_List[
                                        index
                                      ].PerSeat = newData;
                                      updated_data.response.DefaultPackagePerSeat_List[
                                        index
                                      ].PerSeat = newData;
                                      setPageData(updated_data);
                                      return true;
                                    }}
                                    onClick={(e) => {
                                      if (
                                        pageData?.response
                                          ?.DefaultPackagePerSeat_List[index]
                                          ?.PerSeat === "0" ||
                                        pageData?.response
                                          ?.DefaultPackagePerSeat_List[index]
                                          ?.PerSeat === 0
                                      ) {
                                        let updated_data = { ...pageData };
                                        updated_data.response.DefaultPackagePerSeat_List[
                                          index
                                        ].PerSeat = "";
                                        updated_data.response.DefaultPackagePerSeat_List[
                                          index
                                        ].PerSeat = "";
                                        setPageData(updated_data);
                                      }
                                      e.stopPropagation();
                                    }}
                                    onBlur={() => {
                                      if (
                                        pageData?.response
                                          ?.DefaultPackagePerSeat_List[index]
                                          ?.PerSeat === ""
                                      ) {
                                        let updated_data = { ...pageData };
                                        updated_data.response.DefaultPackagePerSeat_List[
                                          index
                                        ].PerSeat = "0";
                                        updated_data.response.DefaultPackagePerSeat_List[
                                          index
                                        ].PerSeat = "0";
                                        setPageData(updated_data);
                                      }
                                      if (
                                        pageData?.response?.DefaultPackagePerSeat_List?.some(
                                          (obj) => obj?.PerSeat != 0
                                        )
                                      ) {
                                        setSeatsIncludesError(false);
                                      } else {
                                        setSeatsIncludesError(true);
                                      }
                                      setTableLoader(true);
                                      updatePackage(packageBillingPeriod?.DDLId).then(() => {
                                        getPackageDetails(Number(scenarioId), clientId, false);
                                        getFeaturesData(Number(scenarioId));
                                      });
                                    }}
                                  /> */}
                                  <input
                                    tabIndex={0}
                                    type="number"
                                    placeholder="Type"
                                    className=" pl-1 pr-2"
                                    value={item?.PerSeat ?? ""}
                                    onChange={(e) => {
                                      setChanged(true);
                                      setUserAndDeviceCountChanged(true);
                                      let newData = e.target.value;
                                      let updated_data = { ...pageData };
                                      updated_data.response.DefaultPackagePerSeat_List[
                                        index
                                      ].PerSeat = newData;
                                      updated_data.response.DefaultPackagePerSeat_List[
                                        index
                                      ].PerSeat = newData;
                                      setPageData(updated_data);
                                    }}
                                    onClick={() => {
                                      if (
                                        pageData?.response
                                          ?.DefaultPackagePerSeat_List[index]
                                          ?.PerSeat === "0" ||
                                        pageData?.response
                                          ?.DefaultPackagePerSeat_List[index]
                                          ?.PerSeat === 0
                                      ) {
                                        let updated_data = { ...pageData };
                                        updated_data.response.DefaultPackagePerSeat_List[
                                          index
                                        ].PerSeat = "";
                                        updated_data.response.DefaultPackagePerSeat_List[
                                          index
                                        ].PerSeat = "";
                                        setPageData(updated_data);
                                      }
                                    }}
                                    onBlur={() => {
                                      if (
                                        pageData?.response
                                          ?.DefaultPackagePerSeat_List[index]
                                          ?.PerSeat === ""
                                      ) {
                                        let updated_data = { ...pageData };
                                        updated_data.response.DefaultPackagePerSeat_List[
                                          index
                                        ].PerSeat = "0";
                                        updated_data.response.DefaultPackagePerSeat_List[
                                          index
                                        ].PerSeat = "0";
                                        setPageData(updated_data);
                                      }
                                      if (
                                        pageData?.response?.DefaultPackagePerSeat_List?.some(
                                          (obj) => obj?.PerSeat != 0
                                        )
                                      ) {
                                        setSeatsIncludesError(false);
                                      } else {
                                        setSeatsIncludesError(true);
                                      }
                                      setTableLoader(true);
                                      updatePackage(packageBillingPeriod?.DDLId).then(() => {
                                        getPackageDetails(Number(scenarioId), clientId, false);
                                        getFeaturesData(Number(scenarioId));
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        )}
                        {/* </div> */}
                      </div>
                      {

                      }
                    </div>

                    {/* Contribution Table */}

                    {
                      isCustom &&
                      <div className="mt-2">
                        <div
                          className="d-flex align-items-center justify-content-between w-100"
                        >
                          <div
                            className="f-500 f-12 f-darkgrey my-1"
                          >
                            Contribution
                          </div>
                          {tableLoader ? (
                            <div className="msp-service-update-loader ml-2 mr-1"></div>
                          ) : (
                            <></>
                          )}
                          {
                            !tableLoader && isAddedFromResetContribution ? <div
                              className="txt-blue pointer"
                              onClick={() => {
                                setResetting(true);
                                setIsAddedFromResetContribution(false);
                                let updatedPackageData = {
                                  ...packageData,
                                  MSPPackagePrice: {
                                    ...packageData.MSPPackagePrice,
                                    PackageMapCurrentSpend_List: [...contribPrevData]
                                  }
                                };
                                setPackageData(updatedPackageData);
                                setResetting(false);
                              }}
                            >
                              <img
                                alt=""
                                className={`mr-1 ${resetting && "rotate-full"}`}
                                src="/images/msp/blue-load-icon.svg"
                              />
                              {resetting ? "Resetting.." : "Reset"}
                            </div> : <></>
                          }
                        </div>

                        <div
                          className="border-parent  radius-8"
                        >
                          <div
                            className="border-item f-12 f-500 f-darkgrey p-2 contribution_table"
                          >
                            <div>Recurring Item</div>
                            <div>Qty.</div>
                            <div>Price</div>
                            <div>Total</div>
                            <div>Period</div>
                          </div>
                          {packageData?.MSPPackagePrice?.PackageMapCurrentSpend_List
                            ?.map((data, dataIndex) => {
                              return (
                                <>
                                  {data.UnitCost !== 0 && (
                                    <div
                                      className="border-item p-2 contribution_table"
                                    >
                                      <div className="f-400 f-14 f-black">{data.ItemName}</div>
                                      <div className="f-400 f-14 greyText2">{data.Quantity}</div>
                                      <div className="f-400 f-14 greyText2">{data.Price}</div>
                                      <div className="f-400 f-14 greyText2">{data.Total}</div>
                                      <div className="f-400 f-14 greyText2">{data.Period_Text}</div>
                                    </div>
                                  )}
                                </>
                              );
                            })}

                          <div className="f-500 f-12 greyText2  p-2 border-item pointer"
                            onClick={() => {
                              setMapCurrentSpendPackageId(packageData?.ScenarioHeader?.objId_Scenario);
                              setMapCurrentSpendPackagePriceText(`${packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${Number(packageData?.MSPPackagePrice?.Subtotal + packageData?.MSPPackagePrice?.Tax).toFixed(2)} /${packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}`);
                              setMapCurrentSpendPackagePrice(Number(packageData?.MSPPackagePrice?.Subtotal).toFixed(2));
                              setShowMapCurrentSpendModal(true);
                              setIsAddedFromResetContribution(true);
                              setUpdateAfterClose(true);
                              const drag = document?.getElementById("map-current-spend-modal");
                              drag.style.display = "block";
                            }}
                          >
                            + Add
                          </div>

                          <div className="contribution_table p-2">
                            <div className="f-400 f-14 greyText2">
                              Total
                            </div>
                            <div></div>
                            <div></div>
                            <div>
                              {numberWithCommas(Number(packageData?.MSPPackagePrice?.Contribution)?.toFixed(2))}
                            </div>
                            <div className="f-400 f-14 greyText2">
                              /{packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {/* Summary Table */}

                    {
                      isCustom &&
                      <div className="">
                        <div className="d-flex align-items-center w-100">
                          <div
                            className="my-2 f-500 f-12 f-darkgrey"
                            onClick={(e) => {
                            }}
                          >
                            Summary
                          </div>
                          {tableLoader ? (
                            <div className="msp-service-update-loader ml-2 mr-1"></div>
                          ) : (
                            <></>
                          )}
                          {
                              !tableLoader && isAddedFromResetContribution ? <div
                                className="txt-blue pointer"
                                onClick={() => {
                                  setResetting(true);
                                  setIsAddedFromResetContribution(false);
                                  let updatedPackageData = {
                                    ...packageData,
                                    MSPPackagePrice: {
                                      ...packageData.MSPPackagePrice,
                                      PackageMapCurrentSpend_List: [...contribPrevData]
                                    }
                                  };
                                  setPackageData(updatedPackageData);
                                  setResetting(false);
                                }}
                              >
                                <img
                                  alt=""
                                  className={`mr-1 ${resetting && "rotate-full"}`}
                                  src="/images/msp/blue-load-icon.svg"
                                />
                                {resetting ? "Resetting.." : "Reset"}
                              </div> : <></>
                            }
                        </div>
                        <div
                          className="border-parent radius-8"
                        >
                          <div className="per_asset_total_pricing_table-updated border-item">
                            <div className="f-400 f-14 f-black pl-2">Subtotal</div>
                            <div></div>
                            <div>
                              {packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                              {Number(packageData?.MSPPackagePrice?.Subtotal)?.toFixed(2)}
                            </div>
                          </div>

                          <div className="per_asset_total_pricing_table-updated  border-item">
                            <div className="f-400 f-14 f-black pl-2">
                              Contribution
                            </div>
                            <div className="f-400 f-14 txt-blue underline pointer"
                              onClick={() => {
                                setMapCurrentSpendPackageId(packageData?.ScenarioHeader?.objId_Scenario);
                                setMapCurrentSpendPackagePriceText(`${packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${Number(packageData?.MSPPackagePrice?.Subtotal + packageData?.MSPPackagePrice?.Tax).toFixed(2)} /${packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}`);
                                setMapCurrentSpendPackagePrice(Number(packageData?.MSPPackagePrice?.Subtotal).toFixed(2));
                                setShowMapCurrentSpendModal(true);
                                setIsAddedFromResetContribution(true);
                                setUpdateAfterClose(true);
                                const drag = document?.getElementById("map-current-spend-modal");
                                drag.style.display = "block";
                              }}
                            >
                              -{packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                              {numberWithCommas(Number(packageData?.MSPPackagePrice?.Contribution)?.toFixed(2))}
                            </div>

                            <div className="f-400 f-14 greyText2">
                              {packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                              {numberWithCommas(
                                Number(packageData?.MSPPackagePrice?.Subtotal - packageData?.MSPPackagePrice?.Contribution).toFixed(2)
                              )}
                            </div>
                          </div>

                          <div className="per_asset_total_pricing_table-updated  border-item">
                            <div className="f-400 f-14 f-black pl-2">
                              Discount
                            </div>
                            <div className="f-400 f-14 " >
                              <IncreaseDecreaseNumbersComponentAlwaysHighlight
                                type={"number"}
                                floatValue={true}
                                children={<div
                                  className="f-400 f-14 f-black"
                                  style={{
                                    width: ' 25px'
                                  }}
                                >
                                  -{packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                                </div>}
                                value={discount ?? 0.00}
                                onChange={(value) => {
                                  setDiscount(value);
                                  return true;
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                            </div>

                            <div className="f-400 f-14 greyText2">
                              {packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                              {numberWithCommas(
                                Number(packageData?.MSPPackagePrice?.Subtotal - packageData?.MSPPackagePrice?.Contribution - discount).toFixed(2)
                              )}
                            </div>
                          </div>

                          <div className="per_asset_total_pricing_table-updated  border-item">
                            <div className="f-400 f-14 f-black pl-2">
                              {`
                            Tax @ ${packageData?.TaxRates_Selected?.TaxRate}% (${packageData?.TaxName})
                          ` ?? "Tax @ 0% (No Taxable)"}
                            </div>
                            <div className="f-400 f-14 greyText2">
                              {packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                              {Number(packageData?.MSPPackagePrice?.Tax)?.toFixed(2)}
                            </div>
                            <div className="f-400 f-14 greyText2">
                              {packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                              {Number(
                                packageData?.MSPPackagePrice?.Subtotal - packageData?.MSPPackagePrice?.Contribution - discount +
                                packageData?.MSPPackagePrice?.Tax
                              ).toFixed(2)}{" "}
                            </div>
                          </div>



                          <div className="per_asset_total_pricing_table-updated  border-item">
                            <div className="f-600 f-14 f-black pl-2">Total</div>
                            <div></div>
                            <div className="f-600 f-14 greyText2">
                              {renderPriceText(
                                packageData?.ScenarioHeader?.BillingPeriod_Selected
                                  ?.DDLSort,
                                (totalCost - packageData?.MSPPackagePrice?.Contribution - discount)?.toFixed(2),
                                packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    <div className="">
                      <div
                        className="d-flex align-items-center justify-content-between pointer"
                      >
                        <div
                          className={`my-2 f-12 f-darkgrey d-flex align-items-center ${!isCustom && "mt-4"
                            }`}
                        >
                          Breakdown by period
                          {tableLoader ? (
                            <div className="msp-service-update-loader ml-2 mr-1"></div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div
                        className="border-block  radius-8"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="per_asset_period-breakdown_table border-item f-grey">
                          <div className="  pl-2">Period</div>
                          <div>Cost</div>
                          <div>Sell</div>
                          <div>Margin</div>
                        </div>
                        {packageData?.MSPPackagePrice?.PackagePeriodBreakdown_List?.map(
                          (data, index) => {
                            return (
                              <div className="per_asset_period-breakdown_table  border-item">
                                <div className="f-500  pl-2">
                                  {data?.Name}
                                </div>
                                <OverlayTrigger
                                  placement="right"
                                  trigger={
                                    exchangeRateData?.FromCurrency &&
                                      exchangeRateData?.FromCurrency !==
                                      defaultCurrency?.CurrencyName
                                      ? ["hover", "focus"]
                                      : []
                                  }
                                  delay={{ show: 200, hide: 250 }}
                                  overlay={
                                    <Tooltip
                                      id={`tooltip-1365`}
                                      className="custom_tooltip_access"
                                    >
                                      <div>
                                        {defaultCurrency?.CurrencySymbol ??
                                          "£"}{" "}
                                        {data.Cost?.toFixed(2)}
                                      </div>
                                      <div className="f-darkgrey">
                                        <img
                                          alt=""
                                          width={13}
                                          height={13}
                                          src="/images/msp/exchange-rate-icon.svg"
                                          className="mr-2"
                                        />
                                        {/* {
                                                    userSelectedCurrency?.CurrencySymbol
                                                  } */}
                                        {
                                          userSelectedCurrency?.CurrencySymbol
                                        }{" "}
                                        {(
                                          data.Cost * exchangeRateData?.Rate
                                        )?.toFixed(2)}
                                      </div>
                                    </Tooltip>
                                  }
                                >
                                  <div className="f-500 f-grey">
                                    {renderPriceText(
                                      99,
                                      (
                                        data?.Cost *
                                        (exchangeRateData?.Rate ?? 1)
                                      )?.toFixed(2),
                                      userSelectedCurrency?.CurrencySymbol
                                    )}
                                  </div>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="right"
                                  trigger={
                                    exchangeRateData?.FromCurrency &&
                                      exchangeRateData?.FromCurrency !==
                                      defaultCurrency?.CurrencyName
                                      ? ["hover", "focus"]
                                      : []
                                  }
                                  delay={{ show: 200, hide: 250 }}
                                  overlay={
                                    <Tooltip
                                      id={`tooltip-1365`}
                                      className="custom_tooltip_access"
                                    >
                                      <div>
                                        {defaultCurrency?.CurrencySymbol ??
                                          "£"}{" "}
                                        {data.Sell?.toFixed(2)}
                                      </div>
                                      <div className="f-darkgrey">
                                        <img
                                          alt=""
                                          width={13}
                                          height={13}
                                          src="/images/msp/exchange-rate-icon.svg"
                                          className="mr-2"
                                        />
                                        {/* {
                                                    userSelectedCurrency?.CurrencySymbol
                                                  } */}
                                        {
                                          userSelectedCurrency?.CurrencySymbol
                                        }{" "}
                                        {(
                                          data.Sell * exchangeRateData?.Rate
                                        )?.toFixed(2)}
                                      </div>
                                    </Tooltip>
                                  }
                                >
                                  <div className="f-500 f-grey">
                                    {renderPriceText(
                                      99,
                                      (
                                        data?.Sell *
                                        (exchangeRateData?.Rate ?? 1)
                                      )?.toFixed(2),
                                      userSelectedCurrency?.CurrencySymbol
                                    )}
                                  </div>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="right"
                                  trigger={
                                    exchangeRateData?.FromCurrency &&
                                      exchangeRateData?.FromCurrency !==
                                      defaultCurrency?.CurrencyName
                                      ? ["hover", "focus"]
                                      : []
                                  }
                                  delay={{ show: 200, hide: 250 }}
                                  overlay={
                                    <Tooltip
                                      id={`tooltip-1365`}
                                      className="custom_tooltip_access"
                                    >
                                      <div>
                                        {defaultCurrency?.CurrencySymbol ??
                                          "£"}{" "}
                                        {data.Margin?.toFixed(2)}
                                      </div>
                                      <div className="f-darkgrey">
                                        <img
                                          alt=""
                                          width={13}
                                          height={13}
                                          src="/images/msp/exchange-rate-icon.svg"
                                          className="mr-2"
                                        />
                                        {/* {
                                                    userSelectedCurrency?.CurrencySymbol
                                                  } */}
                                        {
                                          userSelectedCurrency?.CurrencySymbol
                                        }{" "}
                                        {(
                                          data.Margin *
                                          exchangeRateData?.Rate
                                        )?.toFixed(2)}
                                      </div>
                                    </Tooltip>
                                  }
                                >
                                  <div className="f-500 f-grey">
                                    {renderPriceText(
                                      99,
                                      (
                                        data?.Margin *
                                        (exchangeRateData?.Rate ?? 1)
                                      )?.toFixed(2),
                                      userSelectedCurrency?.CurrencySymbol
                                    )}
                                  </div>
                                </OverlayTrigger>
                              </div>
                            );
                          }
                        )}
                        {
                          selectedServices?.some(item => item?.IsOnDemand === true) ?
                            <div className="px-2 py-3">
                              <div className="d-flex align-items-center">
                                <img
                                  alt=""
                                  width={13}
                                  height={13}
                                  src="/images/info-blue.svg"
                                  className="mr-2"
                                />
                                <div className="f-500 f-14 txt-blue">Price On-Demand</div>
                              </div>
                              <div className="f-400 f-12 f-darkgrey">This package includes services which are priced on demand</div>
                            </div> : <></>
                        }
                      </div>
                    </div>
                    <div
                      className="mt-2"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "83% 10%",
                      }}
                    >
                      <div>
                        {exchangeRateData?.FromCurrency &&
                          exchangeRateData?.FromCurrency !==
                          defaultCurrency?.CurrencyName && (
                            <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
                              <img
                                alt=""
                                width={13}
                                height={13}
                                src="/images/msp/exchange-rate-icon.svg"
                                className="mr-2"
                              />{" "}
                              The exchange rate on{" "}
                              {moment(
                                updatedExchangeRateData?.Date ?? ""
                              )?.format("DD MMM YYYY")}{" "}
                              is 1 {updatedExchangeRateData?.FromCurrency} ={" "}
                              {updatedExchangeRateData?.Rate}{" "}
                              {defaultCurrency?.CurrencyName}
                            </div>
                          )}
                        {exchangeRateData?.FromCurrency &&
                          exchangeRateData?.FromCurrency !==
                          defaultCurrency?.CurrencyName && (
                            <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
                              <img
                                alt=""
                                width={13}
                                height={13}
                                src="/images/msp/exchange-rate-icon.svg"
                                className="mr-2"
                              />{" "}
                              The exchange rate when this package was
                              created (
                              {moment(exchangeRateData?.Date ?? "")?.format(
                                "DD MMM YYYY"
                              )}
                              ) was 1 {exchangeRateData?.FromCurrency} ={" "}
                              {exchangeRateData?.Rate}{" "}
                              {defaultCurrency?.CurrencyName}
                            </div>
                          )}
                      </div>

                      {exchangeRateData?.FromCurrency &&
                        exchangeRateData?.FromCurrency !==
                        defaultCurrency?.CurrencyName && (
                          <div
                            className="f-blue pointer"
                            onClick={() => {
                              updatePackageExchangeRateData(
                                exchangeRateData?.Rate,
                                scenarioId
                              );
                            }}
                          >
                            Update
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </div>

              <hr />

              <div className="mt-4">
                {
                  showBy?.length > 0 ?
                    <div className="mb-1 mt-5">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="f-600 f-14 f-black">
                          Security Capabilities
                        </div>
                        {
                          packageData?.SecurityCapabiltiesByNist_List?.length > 0 && packageData?.SecurityCapabiltiesBySecurityPillar_List?.length > 0 ?
                            <Dropdown className="security-capabilities-dropdown">
                              <Dropdown.Toggle>
                                <div className="d-flex align-items-center py-1 px-2">
                                  <div className="f-500 f-12 greyText2">
                                    {selectedShowBy?.label}
                                  </div>
                                  <img
                                    width={8}
                                    alt=""
                                    className="ml-2"
                                    src="/images/chevron-down.svg"
                                  />
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div>
                                  {
                                    showBy?.map((showByItem, index) => {
                                      return (
                                        <div
                                          className={`f-400 f-12 px-2 py-1 ${showByItem?.id == selectedShowBy?.id ? 'txt-blue' : 'greyText2 bg-hover-grey pointer'}`}
                                          onClick={(e) => {
                                            if (showByItem?.id != selectedShowBy?.id) {
                                              setSelectedShowBy(showByItem);
                                              setSelectedShowByData(showByItem?.id == 0 ? packageData?.SecurityCapabiltiesByNist_List : packageData?.SecurityCapabiltiesBySecurityPillar_List)
                                              e.target
                                                .closest(".security-capabilities-dropdown")
                                                .click();
                                            }
                                          }}
                                        >
                                          {showByItem?.label}
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </Dropdown.Menu>
                            </Dropdown> : <div>
                              <div className="d-flex align-items-center bg-grey-imp radius-2 py-1 px-2">
                                <div className="f-500 f-12 greyText2">
                                  {selectedShowBy?.label}
                                </div>
                              </div>
                            </div>
                        }
                      </div>

                      {
                        selectedShowByData?.length > 0 ? <div className="mt-2 border-gray w-100">
                          <div className="w-100 security-capabilities-table">
                            {
                              selectedShowByData?.map((securityCapabilityItem, itemIndex) => {
                                return (
                                  <div className={`p-2 ${itemIndex % 2 === 0 ? 'border-right' : ''} ${itemIndex < (securityCapabilityItem?.length % 2 === 0 ? securityCapabilityItem?.length - 2 : securityCapabilityItem?.length - 1) ? 'border-bottom' : ''}`}>
                                    <div className="f-500 f-14 f-black mb-1">
                                      {securityCapabilityItem?.Name}
                                    </div>
                                    {
                                      securityCapabilityItem?.SecurityCapabiltiesData_List?.map((dataItem, dataIndex) => {
                                        return (
                                          <div className="d-flex align-items-center">
                                            <img
                                              width={14}
                                              alt=""
                                              className="mr-2"
                                              src={`${dataItem?.IsGreyColour ? '/images/icons/check-gray-circle.svg' : '/images/icons/check-blue-circle.svg'}`}
                                            />
                                            <div className="f-400 f-12 f-black">
                                              {dataItem?.Name}
                                            </div>
                                            {
                                              dataItem?.IsManagedBySOC &&
                                              <img
                                                width={14}
                                                alt=""
                                                className="ml-2"
                                                src="/images/icons/monitor-icon.svg"
                                              />
                                            }
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                )
                              })
                            }
                          </div>
                          <div className="d-flex align-items-center border-top">
                            <img
                              width={14}
                              alt=""
                              className="mx-2"
                              src="/images/icons/check-gray-circle.svg"
                            />
                            <div className="f-400 f-12 f-darkgrey">
                              - Pre-existing
                            </div>
                            <img
                              width={14}
                              alt=""
                              className="mx-2"
                              src="/images/icons/check-blue-circle.svg"
                            />
                            <div className="f-400 f-12 f-darkgrey">
                              - New!
                            </div>
                            <img
                              width={14}
                              alt=""
                              className="mx-2"
                              src="/images/icons/monitor-icon.svg"
                            />
                            <div className="f-400 f-12 f-darkgrey">
                              - Monitored by SOC
                            </div>
                          </div>
                        </div> : <div className="d-flex align-items-center justify-content-center w-100 my-4 f-400 f-darkgrey">
                          No data yet
                        </div>
                      }

                    </div> : <div>
                      <div className="f-600 f-14 f-black">
                        Security Capabilities
                      </div>
                      <div
                        className="f-400 f-12 f-darkgrey p-5 d-flex align-items-center justify-content-center w-100 hg-border radius-4 my-2"
                      >
                        Add services to see security capabilities
                      </div>
                    </div>
                }
              </div>


              <div className="mt-4">
                <div className="f-600 f-14 f-black my-2">
                  Included Features
                </div>

                {
                  pageData?.Nist_All?.length > 0 ?
                    <div className="border-parent  radius-8">
                      <div className="features_table border-item px-2 py-1">
                        <div className="f-500 f-12 f-darkgrey">Serivce</div>
                        <div className="f-500 f-12 f-darkgrey">Features</div>
                      </div>
                      {
                        pageData?.Nist_All?.map((item) => {
                          return (
                            <div className="features_table border-item py-1">
                              <div>
                                <div className="d-flex align-items-center">
                                  <img
                                    alt=""
                                    className="mx-2"
                                    src='/images/other-apps-icon.svg'
                                  />
                                  <div className="f-400 f-14 f-black mt-1">
                                    {item?.Headline}
                                  </div>
                                </div>
                                <div className="ml-3 f-12 f-darkgrey">
                                  {item?.SecurityPillar_Selected?.DDLText && `${item?.SecurityPillar_Selected?.DDLText} • `}
                                  {
                                    item?.MonthlyPrice_Text
                                  }{" "}
                                  {
                                    item
                                      ?.SnapToUnit_Selected
                                      ?.DDLColour
                                  }{" "}
                                  /
                                  {
                                    item
                                      ?.BillingPeriod_Selected
                                      ?.DDLColour
                                  }{" "}
                                  <span className="f-12 txt-blue">
                                    {item?.IsOnDemand ? ' • POD' : ''}
                                  </span>
                                </div>
                              </div>
                              <div>
                                {item?.SecurityPack_Bullet_List?.length > 0 ? (
                                  <>
                                    {item?.SecurityPack_Bullet_List?.map((item) => {
                                      return (
                                        <div className="d-flex align-items-center my-1 f-12 f-400 f-black">
                                          <img
                                            alt=""
                                            className="ml-1 mr-2"
                                            src="/images/msp/green-circular-tick.svg"
                                          />
                                          {item?.Text}
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <div className="d-flex align-items-center my-2 f-grey f-12">
                                    This service has no features
                                  </div>
                                )}

                              </div>
                            </div>
                          )
                        })
                      }
                    </div> : <div
                      className="f-400 f-12 f-darkgrey p-5 d-flex align-items-center justify-content-center w-100 hg-border radius-4 my-2"
                    >
                      Add services to see included features
                    </div>
                }
              </div>

              <div className="mt-4">
                <div className="f-600 f-14 f-black my-2">
                  Alignment to NIST CSF 2.0 Framework
                </div>

                {
                  featuresData?.length > 0 ?
                    <div className="border-block w-100">
                      {
                        featuresData?.map((featureItem, featureIndex) => {
                          return (
                            <div>
                              {
                                featureItem?.Question_List?.filter(question =>
                                  question?.AnswerId !== 0 && question?.AnswerBool === true
                                )?.length > 0 && <div className="w-100">
                                  <div className={`f-500 f-14 f-black bg-grey px-2 py-1 ${featureIndex < featuresData?.length - 1 ? 'border-item' : ''
                                    } ${featureIndex > 0 ? 'border-top-item' : ''}`}>
                                    {featureItem?.Title}
                                  </div>
                                  <div className="">
                                    {
                                      featureItem?.Question_List?.map((question, questionIndex) => {
                                        return (
                                          <div>
                                            {question?.AnswerId !== 0 &&
                                              question?.AnswerBool === true && (
                                                <div className={`d-flex ${questionIndex < featureItem?.Question_List?.filter(question =>
                                                  question?.AnswerId !== 0 && question?.AnswerBool === true
                                                )?.length - 1 ? 'border-item' : ''
                                                  }`}>
                                                  <img
                                                    alt=""
                                                    height={14}
                                                    className="ml-2 my-3"
                                                    src="/images/msp/green-circular-tick.svg"
                                                  />
                                                  <>
                                                    {question?.InheritedText && (
                                                      <div className="table-title radius-4 f-500">
                                                        <div className="img">
                                                          <img
                                                            alt=""
                                                            className=" "
                                                            src="/images/msp/arrow-enter.svg"
                                                          />
                                                        </div>

                                                        <div className="title f-darkgrey">
                                                          Inherited from `
                                                          {question?.InheritedText}`{" "}
                                                        </div>
                                                      </div>
                                                    )}
                                                    <div
                                                      className=""
                                                      style={{ padding: "12px" }}
                                                    >
                                                      <div className="d-flex align-items-start justify-content-between">
                                                        <div>
                                                          {question.Explanation}

                                                          <div className="d-flex flex-wrap mt-2">
                                                            {
                                                              question?.nistType &&
                                                              <div
                                                                className='px-2 rounded-full d-flex align-items-center border1 f-grey bg-greyish f-12 f-500 mr-2'
                                                              >
                                                                {question?.nistType}
                                                              </div>
                                                            }
                                                            {question?.NISTAssetClassification_List?.map(
                                                              (chip, chipIndex) => {
                                                                return (
                                                                  <div className="position-relative tooltip-parent">
                                                                    <QuestionChip
                                                                      iconUrl={
                                                                        chip.IsFramework
                                                                          ? "/images/msp/msp-framework-icon.svg"
                                                                          : null
                                                                      }
                                                                      title={chip?.Name}
                                                                      className={"mr-2"}
                                                                      IsBlue={
                                                                        chip?.Name?.toLowerCase() ===
                                                                        "best practice"
                                                                      }
                                                                    />
                                                                    {!chip.IsFramework && (
                                                                      <PackageToolTip
                                                                        content={chip?.Detail}
                                                                      />
                                                                    )}
                                                                  </div>
                                                                );
                                                              }
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                </div>
                                              )}
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              }
                            </div>
                          )
                        })
                      }
                    </div> : <div
                      className="f-400 f-12 f-darkgrey p-5 d-flex align-items-center justify-content-center w-100 hg-border radius-4 my-2"
                    >
                      Add services to see  NIST components
                    </div>
                }

              </div>

            </div>

            {/* Right SIDE */}
            <div
              className="mx-auto w-100 radius-8 bg-grey h-fit-content my-3 py-2"
              style={{
                minWidth: "500px",
                maxWidth: "500px",
              }}
            >
              <div className="py-2 px-3">
                <div className="f-500 f-14 f-grey my-1">
                  Security Posture
                </div>
                <div
                  className=" m-auto w-fit-content mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // width: "100%",
                      width: "458px",
                    }}
                  >
                    <MspRadar data={data} options={_options} />
                  </div>
                </div>
              </div>

              <div className="border-top p-3 d-flex justify-content-between">
                <div>
                  <div className="f-500 f-14 f-grey">
                    Predicted CyberResilience
                  </div>
                  <div className="f-500 f-18 f-black py-1">
                    {pageData?.response?.ScenarioHeader?.CyberCompliance}%
                  </div>
                </div>
                <CyberComplianceGauge
                  value={pageData?.response?.ScenarioHeader?.CyberCompliance}
                  showTitle={false}
                />
              </div>

              <div className="border-top p-3 d-flex justify-content-between">
                <div>
                  <div className="f-500 f-14 f-grey">
                    Predicted CyberScore
                  </div>
                  <div className="f-500 f-18 f-black py-1">
                    {pageData?.response?.ScenarioHeader?.CyberScore}%
                  </div>
                </div>
                <CyberScoreGauge
                  value={pageData?.response?.ScenarioHeader?.CyberScore}
                  showTitle={false}
                />
              </div>
            </div>
          </>
        )}
      </div >
      <MapCurrentSpendModal
        show={showMapCurrentSpendModal}
        hideModal={() => {
          setShowMapCurrentSpendModal(false);
          const drag = document?.getElementById("map-current-spend-modal");
          drag.style.display = "none";
          setTableLoader(true);
          getPackageDetails(Number(scenarioId), clientId, false);
          // getFeaturesData(Number(scenarioId))
          setMapCurrentSpendPackageId(0);
        }}
        clientId={clientId}
        packageId={mapCurrentSpendPackageId}
        packagePrice={mapCurrentSpendPackagePrice}
        packagePriceText={mapCurrentSpendPackagePriceText}
      />
      <MspScoreModal
        isForPackageDetail={true}
        packageId={Number(scenarioId)}
        show={showMspScoreModal}
        updateRadarChart={updateRadarChart}
        hideModal={() => {
          setShowMspScoreModal(false);
        }}
        radarData={data?.datasets[0]?.data}
        serviceId={selectedServiceId}
        selectedNistPiller={Object.values(services_titles)[0]}
        HideMspSecopsLink={true}
        hideServiceModal={() => { }}
        showExploreMappingModal={showExploreMappingModal}
        setShowExploreMappingModal={setShowExploreMappingModal}
        existServices={[
          selectedServices?.filter(item => item?.NistPillar === 0)?.length,
          selectedServices?.filter(item => item?.NistPillar === 1)?.length,
          selectedServices?.filter(item => item?.NistPillar === 2)?.length,
          selectedServices?.filter(item => item?.NistPillar === 3)?.length,
          selectedServices?.filter(item => item?.NistPillar === 4)?.length
          // selectedServices[0]?.length,
          // selectedServices[1]?.length,
          // selectedServices[2]?.length,
          // selectedServices[3]?.length,
          // selectedServices[4]?.length,
        ]}
      />
      <MspFeaturesModal
        packageId={Number(scenarioId)}
        show={showMspFeaturesModal}
        hideModal={() => {
          setShowMspFeaturesModal(false);
        }}
        identify={selectedServices?.filter(item => item?.NistPillar === 0)}
        protect={selectedServices?.filter(item => item?.NistPillar === 1)}
        detect={selectedServices?.filter(item => item?.NistPillar === 2)}
        recover={selectedServices?.filter(item => item?.NistPillar === 3)}
        respond={selectedServices?.filter(item => item?.NistPillar === 4)}
      // identify={selectedServices[0]}
      // protect={selectedServices[1]}
      // detect={selectedServices[2]}
      // recover={selectedServices[3]}
      // respond={selectedServices[4]}
      />
      <ExploreMappingModal
        show={showExploreMappingModal}
        hideModal={() => {
          setShowExploreMappingModal(false);
        }}
      />
      <MspAddEditServiceModal
        setNestedProductModalState={setNestedProductModalState}
        nestedProductModalState={nestedProductModalState}
        seriesModalData={seriesModalData}
        setSeriesModalData={setSeriesModalData}
        show={showServiceModal}
        _selectedServiceId={selectedServiceId}
        hideModal={() => {
          if (showServiceModal) {
            setShowServiceModal(false);
            setIsAddOnService(false);
            // setLoading(true);
            fillDataInDropdown();
          }
        }}
        nistPillar={nistPillarType}      //!!!!!!!
        _setNistIndex={setNistIndex}
        refreshData={() => {
          setShowServiceModal(false);
          // setLoading(true);
          fillDataInDropdown();
        }}
        parentID="msp-main-screen-for-integration-modal"
        isAddonService={isAddonService}
        serviceList={serviceList}
      />

      <MspAddEditSeriesModal
        show={seriesModalData?.show}
        seriesModalData={seriesModalData}
        setSeriesModalData={setSeriesModalData}
        _selectedServiceId={selectedServiceId}
        hideModal={() => {
          setSeriesModalData((prev) => ({
            ...prev,
            show: false,
          }));
        }}
        refreshData={() => { }}
        parentID="msp-main-screen-for-integration-modal"
      />
      <MspAddEditProductModal
        modalID="dshkvsdq"
        className="nested-product-modal-in-service"
        // deleteProduct={deleteData}
        show={nestedProductModalState?.show}
        selectedProductId={nestedProductModalState?.selectedProductId}
        hideModal={() => {
          setNestedProductModalState((prev) => ({
            ...prev,
            show: false,
          }));
        }}
        mapNestedModalData={(data) => { }}
        refreshData={() => {
          setNestedProductModalState((prev) => ({
            ...prev,
            update: true,
          }));
        }}
        parentID="msp-main-screen-for-integration-modal"
      />

      <CustomDeleteModal
        show={warningModalConfig?.showWarningModal}
        deleteHeaderIcon={"/images/msp/yellow-exclammation.svg"}
        hideModal={() => {
          setWarningModalConfig((prev) => ({
            ...prev,
            showWarningModal: false,
          }));
          setIsSaveClicked(false);
        }}
        deleteButtonClass={"hg-blue-btn"}
        deleteButtonText={isSaveClicked ? "Save" : "Exit"}
        deleteHeaderText={"Warning"}
        deleteBodyText={
          isSaveClicked
            ? "This security package includes no services."
            : "You have unsaved changes."
        }
        deleteFooterText={
          isSaveClicked
            ? "Are you sure you want to save?"
            : "Are you sure you want to exit?"
        }
        deleteFunction={() => {
          if (!isSaveClicked) {
            if (state?.backUrl) {
              history.push(state.backUrl, {
                packageFilterSave: state?.packageFilter,
                serviceFilterSave: state?.serviceFilter,
                productFilterSave: state?.productFilter,
              });
            } else {
              history.push("/mspv2/sell/packages", {
                packageFilterSave: state?.packageFilter,
                serviceFilterSave: state?.serviceFilter,
                productFilterSave: state?.productFilter,
              });
            }
            if (Number(packageId) === 0) {
              deleteData(pageData?.response?.ScenarioHeader?.objId_Scenario);
            }
          } else {
            setSaving(true);
            updatePackage();
          }
        }}
        deleteParams={{}}
        deleteType={"warningClose"}
      />
    </div >
  );
};

export default MspPriceCalcultor;

export const AddButtonForPackage = (props) => {
  return (
    <div
      className={`d-flex align-items-center p-2 bg-grey w-fit-content radius-2 f-500 f-grey pointer ${props.className}`}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e);
        }
      }}
    >
      <img src="/images/actioncenter/plusgrey.svg" alt="" className="mr-2" />
      Add
    </div>
  );
};

const MspPriceCalcultorLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      {/* Left side */}
      <rect x="0%" y="70" rx="8" ry="8" width="50%" height="60" />
      <rect x="0%" y="212" rx="8" ry="8" width="50%" height="90" />
      <rect x="0%" y="304" rx="8" ry="8" width="50%" height="90" />
      <rect x="0%" y="396" rx="8" ry="8" width="50%" height="90" />

      <rect x="0%" y="545" rx="8" ry="8" width="50%" height="60" />
      <rect x="0%" y="645" rx="8" ry="8" width="50%" height="90" />
      <rect x="0%" y="737" rx="8" ry="8" width="50%" height="90" />
      <rect x="0%" y="829" rx="8" ry="8" width="50%" height="90" />

      {/* Right Side */}
      <rect x="55%" y="10%" rx="16" ry="16" width="40%" height="500" />
      <rect x="55%" y="660" rx="8" ry="8" width="40%" height="45" />

      <rect x="55%" y="707" rx="8" ry="8" width="40%" height="45" />
      <rect x="55%" y="754" rx="8" ry="8" width="40%" height="45" />
      <rect x="55%" y="801" rx="8" ry="8" width="40%" height="45" />
    </ContentLoader>
  );
};

export const services_enums = {
  0: "Identify",
  1: "Protection",
  2: "Detection",
  3: "Incident Response",
  4: "Recover",
};
export const services_enums_selected = {
  0: {
    ObjId: 0,
    DDLId: 0,
    DDLText: "Identify",
    DDLSort: 0,
    DDLValue: 0,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  1: {
    ObjId: 0,
    DDLId: 1,
    DDLText: "Protect",
    DDLSort: 1,
    DDLValue: 1,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  2: {
    ObjId: 0,
    DDLId: 2,
    DDLText: "Detect",
    DDLSort: 2,
    DDLValue: 2,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  3: {
    ObjId: 0,
    DDLId: 3,
    DDLText: "Respond",
    DDLSort: 3,
    DDLValue: 3,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  4: {
    ObjId: 0,
    DDLId: 4,
    DDLText: "Recover",
    DDLSort: 4,
    DDLValue: 4,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
};

export const services_titles = {
  Identify: "Identify",
  Protection: "Protect",
  Detection: "Detect",
  Incident_Response: "Respond",
  Recover: "Recover",
};

export const services_count = {
  Identify: "DetectCount",
  Protect: "IdentityCount",
  Detect: "ProtectCount",
  Respond: "RecoverCount",
  Recover: "ResponseCount",
};

export const services_titles_selected = {
  Identify: {
    ObjId: 0,
    DDLId: 0,
    DDLText: "Identify",
    DDLSort: 0,
    DDLValue: 0,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  Protection: {
    ObjId: 0,
    DDLId: 1,
    DDLText: "Protect",
    DDLSort: 1,
    DDLValue: 1,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  Detection: {
    ObjId: 0,
    DDLId: 2,
    DDLText: "Detect",
    DDLSort: 2,
    DDLValue: 2,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  Incident_Response: {
    ObjId: 0,
    DDLId: 3,
    DDLText: "Respond",
    DDLSort: 3,
    DDLValue: 3,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  Recover: {
    ObjId: 0,
    DDLId: 4,
    DDLText: "Recover",
    DDLSort: 4,
    DDLValue: 4,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
};

export const servicesIndex = {
  Identify: 0,
  Protect: 1,
  Detect: 2,
  Respond: 3,
  Recover: 4,
};

// OnToggleItem(parentIndex:int    ,   childIndex :int)
export const ServiceDropdown = ({
  data,
  selectedData,
  serviceEnum,
  onToggleItem,
  onClickAdd,
  onClose,
  _updateSelectedServices,
  _setIsAddonService,
  _setnistPillarType,
  _inputRef,
  _isToggleShows,
  isLoading,
  serviceSearchString,
  setServiceSearchString = () => { }
}) => {
  useEffect(() => {
    setIsToggleShows(_isToggleShows);
  }, [_isToggleShows]);
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const [isToggleShows, setIsToggleShows] = useState(false);

  const handleToggle = () => {
    // setDropdownOpen(!dropdownOpen);
    // setIsToggleShows(!isToggleShows)
  };


  return (
    <div
      className="w-100 d-flex align-items-center border-item p-3 "
      ref={_inputRef}
    >
      <Dropdown
        show={dropdownOpen}
        onToggle={handleToggle}
        className="msp-multiselect-package-selection-dropdown w-100"
      >
        <Dropdown.Toggle className="w-100">
          <div className="highlight-input-with-grey-border p-2 radius-4 d-flex align-items-center">
            <img
              src="/images/attack-surface/search-icon.svg"
              alt=""
              className="mr-2"
            />
            <input
              autoFocus={true}
              className="border-none bg-transparent flex-grow-1"
              type="text"
              value={serviceSearchString}
              onChange={(e) => {
                setServiceSearchString(e?.target?.value)
              }}
            />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100">
          {data?.length !== 0 ? (
            <>
            </>
          ) : (
            <>
              <div className="f-400 f-darkgrey ml-3 my-2">
                No Services or Add-ons yet
              </div>
            </>
          )}

          <div className=" ml-3" /*key={services_enums[serviceEnum]}*/>
            <div className="f-12 f-darkgrey my-2">
              Services
            </div>

            <div
              className="f-blue f-500 mt-1 d-flex align-items-center pointer mb-1"
              onClick={(e) => {
                _setIsAddonService(false);
                onClickAdd();
                // _setnistPillarType(
                //   Object.values(services_titles_selected)[serviceEnum]   ///!!!
                // );
              }}
            >
              <img
                alt=""
                src="/images/msp/blue-add-icon.svg"
                className="mr-2"
              />
              <div className="pt-1">
                Add service
              </div>
            </div>

            {!data && isLoading ? (
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="msp-service-update-loader ml-2 mr-1 my-2"
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                ></div>
              </div>
            ) : (
              <></>
            )}

            {data?.filter(item => item?.Headline?.toLowerCase()?.includes(serviceSearchString?.toLowerCase()))?.map((item, itemIndex) => {
              if (!item?.IsAddOn) {
                return (
                  <div
                    key={itemIndex}
                    className={`
                          d-flex align-items-center justify-content-between mb-3 
                          ${item?.IsChildServiceDisabled
                        ? " curs-default "
                        : " pointer "
                      }
                          `}
                    onClick={() => {
                      if (!item?.IsChildServiceDisabled) {
                        _updateSelectedServices(item);
                        onToggleItem(itemIndex);
                        setDropdownOpen(false);
                      }
                    }}
                  >
                    <div
                      className={`${item.Selected
                        ? " f-blue "
                        : item?.IsChildServiceDisabled
                          ? " f-darkgrey "
                          : " greyText2 "
                        } d-flex align-items-center f-400
                      `}
                    >
                      <img
                        alt=""
                        className={`
                          ${item?.Selected
                            ? " blue-icon "
                            : item?.IsChildServiceDisabled
                              ? " lightgray-icon "
                              : " darkgray-icon "
                          } mr-2
                        `}
                        style={{
                          height: "16px",
                        }}
                        src="/images/msp/dollar-icon.svg"
                      />
                      {item.Headline}
                    </div>
                    <div>
                      {item?.loading ? (
                        <div className="msp-service-update-loader mr-3"></div>
                      ) : (
                        item.Selected && (
                          <img
                            alt=""
                            className=" mr-3"
                            src="/images/msp/blue-tick.svg"
                          />
                        )
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </div>

          <div className=" ml-3" /*key={services_enums[serviceEnum]}*/>
            <div className="f-12 f-darkgrey my-2">
              {services_enums[serviceEnum]} add-ons
            </div>

            <div
              className="f-blue f-500 mt-1 d-flex align-items-center pointer mb-1"
              onClick={(e) => {
                // setShowServiceModal(true);
                _setIsAddonService(true);
                onClickAdd();
                // _setnistPillarType(
                //   Object.values(services_titles_selected)[serviceEnum]   ///!!!!!
                // );
              }}
            >
              <img
                alt=""
                src="/images/msp/blue-add-icon.svg"
                className="mr-2"
              />
              <div className="pt-1">
                Add add-ons
              </div>
            </div>

            {!data && isLoading ? (
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="msp-service-update-loader ml-2 mr-1 my-2"
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                ></div>
              </div>
            ) : (
              <></>
            )}

            {data?.filter(item => item?.Headline?.toLowerCase()?.includes(serviceSearchString?.toLowerCase()))?.map((item, itemIndex) => {
              if (item?.IsAddOn) {
                return (
                  <div
                    key={itemIndex}
                    className={`
                          d-flex align-items-center justify-content-between mb-3 
                            ${item?.IsChildServiceDisabled
                        ? " curs-default "
                        : " pointer "
                      }
                          `}
                    onClick={() => {
                      if (!item?.IsChildServiceDisabled) {
                        _updateSelectedServices(item/*, serviceEnum*/);
                        onToggleItem(itemIndex);
                        setDropdownOpen(false);
                      }
                    }}
                  >
                    <div
                      className={`${item.Selected
                        ? " f-blue "
                        : item?.IsChildServiceDisabled
                          ? " f-darkgrey "
                          : " greyText2 "
                        } d-flex align-items-center f-400
                      `}
                    >
                      <img
                        alt=""
                        className={`
                          ${item?.Selected
                            ? " blue-icon "
                            : item?.IsChildServiceDisabled
                              ? " lightgray-icon "
                              : " darkgray-icon "
                          } mr-2
                        `}
                        style={{
                          height: "16px",
                        }}
                        src="/images/msp/dollar-icon.svg"
                      />
                      {item.Headline}
                    </div>
                    <div>
                      {item?.loading ? (
                        <div className="msp-service-update-loader mr-3"></div>
                      ) : (
                        item.Selected && (
                          <img
                            alt=""
                            className=" mr-3"
                            src="/images/msp/blue-tick.svg"
                          />
                        )
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </Dropdown.Menu>
      </Dropdown>

      <img
        alt=""
        className="mr-1 ml-3 pointer"
        width={12}
        height={12}
        src="/images/attack-surface/cross-icon.svg"
        onClick={() => {
          if (onClose) onClose();
        }}
      />
    </div>
  );
};


export function addZerows(number) {
  if (!isNaN(number)) {
    if (number?.includes(".")) {
      const decimalPartLength =
        number?.split(".")[1]?.length;
      if (decimalPartLength === 1) {
        return (number + "0")
      } else if (decimalPartLength > 2) {
        return (Number(number)?.toFixed(2)?.toString())
      }
    } else {
      return (number + ".00")
    }
  }
  return number;
}