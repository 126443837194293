import { useEffect, useState } from 'react';
import './note-section.styles.scss';
import ImageLoader from "../../../Common/ImageLoader";
import ImageWithInitials from "../../../../Utils/ImageWithInitials";
import ResizerIcon from '../../../icons/resizer.icon';
import useResize from '../../../../hooks/resize.hook';
import useNoteSection from './note-section.data';
import { Spinner } from 'react-bootstrap';
import dateUtils from '../../../../Utils/date.utils';

const NoteSection = ({ tool, onResize, clientId, technologyEnum, refetchTools }) => {
  const [isDataSet, setIsDataSet] = useState(false);
  const [inputTimeout, setInputTimeout] = useState(null);
  const [noteValue, setNoteValue] = useState('');
  const [sentValue, setSentValue] = useState('');
  const textareaRef = useResize({ 
    onResize
  });
  const { isLoading, submitNote } = useNoteSection();
  const userDetails = tool?.ScoreAttestationRow?.UserDetailsVM;

  const submiting = (value) => {
    if (inputTimeout) clearTimeout(inputTimeout);
    if (sentValue === value) return;
    const timeout = setTimeout(() => {
      submitNote({ noteValue: value, technologyEnum, clientId })
        .finally(() => {
          refetchTools();
        });
      setSentValue(value);
      setInputTimeout(null);
    }, 800);
    setInputTimeout(timeout);
  }

  useEffect(() => {
    if (!tool?.ScoreAttestationRow || !tool?.ScoreAttestationRow?.NoteForNoAnswer || isDataSet) return;
    setNoteValue(tool.ScoreAttestationRow.NoteForNoAnswer);
    setSentValue(tool.ScoreAttestationRow.NoteForNoAnswer);
    setIsDataSet(true);
  }, [tool?.ScoreAttestationRow]);

  return (
    <div className="note-section">
      <div className='d-flex align-items-center mb-2'>
        <h3 className='f-12 f-400-imp f-grey m-0'>Note</h3>
        {isLoading && (
          <Spinner
            animation="border"
            variant="secondary"
            size="sm"
            className="ml-2"
            style={{
              width: "10px",
              height: "10px",
            }}
          />
        )}
      </div>
      <div className='position-relative'>
        <textarea
          ref={textareaRef} 
          value={noteValue}
          placeholder="Add note"
          onBlur={() => {
            if (inputTimeout) clearTimeout(inputTimeout);
            if (sentValue === noteValue) return;
            submitNote({ noteValue: noteValue, technologyEnum, clientId })
              .finally(() => {
                refetchTools();
              });
            setSentValue(noteValue);
          }} 
          onChange={e => {
            setNoteValue(e.target.value);
            onResize(e.target);
            submiting(e.target.value);
          }} 
        />
        <ResizerIcon className="resizer" />
      </div>
      {tool?.ScoreAttestationRow?.LastUpdatedBy && userDetails && (
        <div className='last-updated d-flex align-items-center'>
          <span>Last updated by </span>
          <div className='user-logo'>
            <ImageLoader 
              src={userDetails.Avatar}
              alt=""
              className="ml-2 mr-1 rounded-full"
              width={16}
              height={16}
              noImageComponent={(
                <div className="ml-2 mr-1 rounded-full">
                  <ImageWithInitials
                    initials={userDetails.Initials ?? userDetails?.FirstName?.charAt(0)}
                    width={16}
                    height={16}
                    background={"purple"}
                  />
                </div>
              )}
            />
          </div>
          <span className='mr-2 f-500'>{userDetails.FullName}</span>
          <span>at</span>
          <span className='ml-2'>
            {dateUtils.toDayMonthYearTimeFormat(tool?.ScoreAttestationRow?.LastUpdated)}
          </span>
        </div>
      )}
    </div>
  );
}

export default NoteSection;
