import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import "./overview.scss";
import "../common.scss";
import { useAuth } from "../../context/Auth";
import { getPageDataApi } from "../../../Utils/commonApi";
import Loader from "../../Common/loader";
import DeviceIssuesTable from "../device-issues-table/device-issues.table";
import DeviceIssuesFilter from "./device-issues-filter/device-issues-filter.component";
import UserIssuesTable from "../device-issues-table/user-issues.table";
import useQueryParams from "../../../hooks/query-params.hook";
import useAttackSurfaceData from "../../MSPv2/exportClientReport/export-client-left-section/attack-surface.section/attack-surface.data";
import UserIssuesFilter from "./user-issues-filter/user-issues-filter.component";
import DatePickerDropdown from "../../Dashboard/msp-client-dashboard-v2/technology-spend/date-picker/date-picker.dropdown";
import { SearchCriteriaEnumASEIssues } from "../../../constants/ase.constants";
import useUserDetails from "../../../hooks/user-details.hook";

const Overview = ({ progressData}) => {
  const { userDetails } = useUserDetails();
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [overviewPageData, setOverviewPageData] = useState({})
  const [pageLoading, setPageLoading] = useState(false)
  const [tableType, setTableType] = useState("MFA Table")
  const { 
    data: aseData, 
    isLoading: isASELoading,
    fetchAttackSurfaceData,
    setData: setASEData,
    filterList: aseFilters,
    setFilterList: setASEFilters,
    userSortColumn,
    userSortOrder,
    onSetUserSortColumn,
    deviceSortColumn,
    deviceSortOrder,
    onSetDeviceSortColumn
  } = useAttackSurfaceData({});
  const [searchString, setSearchString] = useState("");

  const clientId = userDetails?.objId_Client;
  const selectedSearchCriteria = aseFilters?.SearchCriterias?.[0]?.criteriaEnum;
  const isStringInput = selectedSearchCriteria === SearchCriteriaEnumASEIssues.Email
    || selectedSearchCriteria === SearchCriteriaEnumASEIssues.Name
    || selectedSearchCriteria === SearchCriteriaEnumASEIssues.Job_Title
    || selectedSearchCriteria === SearchCriteriaEnumASEIssues.Operating_System;
  const isNumberInput = selectedSearchCriteria === SearchCriteriaEnumASEIssues.Installed_Date;
  const isDateRangeInput = selectedSearchCriteria === SearchCriteriaEnumASEIssues.Warrantly_Expiry
    || selectedSearchCriteria === SearchCriteriaEnumASEIssues.End_Of_Life_Date;

  useEffect(() => {
    setPageLoading(true)
    getPageData()
  }, [])

  useEffect(() => {
    if (isDateRangeInput && aseFilters?.SearchCriterias?.[0]?.SearchParams[0] === '') return;
    fetchAttackSurfaceData(clientId);
  }, [aseFilters]);

  useEffect(() => {
    setSearchString("");
  }, [selectedSearchCriteria]);

  const { getParam } = useQueryParams();
  const section = getParam('section');

  useEffect(() => {
    if (!section) return;
    if (section === 'MFA') setTableType('MFA Table');
    if (section === 'EOL') setTableType('EOL Table');
  }, [section]);
  

 const { authTokens } = useAuth();

 function updateMfaStatus(kwargs ={}) {
  return new Promise((resolve, reject) => {
    getPageDataApi("UpdateAzureUserMFAStatus", authTokens, {
      // objId_Customer: 7283912,
      
      MFAState: kwargs?.MFAState ?? 0,
      UserId: kwargs?.UserId ?? "",
      UserRecordId: kwargs?.UserRecordId ?? "",
    }).then((response)=>{
      fetchAttackSurfaceData(clientId);
      getPageData();
      resolve(response)
    }).catch((err)=>{
      reject(err)
    })})
 }


 function getPageData(){
  return new Promise((resolve, reject) => {
    getPageDataApi("GetASEOverview", authTokens, {
      // objId_SystemOwner: 4653142,
objId_Customer: clientId
    }).then((response)=>{
      setOverviewPageData(response?.detail)
      setPageLoading(false)
      resolve(response)
    }).catch((err)=>{
      reject(err)
      setPageLoading(false)

    })})
 }

  const updateSearchCriteriaString = (value) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(setTimeout(() => {
      setASEFilters({
        ...aseFilters,
        SearchCriterias: [
          {
            criteriaEnum: selectedSearchCriteria,
            SearchParams: [value]
          }
        ]
      });
      setSearchTimeout(null);
    }, 500));
  }

  const updateSearchCriteriaDateRange = (value) => {
    setASEFilters({
      ...aseFilters,
      SearchCriterias: [
        {
          criteriaEnum: selectedSearchCriteria,
          SearchParams: value 
            ? [value?.startDate?.toISOString(), value?.endDate?.toISOString()] 
            : []
        }
      ]
    });
  }

  function ToggleAccordion(e) {
    var parent = e.target.closest(".insight-drop");

    var panel = parent.querySelector(".panel");

    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  const InsightsData = [
    {
      name: "3 users without MFA enabled",
      subtitle: "You have 3 users in Office 365 who do not have MFA enabled",
      list: [
        {
          name: "Brooklyn Simmons",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Esther Howard",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Bessie Cooper",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
      ],
    },
    {
      name: "Firewall's license has expired on 2 devices",
      subtitle:
        "You're Firewall's license has expired and is no longer performing Intrusion Detection",
      list: [
        {
          name: "HG-AUTOID_SUPER-Firewall-2",
          subtitle: "License expired on 10/07/22",
          image: null,
        },
        {
          name: "HG-AUTOID_SUPER-Firewall-1",
          subtitle: "License expired on 10/07/22",
          image: null,
        },
      ],
    },
    {
      name: "6 devices do not have Endpoint Protection",
      subtitle: "",
      list: [
        {
          name: "Brooklyn Simmons",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Esther Howard",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Bessie Cooper",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
      ],
    },
    
  ];

    // Search Input Animation
    const SearchInput = useRef();
    const [searchInput, setSearchInput] = useState(false);

  return (<>
  {
    pageLoading

  ?

  <div
  className="d-flex align-items-center justify-content-center w-100 "
  style={{
    height: "85vh",
  }}
>
  <Loader />
</div>



  :
  
  
  
  
  
  <div className="attack-overview-wrapper w-100" style={{ padding: '24px' }}>
      <div className="overview-banner bg-grey radius-8 w-100 d-flex">
        <div
          className="w-50 p-4"
          style={{
            borderRight: "1px solid #EBEDF1",
          }}
        >
          <div className="f-500 f-grey mb-3">Attack surface analysis</div>

          <div className="all-progresses d-flex flex-column">
            {progressData?.slice(1).map((progress, index) => {
              return (
                <div className=" attack-progress mb-2">
                  <div className="f-grey">{progress?.Title} </div>
                  <div className="d-flex align-items-center">
                    <progress
                      min="0"
                      value={progress?.CompletionScore}
                      max="100"
                    />
                  </div>
                  <div className="f-darkgrey d-flex align-items-center justify-content-center">
                    {progress?.CompletionScore}%
                  </div>
                </div>
              );
            })}
          </div>
          <div className="" style={{}}></div>
        </div>
        <div className="w-50  d-flex flex-column">
        

          <div className="asset-data d-flex flex-column w-100">
            <div className="w-100 d-flex">
              <div className="w-100 p-3">
                <div className="f-grey f-500 mb-2">Users</div>
                <div className="f-18 f-black f-500">
                  {overviewPageData?.Users}{" "}
                </div>
              </div>
              <div
                className="w-100 p-3"
                style={{ borderLeft: "1px solid #EBEDF1" }}
              >
                <div className="f-grey f-500 mb-2 ">Locations</div>
                <div className="f-18 f-black f-500">
                  {overviewPageData?.Locations}
                </div>
              </div>
            </div>

            <div className="w-100 d-flex">
              <div
                className="w-100 p-3"
                style={{
                  borderTop: "1px solid #EBEDF1",
                  borderRight: "1px solid #EBEDF1",
                }}
              >
                <div className="f-grey f-500 mb-2">Assets</div>
                <div className="f-18 f-black f-500">
                  {overviewPageData?.Assets}
                </div>
              </div>
              <div
                className="w-100 p-3"
                style={{ borderTop: "1px solid #EBEDF1" }}
              >
                <div className="f-grey f-500 mb-2">Cloud Services</div>
                <div className="f-18 f-black f-500">
                  {overviewPageData?.cloudServices}
                </div>
              </div>
            </div>
            <div className="w-100 d-flex">
              <div
                className="w-100 p-3"
                style={{
                  borderTop: "1px solid #EBEDF1",
                  borderRight: "1px solid #EBEDF1",
                  borderBottom: "1px solid #EBEDF1",
                }}
              >
                <div className="f-grey f-500 mb-2">Disabled MFA</div>
                <div className="f-18 f-black f-500">
                  {overviewPageData?.DisabledMFA}
                </div>
              </div>
              <div
                className="w-100 p-3"
                style={{
                  borderTop: "1px solid #EBEDF1",

                  borderBottom: "1px solid #EBEDF1",
                }}
              >
                <div className="f-grey f-500 mb-2">Life End Devices</div>
                <div className="f-18 f-black f-500">
                  {overviewPageData?.EndOfLifeDevices}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-5">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <div
              className={`mr-3 f-500 pointer ${
                tableType === "MFA Table" ? "f-blue" : "f-grey"
              }`}
              onClick={() => {
                setTableType("MFA Table");
              }}
            >
              User Issues ({overviewPageData?.MFAStatusList?.length})
            </div>
            <div
              className={`pointer f-500  ${
                tableType === "EOL Table" ? "f-blue" : "f-grey"
              }`}
              onClick={() => {
                setTableType("EOL Table");
              }}
            >
              Device Issues (
              {overviewPageData?.EndOfLifeDevicesList?.length})
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ gap: '8px' }}>
          {tableType === "MFA Table" && (
              <UserIssuesFilter 
                aseFilters={aseFilters} 
                setASEFilters={setASEFilters}
              />
            )}
            {tableType === "EOL Table" && (
              <DeviceIssuesFilter 
                aseFilters={aseFilters} 
                setASEFilters={setASEFilters}
              />
            )}
            {isStringInput || isNumberInput ? (
              <div className="mx-2 d-flex align-items-center">
                <img
                  alt=""
                  src="/images/attack-surface/search-icon.svg"
                  className="pointer"
                  onClick={() => {
                    setSearchInput(true);
                    SearchInput.current.focus();
                  }}
                />
                <input
                  type={isStringInput ? "text" : "number"}
                  placeholder="Search.."
                  ref={SearchInput}
                  value={searchString}
                  onChange={(e) => {
                    // handleInputChange(e);
                    setSearchString(e.target.value);
                    updateSearchCriteriaString(e.target.value);
                  }}
                  onBlur={() => {}}
                  className={`${
                    searchInput
                      ? "animated-search-input-open"
                      : "animated-search-input-close"
                  } pl-2`}
                  autoFocus
                />
                <img
                  alt=""
                  style={{
                    visibility: searchString !== "" ? "visible" : "hidden",
                  }}
                  src="/images/settings-v2/header-search-cross.svg"
                  className="pointer"
                  onClick={() => {
                    // handleInputChange({ target: { value: "" } });
                    setSearchString("");
                    updateSearchCriteriaString("");
                  }}
                />
              </div>
            ) : isDateRangeInput ? (
              <DatePickerDropdown
                setDateSelection={(dateSelection) => {
                  updateSearchCriteriaDateRange(dateSelection);
                }}
                id={selectedSearchCriteria}
              />
            ) : null}
          </div>
        </div>  
        {tableType === "MFA Table" ? (
            <>
            {aseData?.UserIssues?.length === 0 ? (
              <div className="mt-5 mx-auto w-50 d-flex flex-column justify-content-center align-items-center">
                <img
                  src="/images/msp/no-mfa-item-icon.svg"
                  alt=""
                  className=""
                />
                <div className="f-600 f-grey">
                  This tenant has not been authorised yet
                </div>
                <div className="f-darkgrey w-50 my-2 mb-3 text-center">
                  Go to the Microsoft 365 Lighthouse Integration and authorise
                  this tenant to get started
                </div>
                <Button className="hg-blue-btn radius-2">
                  Open Microsoft 365 Lighthouse Integration
                </Button>
              </div>
            ) : (
              <UserIssuesTable 
                userIssues={aseData?.UserIssues}
                setASEData={setASEData}
                aseData={aseData}
                updateMfaStatus={updateMfaStatus}
                onSetUserSortColumn={onSetUserSortColumn}
                userSortColumn={userSortColumn}
                userSortOrder={userSortOrder}
                isLoading={isASELoading}
                sortEnabled
              />
            )}
          </>
        ) : tableType === "EOL Table" ? (
          <>
            {
              
              aseData?.DevicesIssues?.length === 0 ? (
                <div className="mt-5 mx-auto w-50 d-flex flex-column justify-content-center align-items-center">
                  <img
                    src="/images/msp/no-eol-item-icon.svg"
                    alt=""
                    className="mb-3"
                  />
                  <div className="f-600 f-grey">
                  No End of Life Devices
                  </div>
                  <div className="f-darkgrey w-50 my-2 mb-3 text-center">
                  End of Life Devices will be shown here
                  </div>
                  
                </div>
              ) : (
                <DeviceIssuesTable 
                  deviceIssues={aseData?.DevicesIssues}
                  className="ase-device-issues-table"
                  noDataComponent={(
                    <div />
                  )}
                  deviceSortColumn={deviceSortColumn}
                  deviceSortOrder={deviceSortOrder}
                  onSetDeviceSortColumn={onSetDeviceSortColumn}
                  isLoading={isASELoading}
                  sortEnabled
                />
              )
            }
          </>
        ) : null}
      </div>
    </div>}
    </>
  );
};

export default Overview;
