import moment from 'moment';

const getDuration = (startDate, endDate, format = "DD/MM/YYYY", unit = 'days') => {
  if (typeof startDate === 'string') {
    startDate = moment(startDate, format);
  }
  if (typeof endDate === 'string') {
    endDate = moment(endDate, format);
  }
  return endDate.diff(startDate, unit);
}

const convertDateFormat = (date, to = "DD/MM/YYYY") => {
  return moment(date).format(to);
}

const isDateFormatCorrect = (date, separator) => {
  const regex = new RegExp(`^\\d{2}\\${separator}\\d{2}\\${separator}\\d{4}`);
  return regex.test(date);
}

/**
 * 
 * @param {*} date 
 * @returns format: DD MMM YYYY HH:mm. For example: 01 Jan 2021 12:00
 */
const toDayMonthYearTimeFormat = (date) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = months[dateObj.getMonth()];
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  return `${day} ${month} ${year} ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
}

const dateUtils = {
  getDuration,
  convertDateFormat,
  isDateFormatCorrect,
  toDayMonthYearTimeFormat
};

export default dateUtils;