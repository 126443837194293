import React, { useEffect, useMemo, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./drilldownModal.scss";
import { IndependentGetAPIData, IndependentPostRequest, getAPIData, postRequest } from "../../Utils/commonApi";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { useAuth } from "../context/Auth";
import { accessStateValue, viewOnlyAccess } from "../App/App";
import { GreyLoader } from "../Theme/APILoaders";
import { AttestationPopUp } from "./KPIpopup/KPIpopup";
import   {
  CreateClientComplianceLoader,
} from "../MSPv2/clients/createClientScreens/CyberComplianceForGroupModal";
import { Attestation_Icon_Mappings } from "../ComplianceManager/WebFilteringBoxes/WebFilteringBoxes";
import SearchInput from "../Common/search-input/search-input.component";
import IntegrationListItem from "./KPICyberScoreSection/integration-list-item.component";
import SelectToolDropdown from "./KPICyberScoreSection/select-tool/select-tool.dropdown";
import NoteSection from './KPICyberScoreSection/note-section/note-section.component';
import SearchTitle from '../Common/search-title.component';
import { BASE_API_PAGE_ENUMS } from "../../constants/apis.constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import KPISettingsDropdown from "./kpi-settings-dropdown/kpi-settings.dropdown";
import { ROUTE_ACTIONS, ROUTE_PATHS } from "../../constants/app.constants";
import { IntegrationCategories } from "../../constants/integrations.constants";
import useToggleRow from "../../hooks/toggle-row.hook";
import useDropdown from "../../hooks/dropdown.hook";
import SelectArrowIcon from "../icons/select-arrow.icon";
import SuccessSignIcon from "../icons/success-sign.icon";
import httpUtils from "../../Utils/http.utils";

const CyberScoreForGroupModal = ({ 
  clientId, AccessStateLabel, passedOptionEnum99, showActivePackage = false, activePackageName, activePackageId,
  showAttestationIcons,
  setShowAttestationIcons,
  isSaas
}) => {
  const [searchComponent, setSearchComponent] = useState('');
  const { authTokens } = useAuth();
  const history = useHistory();
  const [mainPageLoading, setMainPageLoading] = useState(false);

  const [toolData, setToolData] = useState([]);
  const [cyberScoreData, setCyberScoreData] = useState({}); 
  const [loading, setLoading] = useState(false)

  const [selectedIntegrationId, setSelectedIntegrationId] = useState();

  const [showMspConnectModal, setShowMspConnectModal] = useState(false);

  useEffect(() => {
    setMainPageLoading(true);
    getCyberScoreData();
  }, []);

  useEffect(() => {
    let main_wrapper = document.querySelector("#cyber-score-group-wrapperr");
    if (!main_wrapper) return;
    let all_rows = main_wrapper.querySelectorAll(".point");
    for (let i = 0; i < all_rows.length; i++) {
      let panel = all_rows[i].querySelector(".panel");
      if (panel?.style?.maxHeight) {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }, [toolData]);

  /**
   * @param
   *
   * {
   * showUpdateToast: false,
   * showErrorToast:false,
   * keepToolsAtRow
   * }
   */

  function getCyberScoreData(kwargs = {}) {
    if (passedOptionEnum99) {
      IndependentGetAPIData(433, authTokens, {
        optionEnum1: window.location.pathname.split("/")[4] ?? clientId ?? 0,
        optionEnum2: 4,
        optionEnum99: passedOptionEnum99
      })
        .then((response) => {
          setMainPageLoading(false);
          setCyberScoreData(response);
          let tool_data = [...response?.AttestationRow_List];
  
          let updated_tool_data = tool_data?.map((toolInfo, toolIndex) => {
            const curTool = toolData.find(item => item?.TechnologyEnum === toolInfo?.TechnologyEnum);
            const hasChilds = toolInfo.Grouping && toolInfo.IsChild === false;
            const childs = hasChilds ? tool_data.filter((itemToolData) => itemToolData.IsChild && itemToolData.Grouping === toolInfo.Grouping) : [];
            return {
              ...toolInfo,
              IntegrationList:
                kwargs.keepToolsAtRow >= 0
                  ? [...toolData[toolIndex].IntegrationList]
                  : [],
              addNewTool: false,
              newToolName: "",
              addingNewTool: false,
              newToolSelected: false,
              loading: false,
              ...(curTool?.selected 
                ? { selected: curTool.selected } 
                : {
                  selected:
                  toolInfo?.SelectedTool_List?.length > 0 || childs.some(child => child?.SelectedTool_List?.length > 0)
                    ? { id: 1, category: "Yes" }
                    : toolInfo?.ShowNoCapability
                    ? { id: 0, category: "No" }
                    : null
                }),
              dummy_SelectedTool_List:
                toolInfo?.SelectedTool_List?.length > 0
                  ? [...toolInfo?.SelectedTool_List]
                  : [],
              ScoreAttestationRow: toolInfo.ScoreAttestationRow
            };
          });
  
          setToolData(updated_tool_data);
          if (kwargs?.showUpdateToast) {
            // CallToast("Updated successfully", false, false, "v2style");
          }
          if (kwargs?.showErrorToast) {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          setMainPageLoading(false);
        });
    } else {
      getAPIData(433, authTokens, {
        optionEnum1: window.location.pathname.split("/")[4] ?? clientId ?? 0,
        optionEnum2: 4,
      })
        .then((response) => {
          setMainPageLoading(false);
          setCyberScoreData(response);
          let tool_data = [...response?.AttestationRow_List];
  
          let updated_tool_data = tool_data?.map((toolInfo, toolIndex) => {
            const curTool = toolData.find(item => item?.TechnologyEnum === toolInfo?.TechnologyEnum);
            const hasChilds = toolInfo.Grouping && toolInfo.IsChild === false;
            const childs = hasChilds ? tool_data.filter((itemToolData) => itemToolData.IsChild && itemToolData.Grouping === toolInfo.Grouping) : [];
            return {
              ...toolInfo,
              IntegrationList:
                kwargs.keepToolsAtRow >= 0
                  ? [...toolData[toolIndex].IntegrationList]
                  : [],
              addNewTool: false,
              newToolName: "",
              addingNewTool: false,
              newToolSelected: false,
              loading: false,
              ...(curTool?.selected 
                ? { selected: curTool.selected } 
                : {
                  selected:
                  toolInfo?.SelectedTool_List?.length > 0 || childs.some(child => child?.SelectedTool_List?.length > 0)
                    ? { id: 1, category: "Yes" }
                    : toolInfo?.ShowNoCapability
                    ? { id: 0, category: "No" }
                    : null
                }),
              dummy_SelectedTool_List:
                toolInfo?.SelectedTool_List?.length > 0
                  ? [...toolInfo?.SelectedTool_List]
                  : [],
            };
          });
  
          setToolData(updated_tool_data);
          if (kwargs?.showUpdateToast) {
            // CallToast("Updated successfully", false, false, "v2style");
          }
          if (kwargs?.showErrorToast) {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          setMainPageLoading(false);
        });
    }
  }

  // kwargs={
  //   technologyEnum,
  //   IntegrationId,
  //   toolName,
  //   selected,
  //   rowIndex
  // }

  function addNewIntegration(kwargs = {}) {
    return new Promise((resolve, reject) => {
      if (passedOptionEnum99) {
        IndependentPostRequest(authTokens, "CreateIntegrationTechnologyTool", {
          Selected: kwargs.selected ?? true,
          IsMultipleIntegrationAllowed: true,
          ObjId_Customer: clientId,
          ToolName: kwargs.toolName,
          ObjectId: kwargs.IntegrationId,
          accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          IntegrationTechnology: kwargs.technologyEnum,
          optionEnum99: 3,
          MonitoredBySOC: kwargs.isManagedBySOC
        })
          .then((response) => {
            if (response?.mr?.Success) {
              resolve();
            } else {
            }
          })
          .catch((err) => {
            getCyberScoreData({ showErrorToast: true });
          });
      } else {
        postRequest(authTokens, "CreateIntegrationTechnologyTool", {
          Selected: kwargs.selected ?? true,
          IsMultipleIntegrationAllowed: true,
          ObjId_Customer: clientId,
          ToolName: kwargs.toolName,
          ObjectId: kwargs.IntegrationId,
          accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          IntegrationTechnology: kwargs.technologyEnum,
          MonitoredBySOC: kwargs.isManagedBySOC
        })
          .then((response) => {
            if (response?.mr?.Success) {
              resolve();
            } else {
            }
          })
          .catch((err) => {
            getCyberScoreData({ showErrorToast: true });
          });
      }
    });
  }

  async function disconnectIntegration(_integrationId) {
    return new Promise((resolve, reject) => {
      if (passedOptionEnum99) {
        IndependentGetAPIData("626", authTokens, {
          OptionEnum1: _integrationId,
          // OptionEnum2: 6,
          optionEnum2: clientId,
          optionEnum99: passedOptionEnum99
        })
          .then((response) => {
            if (response?.Success) {
              resolve();
            } else {
            }
          })
          .catch((err) => {});
      } else {
        getAPIData("626", authTokens, {
          OptionEnum1: _integrationId,
          // OptionEnum2: 6,
          optionEnum2: clientId,
        })
          .then((response) => {
            if (response?.Success) {
              resolve();
            } else {
            }
          })
          .catch((err) => {});
        }
      });
  }

  async function renameIntegration(_integrationId, newName) {
    return new Promise((resolve, reject) => {
      IndependentGetAPIData(BASE_API_PAGE_ENUMS.RenameCustomIntegrationName, authTokens, {
        optionEnum1: _integrationId,
        optionStrEnum1: newName,
      })
        .finally(() => resolve())
      });
  } 

  async function createIntegrationsMonitoredSOC(_integrationId, isMonitoredBySOC) {
    return new Promise((resolve, reject) => {
      IndependentPostRequest(authTokens, 'CreateIntegrationsMonitoredSOC', {
        ObjId_Customer: clientId,
        ObjId_Integration: _integrationId,
        IsMonitoredBySOC: isMonitoredBySOC,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
        .finally(() => resolve());
    });
  }

  // kwargs={
  //   technologyEnum,
  //   rowIndex
  // }
  async function removeAllIntegrations(kwargs = {}) {
    if (passedOptionEnum99) {
      await IndependentGetAPIData("261", authTokens, {
        OptionEnum1: kwargs.technologyEnum,
        // OptionEnum2: 6,
        optionEnum2: clientId,
        optionEnum99: passedOptionEnum99
      })
        .then((response) => {
        
          let updated_data = [...toolData];
          updated_data[kwargs.rowIndex].loading = false;
          updated_data[kwargs.rowIndex].IntegrationList = [];
          updated_data[kwargs.rowIndex].ShowSelectedTool = false;
          updated_data[kwargs.rowIndex].SelectedTool_List = [];
          setToolData(updated_data);
  setLoading(false)
          if (response?.mr?.Success) {
            // getCyberScoreData({ showUpdateToast: true });
          } else {
            // getCyberScoreData({ showErrorToast: true });
          }
          getCyberScoreData({
            keepToolsAtRow: 1,
          });
        })
        .catch((err) => {
          getCyberScoreData({ showErrorToast: true });
        });
    } else {
      await getAPIData("261", authTokens, {
        OptionEnum1: kwargs.technologyEnum,
        // OptionEnum2: 6,
        optionEnum2: clientId,
      })
        .then((response) => {
        
          let updated_data = [...toolData];
          updated_data[kwargs.rowIndex].loading = false;
          updated_data[kwargs.rowIndex].IntegrationList = [];
          updated_data[kwargs.rowIndex].ShowSelectedTool = false;
          updated_data[kwargs.rowIndex].SelectedTool_List = [];
          setToolData(updated_data);
  setLoading(false)
          if (response?.mr?.Success) {
            // getCyberScoreData({ showUpdateToast: true });
          } else {
            // getCyberScoreData({ showErrorToast: true });
          }
          getCyberScoreData({
            keepToolsAtRow: 1,
          });
        })
        .catch((err) => {
          getCyberScoreData({ showErrorToast: true });
        });
    }
  }

  function itemsLoading() {
    let isLoading = toolData?.some((toolRow) => {
      let integrationLoading = toolRow?.IntegrationList?.some(
        (integrationItem) => integrationItem?.loading === true
      );

      return integrationLoading || toolRow?.loading || toolRow?.addingNewTool;
    });

    return isLoading;
  }

  // kwargs={
  //   parentID,
  //   rowIndex,
  //   open,
  // }

  // kwargs= {
  //   rowIndex,
  //   technologyEnum
  // }
  function getTools(kwargs = {}) {
    return new Promise((resolve, reject) => {
      if (passedOptionEnum99) {
        IndependentGetAPIData(262, authTokens, {
          optionEnum1: kwargs.technologyEnum,
          optionEnum2: clientId,
          optionEnum99: passedOptionEnum99
        })
          .then((response) => {
            let updated_data = [...toolData];
            updated_data[kwargs.rowIndex].IntegrationList = [
              ...response?.TechnologyTool_List,
            ];
            updated_data[kwargs.rowIndex].loading = false;
            setToolData(updated_data);
            resolve();
          })
          .catch((err) => {});
      } else {
        getAPIData(262, authTokens, {
          optionEnum1: kwargs.technologyEnum,
          optionEnum2: clientId,
        })
          .then((response) => {
            let updated_data = [...toolData];
            updated_data[kwargs.rowIndex].IntegrationList = [
              ...response?.TechnologyTool_List,
            ];
            updated_data[kwargs.rowIndex].loading = false;
            setToolData(updated_data);
            resolve();
          })
          .catch((err) => {});
      }
    });
  }

  return (
    <div
      id="cyber-score-group-wrapperr"
      className="w-100 position-relative"
      style={{
        pointerEvents:
          accessStateValue("AccessState_MSP_Security_Stack") === 1
            ? "none"
            : "all",
      }}
    >
       {
            loading && <div className="position-absolute" style={{
              top:"0px",
              left:"0px",
              bottom:"0px",
              right:"0px",
              opacity:0.5,
              background:"#fff",
              zIndex:300
            }}></div>
          }
      {mainPageLoading ? (
        <CreateClientComplianceLoader />
      ) : (
        <>
          <div className="search-container">
            <SearchInput 
              placeholder="Search" 
              value={searchComponent} 
              onChange={(value) => setSearchComponent(value)} 
            />
            <KPISettingsDropdown 
              showAttestationIcons={showAttestationIcons} 
              setShowAttestationIcons={setShowAttestationIcons}
              switchId="showAttestationIcons"
              clientId={clientId}
            />
          </div>

          {
            showActivePackage && <div className="mb-2">
              {
                activePackageName && activePackageId ? <div className="bg-lightblue py-2 px-3 radius-4">
                  <div className="f-400 f-14 f-black line-height-22 letter-space-2">
                    Some of these components are covered by an active security package.
                  </div>
                  <div 
                    className="d-flex align-items-center pointer"
                    onClick={() => {
                      history.push(
                        `/mspv2/clients/clientsView/${clientId}/managed-security`, {
                          activePackageId: activePackageId
                        }
                      );
                    }}
                  >
                    <img
                      alt=""
                      height={13}
                      className="mr-2"
                      src="/images/msp/msp-package-icon.svg"
                    />
                    <div className="f-400 f-14 f-grey line-height-22 letter-space-2">
                      {activePackageName}
                    </div>
                  </div>
                  </div> : <div className="d-flex align-items-center bg-lightblue py-2 px-3 radius-4">
                  <img
                    alt=""
                    height={15}
                    className="mr-2"
                    src="/images/navIcons/calculator.svg"
                  />
                  <div className="f-400 f-14 f-black line-height-22 letter-space-2 mr-1">
                    Apply a security package in
                  </div>
                  <div 
                    className="f-500 f-14 f-blue line-height-22 letter-space-2 pointer"
                    onClick={() => {
                      history.push(
                        `/mspv2/clients/clientsView/${clientId}/managed-security`
                      );
                    }}
                  >
                    Managed Security
                  </div>
                </div>
              }
            </div>
          }

          <div
            className={`bg-grey radius-8 border-parent   ${itemsLoading() ? "stop-all-events" : ""
              }`}
            id="stack-wrapper"
          >
            <div className="border-item f-12 f-500 f-grey header-section cyber-score-table-row">
              <div>Component</div>
            </div>
            <div id="cyber-score-parent-rows">
              {toolData?.map((tool, toolIndex) => {
                return (
                  <AttestationRow
                    key={`tool-${tool?.TechnologyEnum}-${toolIndex}`} 
                    tool={tool}
                    toolIndex={toolIndex}
                    toolData={toolData}
                    setToolData={setToolData}
                    clientId={clientId}
                    showAttestationIcons={showAttestationIcons}
                    setShowMspConnectModal={setShowMspConnectModal}
                    searchComponent={searchComponent}
                    getTools={getTools}
                    removeAllIntegrations={removeAllIntegrations}
                    addNewIntegration={addNewIntegration}
                    renameIntegration={renameIntegration}
                    createIntegrationsMonitoredSOC={createIntegrationsMonitoredSOC}
                    isSaas={isSaas}
                    setLoading={setLoading}
                    getCyberScoreData={getCyberScoreData}
                    AccessStateLabel={AccessStateLabel}
                    setSelectedIntegrationId={setSelectedIntegrationId}
                    disconnectIntegration={disconnectIntegration}
                    rowParentId="cyber-score-parent-rows"
                    displayTopBorder={toolIndex !== 0}
                  />
                )
              })}
            </div>

            <div className="border-item f-12 f-500 f-grey p-2 text-align-end border-item-top">
              <div className="p-2">
                <span className="f-grey">
                  Your {cyberScoreData?.ScoreText} is
                </span>
                <span className="f-black f-500 mx-2">
                  {cyberScoreData?.ScoreValue}{" "}
                </span>
                <span
                  className=""
                  style={{
                    color: cyberScoreData?.ScoreRatingColour,
                  }}
                >
                  {cyberScoreData?.ScoreRating}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CyberScoreForGroupModal;

const AttestationRow = ({ 
  tool, 
  toolIndex,
  toolData,
  setToolData,
  clientId,
  showAttestationIcons,
  setShowMspConnectModal,
  searchComponent,
  getTools,
  removeAllIntegrations,
  addNewIntegration,
  renameIntegration,
  createIntegrationsMonitoredSOC,
  isSaas,
  setLoading,
  getCyberScoreData,
  AccessStateLabel,
  setSelectedIntegrationId,
  disconnectIntegration,
  rowParentId,
  onChildToggle = () => {},
  displayTopBorder = false,
  hideChilds = true,
  hasParentToolTitle = false,
}) => {
  const pointRef = useRef(null);
  const childContainerRef = useRef(null);
  const history = useHistory();
  const toolTitle = SearchTitle({ title: tool?.TechnologyTitle, searchValue: searchComponent });
  const { toggleRow, refreshState, setOpen } = useToggleRow({ rowRef: pointRef, parentId: rowParentId });
  const hasChilds = tool.Grouping && tool.IsChild === false;
  const isChildOf = tool.Grouping && tool.IsChild === true;
  const childs = useMemo(() => (
    hasChilds ? toolData.filter((itemToolData) => itemToolData.IsChild && itemToolData.Grouping === tool.Grouping) : []
  ), [toolData, tool, hasChilds]);
  const hasChildToolTitle = useMemo(() => (
    childs.some((child) => SearchTitle({ title: child?.TechnologyTitle, searchValue: searchComponent }))
  ), [childs, searchComponent]);
  const hasToolsToolTitle = useMemo(() => (
    tool?.SelectedTool_List?.some((option) => SearchTitle({ title: option?.ToolName, searchValue: searchComponent }))
  ), [tool, searchComponent]);
  const hasChildsToolsToolTitle = useMemo(() => (
    childs.some((child) => child?.SelectedTool_List?.some((option) => SearchTitle({ title: option?.ToolName, searchValue: searchComponent }))
  )), [childs, searchComponent]);
  const childWrapperId = useMemo(() => hasChilds ? `cyber-score-child-rows-${tool?.TechnologyTitle}` : null, [tool, hasChilds]);

  useEffect(() => {
    refreshState();
    if (!searchComponent) return;
    if (!hasChildToolTitle && !hasToolsToolTitle && !hasChildsToolsToolTitle) return;
    setOpen(true);
  }, [searchComponent]);

  if (!toolTitle && !hasChildToolTitle && !hasToolsToolTitle && !hasChildsToolsToolTitle && !hasParentToolTitle) return null;
  if (hideChilds && isChildOf) return null;

  const onToggleRow = () => {
    toggleRow();
    onChildToggle(childContainerRef?.current?.scrollHeight);
  }

  const yesNoId = `yes-no-dropdown-${tool?.TechnologyEnum}`;

  function openTheDropDown() {
    const dropdown = document.querySelector(`#${yesNoId}`);
    if (dropdown) {
      dropdown.click();
    }
  }

  return (
    <div
      className={`point ${displayTopBorder ? 'border-item-top' : ''}`}
      ref={pointRef} 
      onClick={(e) => {
        if (hasChilds) {
          onToggleRow();
        } else if (tool?.selected?.category === "Yes") {
          onToggleRow();
        } else if (tool?.selected?.category === "No") {
          onToggleRow();
        } else if (tool?.IntegrationList?.length > 0) {
          onToggleRow();
        } else {
          openTheDropDown();
        }
      }}
    >
      <div className={`d-flex flex-column align-items-center justify-content-between bg-hover-row w-100 row-section ${
        viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard") ? 'cursor-default' : 'pointer'
      }`}>
        <div className="d-flex align-items-center justify-content-between w-100 ">
          <div className="d-flex align-items-center  w-85">
            {showAttestationIcons && (
              <div className="mr-2">
                <AttestationPopUp>
                  <img
                    alt=""
                    src={
                      Attestation_Icon_Mappings[
                        tool?.ScoreAttestationRow?.ScoreAttestation
                      ]
                    }
                    className=""
                  />
                </AttestationPopUp>
              </div>
            )}
            <div className="d-flex flex-column">
              <div className="d-flex align-items-baseline ">
                <p className="f-500 m-0 component-title">{toolTitle ? toolTitle : tool?.TechnologyTitle}</p>
              </div>
              <div className="f-12 f-darkgrey component-question">
                  {tool?.TechnologyQuestion}
                </div>
            </div>
          </div>
          <div
            className="option-dropdown d-flex align-items-center"
            onClick={(e) => e.stopPropagation()}
          >
            <div>{tool?.loading && false &&<GreyLoader />}</div>
            {
              viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard") ?  
              <div className="f-darkgrey">
                {tool?.selected?.category ?? 'No Selected'}
                </div>
              : 
              (
                <YesNoDropdown
                  id={yesNoId}
                  selected={tool?.selected?.category}
                  setSelected={(val) => {
                    if (tool?.selected?.category === val) return;
                    let updated_data = [...toolData];
                    updated_data[toolIndex].selected = {
                      id: val === "Yes" ? 1 : 0,
                      category: val,
                    };
                    updated_data[toolIndex].error = false;
                    updated_data[toolIndex].loading = true;
                    if (val === "Yes") {
                      setLoading(true);
                      getTools({
                        technologyEnum: tool?.TechnologyEnum,
                        rowIndex: toolIndex,
                      }).then(() => {
                        onToggleRow();
                        setLoading(false)
                      });
                    }
                    if (val === "No") {
                      updated_data[toolIndex].loading = true;
                      setLoading(true)
                      removeAllIntegrations({
                        rowIndex: toolIndex,
                        technologyEnum:
                          updated_data[toolIndex]?.TechnologyEnum,
                      }).then(() => {
                        onToggleRow();
                      });
                    }
                    setToolData(updated_data);
                  } }
                  isFixed={isChildOf}
                />
              )
            }
          </div>
        </div>
      </div>
      <div className="panel" onClick={(e) => e.stopPropagation()}>
        {tool?.selected?.category === "No" ? (
          <NoteSection 
            onResize={(target) => {
              if (!target) return;
              const parent = target.closest(".point");
              let panel = parent.querySelector(".panel");
              if (!panel.style.maxHeight) return;
              panel.style.maxHeight = panel.scrollHeight + 20 + "px";
              onChildToggle(panel.scrollHeight + 20);
            }}
            clientId={clientId}
            technologyEnum={tool?.TechnologyEnum} 
            tool={tool}
            refetchTools={() => {
              getCyberScoreData({
                keepToolsAtRow: toolIndex,
              });
            }}
          />
        ) : tool?.selected?.category === "Yes" ? (
          hasChilds ? (
            <div id={childWrapperId}>
              {childs.map((child, childIndex) => (
                <AttestationRow 
                  key={`child-${child?.TechnologyEnum}-${childIndex}`} 
                  tool={child} 
                  toolIndex={toolData?.findIndex(item => item?.TechnologyEnum === child?.TechnologyEnum)} 
                  toolData={toolData} 
                  setToolData={setToolData} 
                  clientId={clientId} 
                  showAttestationIcons={showAttestationIcons} 
                  setShowMspConnectModal={setShowMspConnectModal} 
                  searchComponent={searchComponent} 
                  getTools={getTools} 
                  removeAllIntegrations={removeAllIntegrations} 
                  addNewIntegration={addNewIntegration} 
                  renameIntegration={renameIntegration} 
                  isSaas={isSaas} 
                  setLoading={setLoading} 
                  getCyberScoreData={getCyberScoreData} 
                  AccessStateLabel={AccessStateLabel} 
                  setSelectedIntegrationId={setSelectedIntegrationId} 
                  disconnectIntegration={disconnectIntegration}
                  rowParentId={childWrapperId}
                  hideChilds={false}
                  displayTopBorder={true}
                  hasParentToolTitle={!!toolTitle || !!hasToolsToolTitle}
                  createIntegrationsMonitoredSOC={createIntegrationsMonitoredSOC}
                  onChildToggle={(extraHeight) => {
                    refreshState(extraHeight);
                    setTimeout(() => {
                      refreshState(extraHeight);
                    }, 100);
                  }}
                />
              ))}
            </div>
          ) : (
            <div 
              className="tool-listing-wrapper ml-4 d-flex flex-column" 
              style={{ gap: '4px', padding: '0 16px' }}
              ref={childContainerRef} 
            >
              {tool?.SelectedTool_List
                ?.filter(option => option?.ToolSelected)
                ?.map((option, optionIndex) => {
                  return (
                    <IntegrationListItem 
                      key={`io-${option?.ToolName}-${optionIndex}`} 
                      option={option} 
                      integrationName={option?.ToolName}
                      integrationId={option?.obj_IntegrationId}
                      toolIcon={option?.ToolIcon}
                      isLoading={option?.loading}
                      optionIndex={optionIndex}
                      showRename={option?.IsUserDefined}
                      isManagedBySOC={option?.IsManagedBySOC}
                      managedBySOCState={option?.ManagedBySOCState}
                      style={{ marginLeft: '16px' }}
                      showSOC
                      searchComponent={searchComponent}
                      onRenameOption={(integrationId, newName) => {
                        let updated_data = [...toolData];
                        const optionIndex = tool?.SelectedTool_List
                          ?.findIndex(item => item?.obj_IntegrationId === integrationId);
                        updated_data[toolIndex].SelectedTool_List[
                          optionIndex
                        ].loading = true;
                        renameIntegration(integrationId, newName).then(() => {
                          getTools({
                            technologyEnum: tool?.TechnologyEnum,
                            rowIndex: toolIndex,
                          }).finally(() => {
                            getCyberScoreData({
                              keepToolsAtRow: toolIndex,
                            });
                          });
                        });
                        setToolData(updated_data);
                      }}
                      onRemoveOption={(integrationId) => {
                        let updated_data = [...toolData];
                        const optionIndex = tool?.SelectedTool_List
                          ?.findIndex(item => item?.obj_IntegrationId === integrationId);
                        updated_data[toolIndex].SelectedTool_List[
                          optionIndex
                        ].loading = true;
                        disconnectIntegration(
                          integrationId
                        ).finally(() => {
                          getTools({
                            technologyEnum: tool?.TechnologyEnum,
                            rowIndex: toolIndex,
                          }).finally(() => {
                            getCyberScoreData({
                              keepToolsAtRow: toolIndex,
                            });
                          });
                        });
                        setToolData(updated_data);
                      }}
                      onAddSubscription={(integrationId) => {
                        const integration = tool?.SelectedTool_List?.find(item => item?.obj_IntegrationId === integrationId);
                        if (!integration) return;
                        const query = httpUtils.generateQueryString({
                          action: ROUTE_ACTIONS.addSubscription,
                          integrationName: integration.ToolName,
                          integrationCategory: IntegrationCategories.CyberSecurity
                        })
                        const path = isSaas ? ROUTE_PATHS.saas.technologySpend : ROUTE_PATHS.mspv2.clients.technologySpend(clientId);
                        const url = `${path}${query}`;
                        history.push(url);
                      }}
                      onSOCStateChange={(integrationId, isManagedBySOC) => {
                        let updated_data = [...toolData];
                        const optionIndex = tool?.SelectedTool_List
                          ?.findIndex(item => item?.obj_IntegrationId === integrationId);
                        updated_data[toolIndex].SelectedTool_List[
                          optionIndex
                        ].loading = true;
                        createIntegrationsMonitoredSOC(integrationId, isManagedBySOC).then(() => {
                          getTools({
                            technologyEnum: tool?.TechnologyEnum,
                            rowIndex: toolIndex,
                          }).then(() => {
                            getCyberScoreData({
                              keepToolsAtRow: toolIndex,
                            });
                          });
                        });
                        setToolData(updated_data);
                      }}  
                    />
                  )
                })}

                <SelectToolDropdown 
                  integrationList={tool?.IntegrationList?.filter(option => !option?.ToolSelected)}
                  addingNewTool={toolData[toolIndex].addingNewTool}
                  onOpenModal={() => {
                    return new Promise((resolve, reject) => {
                      if (tool?.IntegrationList?.length === 0) {
                        setLoading(true);
                        getTools({
                          technologyEnum: tool?.TechnologyEnum,
                          rowIndex: toolIndex,
                        }).finally(() => {
                          setLoading(false);
                          resolve();
                        });
                      } else {
                        resolve();
                      }
                    });
                  }}
                  onOptionAdd={(option) => {
                    return new Promise((resolve, reject) => {
                      let updated_data = [...toolData];
                      const optionIndex = tool?.IntegrationList
                        ?.findIndex(item => item?.obj_IntegrationId === option?.obj_IntegrationId);
                      updated_data[toolIndex].IntegrationList[
                        optionIndex
                      ].loading = true;
                      addNewIntegration({
                        technologyEnum:
                          updated_data[toolIndex].TechnologyEnum,
                        IntegrationId: option?.obj_IntegrationId,
                        toolName: option?.IntegrationName,
                        selected: true,
                        rowIndex: toolIndex,
                        isManagedBySOC: option?.IsManagedBySOC,
                      }).then(() => {
                        getTools({
                          technologyEnum: tool?.TechnologyEnum,
                          rowIndex: toolIndex,
                        }).then(() => {
                          getCyberScoreData({
                            keepToolsAtRow: toolIndex,
                          });
                        });
                      })
                      .finally(() => resolve());
                      setToolData(updated_data);
                    });
                  }}
                  onNewOptionAdd={(optionName) => {
                    return new Promise((resolve, reject) => {
                      if (optionName === "") {
                        resolve();
                        return;
                      };
                      let updated_data = [...toolData];
                      updated_data[toolIndex].addingNewTool = true;
                      setToolData(updated_data);
                      addNewIntegration({
                        technologyEnum:
                          updated_data[toolIndex].TechnologyEnum,
                        IntegrationId: 0,
                        toolName: optionName,
                        selected: true,
                        rowIndex: toolIndex,
                      }).then(() => {
                        let updated_data = [...toolData];
                        updated_data[toolIndex].addNewTool = false;
                        updated_data[toolIndex].newToolName = "";
                        setToolData(updated_data);
                        getTools({
                          technologyEnum: tool?.TechnologyEnum,
                          rowIndex: toolIndex,
                        }).then(() => {
                          getCyberScoreData({
                            keepToolsAtRow: toolIndex,
                          });
                        });
                      }).finally(() => resolve());
                    })
                  }}
                />
            </div>
          )
        ) : null}
      </div>
    </div>
  );
}

const YesNoDropdown = ({ selected, setSelected, id, isFixed }) => {
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({});
  const popperConfig = {
    ...(isFixed ? { strategy: "fixed" } : {}),
  };
  const items = ['Yes', 'No'];
  return (
    <Dropdown 
      show={isOpen} 
      className="transparennt-wrapper-dropdown"
      onToggle={() => setIsOpen(prev => !prev)}
    >
      <Dropdown.Toggle ref={refBtn} id={id ?? 'dropdown-basic'}>
        <div className="d-flex align-items-center" style={{ gap: '8px' }}>
          <span className={`f-500 f-14 ${selected ? 'f-black' : 'f-grey'}`}>
            {selected ?? 'Select'}
          </span>
          <span
            style={{
              ...(isOpen ? { transform: "rotate(180deg)" } : {}),
            }}
          >
            <SelectArrowIcon width="12px" height="8px" />
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu ref={refMenu} popperConfig={popperConfig} className="yes-no-modal">
        {items.map((item, index) => (
          <div
            key={index} 
            className="d-flex align-items-center justify-content-between py-1 px-3 item"
            onClick={() => {
              setSelected(item)
              setIsOpen(false)
            }}
          >
            <span>
              {item}
            </span>
            {selected === item && (
              <SuccessSignIcon width="12px" height="9px" />
            )}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
