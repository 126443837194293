import { useState } from "react";
import useApiActions from "../../../../../hooks/api-actions.hook";

const useGetSubPaymentDetails = () => {
  const [fetchTimeout, setFetchTimeout] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [displayDetails, setDisplayDetails] = useState(false);
  const { getAddSubscriptionSpendCalcs } = useApiActions();

  const fetchData = ({
    clietId,
    term,
    invoiceId,
    reccuringPeriod,
    startDate
  }) => {
    if (fetchTimeout) {
      clearTimeout(fetchTimeout);
    }
    setFetchTimeout(
      setTimeout(() => {
        setIsLoading(true);
        getAddSubscriptionSpendCalcs({
          clietId,
          term,
          invoiceId,
          reccuringPeriod,
          startDate
        })
          .then((response) => {
            if (response && response.mr.Success) {
              setIsLoading(false);
              setData(response.detail);
              setDisplayDetails(true);
            }
          });
        setFetchTimeout(null);
      }, 500)
    );
  }

  return {
    isLoading,
    data,
    displayDetails,
    fetchData,
    setDisplayDetails
  }
}

export default useGetSubPaymentDetails;
