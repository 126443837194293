export const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
export const IP_API_URL = process.env.REACT_APP_IP_API_URL;

export const BASE_API_PAGE_ENUMS = {
  QuestionNote: 261,
  CurrentUserDetails: 915,
  CyberSecurityPosture: 965,
  IntegrationByTechType: 985,
  RenameCustomIntegrationName: 986,
};

export const BASE_API_ROUTES = {
  validateUserToken: `${BASE_API_URL}/AttackSurfaceAPI/ValidateUser`,
  getAllIntegrationsByTechType: `${BASE_API_URL}/CC_API/GetAllIntegrationsByTechologyType`,
  saveIntegrationsInfo: `${BASE_API_URL}/CC_API/CreateIntegrationInfo`,
  submitNotificationSettings: `${BASE_API_URL}/CC_API/SubmitNotificationSettings`,
  getClientDashboardV2: `${BASE_API_URL}/CC_API/GetClientDashboardV2`,
  getTechnologySpend: `${BASE_API_URL}/CC_API/GetTechnologySpend`,
  getAllAssetSourcesV2: `${BASE_API_URL}/CC_API/GetAllAssetSourcesV2`,
  getUserDeviceIssues: `${BASE_API_URL}/CC_API/GetUserDeviceIssues`,
  createIntegrationsMonitoredSOC: `${BASE_API_URL}/CC_API/CreateIntegrationsMonitoredSOC`,
  getAddSubscriptionSpendCalcs: `${BASE_API_URL}/CC_API/GetAddSubscriptionSpendCalcs`,
}
