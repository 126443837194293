import { useEffect, useState } from "react";

const useToggleRow = ({ 
  rowRef, 
  multiple = false, 
  defaultOpen = false, 
  parentId = null,
  panelVisibleOut = false
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleRow = (extraHeight = 0) => {
    const parent = rowRef.current;
    if (!parent) return;
    const panel = parent?.querySelector(".panel");
    if (!multiple) {
      let allPoints = [];
      if (parentId) {
        const parentPanel = document?.getElementById(parentId);
        if (parentPanel) {
          allPoints = parentPanel?.querySelectorAll(".point");
        }
      } else {
        allPoints = document?.querySelectorAll(".point");
      }
      allPoints?.forEach(function (point) {
        const otherPanel = point.querySelector(".panel");
        if (otherPanel !== panel && otherPanel.style.maxHeight) {
          otherPanel.style.maxHeight = null;
          if (panelVisibleOut) {
            otherPanel.style.overflow = "hidden";
          }
        }
      });
    }
    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
      if (panelVisibleOut) {
        panel.style.overflow = "hidden";
      }
      setIsOpen(false);
    } else {
      console.log('panel', extraHeight)
      panel.style.maxHeight = panel.scrollHeight + extraHeight + "px";
      if (panelVisibleOut) {
        panel.style.overflow = "visible";
      }
      setIsOpen(true);
    }
  }

  const refreshState = (extraHeight = 0) => {
    const parent = rowRef.current;
    if (!parent) return;
    const panel = parent?.querySelector(".panel");
    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = panel.scrollHeight + extraHeight + "px";
    }
  }

  const refreshPanelStates = (extraHeight = 0) => {
    if (parentId) {
      const parentPanel = document?.getElementById(parentId);
      if (parentPanel) {
        const allPanels = parentPanel?.querySelectorAll(".panel");
        allPanels?.forEach(function (panel) {
          if (panel.style.maxHeight) {
            panel.style.maxHeight = panel.scrollHeight + extraHeight + "px";
          }
        });
      }
      return;
    }
    const allPanels = document?.querySelectorAll(".panel");
    allPanels?.forEach(function (panel) {
      if (panel.style.maxHeight) {
        panel.style.maxHeight = panel.scrollHeight + extraHeight + "px";
      }
    });
  }

  const setOpen = (state) => {
    setIsOpen(state);
    const parent = rowRef.current;
    if (!parent) return;
    const panel = parent?.querySelector(".panel");
    if (state) {
      panel.style.maxHeight = panel.scrollHeight + "px";
      if (panelVisibleOut) {
        panel.style.overflow = "visible";
      }
    } else {
      panel.style.maxHeight = null;
      if (panelVisibleOut) {
        panel.style.overflow = "hidden";
      }
    }
  }

  useEffect(() => {
    if (!defaultOpen) return;
    const parent = rowRef.current;
    const panel = parent?.querySelector(".panel");
    if (!panel) return;
    const originalTransition = panel.style.transition;
    panel.style.transition = 'none';
    toggleRow();
    setTimeout(() => {
      panel.style.transition = originalTransition;
    }, 0);
  }, [defaultOpen]);

  return { toggleRow, setOpen, refreshState, refreshPanelStates, isOpen };
}

export default useToggleRow;
