import React, { useEffect, useState } from "react";
import styles from "./search-input.styles.module.scss";

const SearchInput = (props) => {
  const [search, setSearch] = useState(props.value ?? "");

  useEffect(() => {
    if (props.onChange) props.onChange(search);
  }, [search]);

  return (
    <div className={`${styles['search-bar']} ${props.className ?? ''}`}>
      <img
        alt=""
        src="/images/settings-v2/search-icon.svg"
        className="mr-2 ml-1"
        width={'14px'}
        height={'14px'}
      />
      <input
        value={search}
        {...props}
        type="text"
        className={styles['search-bar-input']}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      {search && (
        <img
          alt=""
          src="/images/settings-v2/header-search-cross.svg"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            setSearch("");
          }}
        />
      )}
    </div>
  );
}

export default SearchInput;